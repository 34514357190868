import React, { useState } from 'react'
import styles from './address-validation-modal.module.scss'
import { Dialog, DialogContent, Grid } from '@material-ui/core'
import { BodyLarge, BodyRegular, Button, Spinner } from '@clubspark-react/clubspark-react-tools'
import { Radio } from '../payment-options-list/payment-options-list'
import { SchoolInfo } from '../../../types/types'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { VALIDATE_ADDRESS } from '../../apollo/queries'
import { useTranslation } from 'react-i18next'
import { FormikHelpers, FormikProps } from 'formik'

export enum AddressUsed {
  VALIDATED = 'VALIDATED',
  NON_VALIDATED = 'NON_VALIDATED'
}
interface AddressValidationModalProps extends FormikProps<any> {
  open: boolean
  onClose: () => void
  onContinue: (values: SchoolInfo, actions: FormikHelpers<SchoolInfo>, selectedAddress: AddressUsed, validatedAddress: any) => void
  values: SchoolInfo
}

const AddressValidationModal: React.FC<AddressValidationModalProps> = ({open, onClose, values, onContinue, ...formikProps}) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [selectedAddress, setSelectedAddress] = useState<AddressUsed>(AddressUsed.NON_VALIDATED)
  const {mailingAddress, city, state, zipCode} = values

  const { data, error, loading } = useQuery(VALIDATE_ADDRESS, {
    client,
    skip: !open,
    variables: {
      address: {
        address: mailingAddress,
        city,
        state,
        zip: zipCode
      }
    },
    onCompleted: () => {
      setSelectedAddress(AddressUsed.VALIDATED)
    },
    onError: () => {
      setSelectedAddress(AddressUsed.NON_VALIDATED)
    }
  })

  const handleSubmit = () => {
    const validatedAddressData = {
      mailingAddress: data?.validateAddress?.address,
      city: data?.validateAddress?.city,
      state: data?.validateAddress?.state,
      zipCode: data?.validateAddress?.zip
    }
    onContinue(values, formikProps, selectedAddress, validatedAddressData)
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: styles.paper }}>
      <DialogContent>
        {loading &&
          <Grid container justify="center">
            <Spinner />
          </Grid>
        }
        {!loading &&
          <div>
            <BodyLarge>
              <strong>{t('address entered')}</strong>
            </BodyLarge>
            <div className={styles.radioContainer} onClick={() => setSelectedAddress(AddressUsed.NON_VALIDATED)}>
              <Radio checked={selectedAddress === AddressUsed.NON_VALIDATED} />
              <BodyRegular>
                {mailingAddress}<br/>
                {city}, {state}, {zipCode}
              </BodyRegular>
            </div>
            {error &&
              <div>
                <BodyRegular classnames={styles.warning} spacing={{ margins: { md: 'top' } }}>{t('address issues')}</BodyRegular>
                {error?.graphQLErrors.map(({ message }, i) => (
                  <BodyRegular classnames={styles.warning} key={i}>{message}</BodyRegular>
                ))}
                <BodyRegular classnames={styles.warning}>{t('continue anyway')}</BodyRegular>
              </div>
            }
            {!error && data &&
              <div>
                <BodyLarge spacing={{ margins: { sm: 'top' } }}>
                  <strong>{t('we found')}</strong>
                </BodyLarge>
                <div className={styles.radioContainer} onClick={() => setSelectedAddress(AddressUsed.VALIDATED)}>
                  <Radio checked={selectedAddress === AddressUsed.VALIDATED} />
                  <BodyRegular>
                    {data.validateAddress.address}<br/>
                    {data.validateAddress.city}, {data.validateAddress.state}, {data.validateAddress.zip}
                  </BodyRegular>
                </div>
                {!!data?.validateAddress?.validationErrors.length &&
                  <>
                    <BodyRegular classnames={styles.warning} spacing={{ margins: { md: 'top' } }}>{t('address issues')}</BodyRegular>
                    {data?.validateAddress?.validationErrors.map(({ type }, i) => (
                      <BodyRegular classnames={styles.warning} key={i}>{type}</BodyRegular>
                    ))}
                    <BodyRegular classnames={styles.warning}>{t('continue anyway')}</BodyRegular>
                  </>
                }
              </div>
            }
            <div className={styles.flex}>
              <Button
                type="button"
                classname={styles.buttonBack}
                size="lg"
                level="secondary"
                onClick={onClose}
              >
                {t('back')}
              </Button>
              <Button onClick={handleSubmit} size="lg" classname={styles.button}>
                {t('use this address')}
              </Button>
            </div>
          </div>

        }
      </DialogContent>
    </Dialog>
  )
}

export default AddressValidationModal