import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { GET_PRICE_FOR_SCHOOL } from 'src/App/components/register-school-form/register-school-form-queries'
import { GetPriceForSchool, GetPriceForSchoolVariables } from 'src/graphql-types/GetPriceForSchool'

export function useSchoolPrice(options: QueryHookOptions<GetPriceForSchool, any>) {
  const { variables, ...otherOptions } = options

  const query = useQuery<GetPriceForSchool, GetPriceForSchoolVariables>(GET_PRICE_FOR_SCHOOL, {
    ...otherOptions,
    variables,
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  })
  return { ...query, amount: query.data?.getPriceForSchool?.amount }
}
