import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { Box, Hidden } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser } from 'src/api-service/api-service'
import { GET_CONFERENCE_DIVISION, GET_EXTERNAL_ID } from 'src/App/apollo/local-state'
import { ApplicableSteps, InitialStep, useMembershipTerms } from 'src/App/auth/register/register'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useExistingMembership } from 'src/hooks/use-existing-membership'
import { usePrice } from 'src/hooks/use-price'
import { useToken } from 'src/hooks/use-token'
import {
  CONFERENCE_DETAILS_ROUTE,
  CONFERENCE_PAYMENT_CONFIRMATION_ROUTE,
  CONFERENCE_PAYMENT_ROUTE
} from 'src/utils/constants/routes'
import { CONFERENCE_MEMBERSHIP } from 'src/utils/constants/strings'
import {
  getCurrentStep,
  getCurrentUser,
  getTermId,
  setCurrentStep
} from 'src/utils/localStorage/local-storage'
import { RouteCheck } from '../../../utils/helper/helper'
import ConferencePaymentConfirmation from '../conference-confirmation/conference-confirmation'
import ConferenceDetails from '../conference-details/conference-details'
import MembershipSummary from '../membership-summary/membership-summary'
import PageContainer from '../page-container/page-container'
import PageWidth from '../page-width/page-width'
import RegisterPayment from '../register-payment/register-payment'
import RegisterTerms from '../register-terms/register-terms'
import MuiStepper from '../stepper/stepper'

interface ConferenceMembershipProps extends RouteComponentProps {}

const ConferenceMembership: React.FC<ConferenceMembershipProps> = () => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const { token } = useToken()

  const { loadingExistingMembership } = useExistingMembership(MembershipCodeEnum.CONF, !token)
  const { data: clientData } = useQuery(GET_CONFERENCE_DIVISION, { client })
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.CONF)
  const { amount } = usePrice({
    skip: !clientData?.conferenceDivision || !getTermId(),
    variables: {
      input: {
        membershipCode: MembershipCodeEnum.CONF,
        termId: getTermId(),
        division: clientData?.conferenceDivision
      }
    }
  })

  const currentStep = getCurrentStep()
  const user = getCurrentUser()

  const { data: userId } = useQuery(GET_EXTERNAL_ID, { client })

  useEffect(() => {
    setLoadingPage(true)
    if (!user) getUser(client, MembershipCodeEnum.CONF)
    if (!currentStep || currentStep == null) setCurrentStep('0')
    setLoadingPage(false)
  }, [userId, currentStep, client, user])

  const initialSteps: InitialStep[] = [
    {
      key: '1',
      value: t('terms'),
      visible: hasMultipleActiveTerms,
      Component: (
        <RegisterTerms code={MembershipCodeEnum.CONF} navigateRoute={CONFERENCE_DETAILS_ROUTE} />
      )
    },
    { key: '2', value: t('details'), visible: true, Component: <ConferenceDetails /> },
    {
      key: '3',
      value: t('payment'),
      visible: true,
      Component: (
        <RegisterPayment path={CONFERENCE_PAYMENT_ROUTE} membership={CONFERENCE_MEMBERSHIP} />
      )
    },
    {
      key: '4',
      value: t('confirmation'),
      visible: true,
      Component: <ConferencePaymentConfirmation path={CONFERENCE_PAYMENT_CONFIRMATION_ROUTE} />
    }
  ]

  const applicableSteps = initialSteps.filter(s => s.visible)
  const stepNames = applicableSteps.map(s => s.value)

  return (
    <>
      <Hidden smUp>
        <RouteCheck>
          <MembershipSummary cost={amount} />
        </RouteCheck>
      </Hidden>
      <Box clone p={{ xs: '0 20px 0 20px', sm: '0 20px 0 20px', md: '0 135px 0 135px' }}>
        <PageContainer>
          <PageWidth>
            <MuiStepper
              steps={stepNames}
              children={
                <ApplicableSteps steps={applicableSteps} currentStep={currentStep ?? '0'} />
              }
              activeStep={Number(currentStep)}
              loading={loadingExistingMembership}
              loadingPage={loadingPage || loadingExistingMembership}
            />
          </PageWidth>
        </PageContainer>
      </Box>
    </>
  )
}

export default ConferenceMembership
