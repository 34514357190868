import gql from 'graphql-tag'

export const GET_PRICE = gql`
  query GetPrice($input: GetPriceInput!) {
    getPrice(getPriceInput: $input) {
      amount
      basePrice
      currency
      discount {
        start
        end
        type
        amount
      }
    }
  }
`

export const GET_MEMBERSHIPS = gql`
  query GetMemberships($pagination: PaginationInput!, $sorting: SortingInput) {
    memberships(pagination: $pagination, sorting: $sorting) {
      data {
        id
        name
        code
        terms {
          id
          name
          start
          end
          state
        }
      }
    }
  }
`
export const VALIDATE_ADDRESS = gql`
  query ValidateAddress($address: AddressInput) {
    validateAddress(address: $address) {
      address
      city
      state
      zip
      validationErrors {
        type
        value
      }
    }
  }
`
