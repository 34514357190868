import graphql from 'graphql-tag'

export const setRegistrationStep = (step, client) => {
  client.writeData({ data: { registrationStep: step } })
}

export const setLoggedIn = (isLoggedIn, client) => {
  client.writeData({ data: { isLoggedIn: isLoggedIn } })
}

export const setConferenceDivision = (division, client) => {
  client.writeData({ data: { conferenceDivision: division } })
}

export const setSelectedTeam = (team, client) => {
  client.writeData({ data: { selectedTeam: team } })
}

export const GET_EXTERNAL_ID = graphql`
  query GetExternalId {
    externalId @client
  }
`
export const IS_LOGGED_IN = graphql`
  query IsLoggedIn {
    isLoggedIn @client
  }
`

export const GET_COST = graphql`
  query GetCost {
    cost @client
  }
`
export const GET_ADDITIONAL_INFO = graphql`
  query GetAdditionalInfo {
    additionalInfo @client
  }
`

export const GET_CONFERENCE_DIVISION = graphql`
  query GetConferenceDivision {
    conferenceDivision @client
  }
`
export const GET_SELECTED_TEAM = graphql`
  query GetSelectedTeam {
    selectedTeam @client
  }
`
