import { useQuery } from '@apollo/react-hooks'
import { GET_SCHOOLS } from 'src/App/components/register-school-form/register-school-form-queries'
import { GetSchools } from 'src/graphql-types/GetSchools'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { getTermId } from 'src/utils/localStorage/local-storage'
import { useOwnMembers } from './use-own-members'
import { findSchool } from '../utils/helper/helper'

export function useTeamIds(membershipCode: MembershipCodeEnum | undefined | null) {
  const termId = getTermId()
  const { data: schoolsInfo } = useQuery<GetSchools>(GET_SCHOOLS, {
    skip: membershipCode !== MembershipCodeEnum.CPM
  })

  const { data } = useOwnMembers({
    code: MembershipCodeEnum.CPM
  })
  const member = data?.getOwnMembers?.filter(member => member?.termId === termId)
  const latestMemberTeamIds = member?.[member?.length -1]?.organisationId?.teamIds ?? []

  const school = findSchool(schoolsInfo, false)
  const purchasedWomensTeam = latestMemberTeamIds?.includes(school?.womanId ?? null)
  const purchasedMensTeam = latestMemberTeamIds?.includes(school?.manId ?? null)
  const purchasedBothTeams = purchasedWomensTeam && purchasedMensTeam

  return {
    mensId: purchasedBothTeams || purchasedMensTeam ? school?.manId : null,
    womensId: purchasedBothTeams || purchasedWomensTeam ? school?.womanId : null
  }
}
