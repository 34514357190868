import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { GET_PRICE } from 'src/App/apollo/queries'
import { GetPrice, GetPriceVariables } from 'src/graphql-types/GetPrice'

export function usePrice(options: QueryHookOptions<GetPrice, GetPriceVariables>) {
  const query = useQuery<GetPrice, GetPriceVariables>(GET_PRICE, {
    ...options,
    fetchPolicy: 'network-only'
  })
  return { ...query, amount: query.data?.getPrice?.amount }
}
