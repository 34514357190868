import graphql from 'graphql-tag'

export const CREATE_MEMBER = graphql`
  mutation CreateMember($input: MemberInput!) {
    createMember(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_MEMBER = graphql`
  mutation UpdateMember($id: ID!, $input: MemberInput!) {
    updateMember(id: $id, input: $input) {
      id
      name
    }
  }
`

export const GET_MEMBER = graphql`
  query GetMember($id: ID!) {
    member(id: $id) {
      id
      name
      school
    }
  }
`

export const CREATE_ORGANISATION = graphql`
  mutation CreateOrganisation($input: OrganisationInput!) {
    createOrganisation(input: $input) {
      id
      name
      mailingAddress {
        city
      }
    }
  }
`

export const GET_ORGANISATION = graphql`
  query GetOrganisation($id: ID!) {
    organisation(id: $id) {
      id
      name
      mailingAddress {
        country
        countryCode
        state
        city
        zipCode
        street
        houseNumber
      }
    }
  }
`
export const UPDATE_ORGANISATION = graphql`
  mutation UpdateOrganisation($id: ID!, $input: OrganisationInput!) {
    updateOrganisation(id: $id, input: $input) {
      id
      name
      mailingAddress {
        city
      }
    }
  }
`

export const DELETE_ORGANISATION = graphql`
  mutation DeleteOrganisation($id: ID!) {
    deleteOrganisation(id: $id)
  }
`
