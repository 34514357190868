export const ROOT_ROUTE = '/'
export const AUTH_ROUTE = '/auth'
export const HOME_ROUTE = '/home'
export const LOGIN_ROUTE = `${AUTH_ROUTE}/login`
export const REGISTER_ROUTE = `${AUTH_ROUTE}/register`
export const SUMMER_CIRCUIT_ROUTE = `${AUTH_ROUTE}/summer-circuit`
export const CORPORATE_ROUTE = `${AUTH_ROUTE}/corporate`
export const CONFERENCE_ROUTE = `${AUTH_ROUTE}/conference`
export const USTA_SECTION_ROUTE = `${AUTH_ROUTE}/usta-section`
export const ASSOCIATE_ROUTE = `${AUTH_ROUTE}/associate`
export const EMERITUS_ROUTE = `${AUTH_ROUTE}/emeritus`
export const REGISTER_DETAILS_ROUTE = `${REGISTER_ROUTE}/register-details`
export const PAYMENT_ROUTE = `${REGISTER_ROUTE}/payment`
export const PAYMENT_CONFIRMATION_ROUTE = `${REGISTER_ROUTE}/payment-confirmation`
export const REGISTER_SCHOOL_DETAILS_ROUTE = `${REGISTER_ROUTE}/school-details`
export const REGISTER_TERMS_ROUTE = `${REGISTER_ROUTE}/terms`
export const SC_DETAILS_ROUTE = `${SUMMER_CIRCUIT_ROUTE}/details`
export const SC_TERMS_ROUTE = `${SUMMER_CIRCUIT_ROUTE}/terms`
export const SC_PAYMENT_ROUTE = `${SUMMER_CIRCUIT_ROUTE}/payment`
export const SC_PAYMENT_CONFIRMATION_ROUTE = `${SUMMER_CIRCUIT_ROUTE}/payment-confirmation`
export const CORPORATE_DETAILS_ROUTE = `${CORPORATE_ROUTE}/details`
export const CORPORATE_TERMS_ROUTE = `${CORPORATE_ROUTE}/terms`
export const CORPORATE_PAYMENT_ROUTE = `${CORPORATE_ROUTE}/payment`
export const CORPORATE_PAYMENT_CONFIRMATION_ROUTE = `${CORPORATE_ROUTE}/payment-confirmation`
export const CONFERENCE_DETAILS_ROUTE = `${CONFERENCE_ROUTE}/details`
export const CONFERENCE_TERMS_ROUTE = `${CONFERENCE_ROUTE}/terms`
export const CONFERENCE_PAYMENT_ROUTE = `${CONFERENCE_ROUTE}/payment`
export const CONFERENCE_PAYMENT_CONFIRMATION_ROUTE = `${CONFERENCE_ROUTE}/payment-confirmation`
export const USTA_SECTION_DETAILS_ROUTE = `${USTA_SECTION_ROUTE}/details`
export const USTA_SECTION_TERMS_ROUTE = `${USTA_SECTION_ROUTE}/terms`
export const USTA_SECTION_PAYMENT_ROUTE = `${USTA_SECTION_ROUTE}/payment`
export const USTA_SECTION_PAYMENT_CONFIRMATION_ROUTE = `${USTA_SECTION_ROUTE}/payment-confirmation`
export const ASSOCIATE_DETAILS_ROUTE = `${ASSOCIATE_ROUTE}/details`
export const ASSOCIATE_TERMS_ROUTE = `${ASSOCIATE_ROUTE}/terms`
export const ASSOCIATE_PAYMENT_ROUTE = `${ASSOCIATE_ROUTE}/payment`
export const ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE = `${ASSOCIATE_ROUTE}/payment-confirmation`
export const EMERITUS_DETAILS_ROUTE = `${EMERITUS_ROUTE}/details`
export const EMERITUS_TERMS_ROUTE = `${EMERITUS_ROUTE}/terms`
export const EMERITUS_PAYMENT_ROUTE = `${EMERITUS_ROUTE}/payment`
export const EMERITUS_PAYMENT_CONFIRMATION_ROUTE = `${EMERITUS_ROUTE}/payment-confirmation`
