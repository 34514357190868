import {
  BodyLarge,
  BodyLargeBold,
  BodyRegular,
  Button,
  H1,
  Spinner
} from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { navigate, RouteComponentProps } from '@reach/router'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { ROOT_ROUTE } from 'src/utils/constants/routes'
import { setCurrentStep, setTermId as storeTermId } from 'src/utils/localStorage/local-storage'
import { Radio } from '../payment-options-list/payment-options-list'
import styles from './register-terms.module.scss'

interface RegisterDetailsProps extends RouteComponentProps {
  code: MembershipCodeEnum
  navigateRoute: string
}

const RegisterTerms: React.FC<RegisterDetailsProps> = ({ code, navigateRoute }) => {
  const { t } = useTranslation()
  const { activeTerms, loading } = useMembershipTerms(code)
  const [term, setTerm] = useState<string | undefined>(undefined)

  const termOptions = activeTerms?.map(t => ({
    value: t?.id,
    label: `${t?.name} | ${moment.utc(t?.start).format('MMM DD, YYYY')} - ${moment
      .utc(t?.end)
      .format('MMM DD, YYYY')}`
  }))

  useEffect(() => {
    storeTermId(term)
  }, [term])

  return (
    <Grid container>
      {loading && <Spinner />}
      {!loading && (
        <Grid item xs={12} md={12} lg={12}>
          <H1 spacing={{ margins: { md: 'top' } }}>{t('terms')}</H1>
          <BodyLarge spacing={{ margins: { md: 'top', lg: 'bottom' } }}>
            {t('terms sub text')}
          </BodyLarge>
          <div className={styles.divider} />
          <BodyLargeBold spacing={{ margins: { lg: 'top', md: 'bottom' } }}>
            {t('choose term for your membership')}
          </BodyLargeBold>
          {termOptions?.map(({ value, label }) => (
            <div className={styles.radioContainer} key={label} onClick={() => setTerm(value)}>
              <Radio checked={term === value} />
              <BodyRegular>{label}</BodyRegular>
            </div>
          ))}
          <div className={styles.root}>
            <Button
              type="button"
              classname={styles.buttonBack}
              size="lg"
              level="secondary"
              onClick={() => navigate(ROOT_ROUTE)}
            >
              {t('back')}
            </Button>
            <Button
              size="lg"
              classname={styles.button}
              disabled={!term}
              onClick={() => {
                setCurrentStep('1')
                navigate(navigateRoute)
              }}
            >
              {t('continue')}
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  )
}
export default RegisterTerms
