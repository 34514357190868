import { CustomDialog } from '@clubspark-react/clubspark-react-tools'
import { Radio as MuiRadio, RadioProps, Grid } from '@material-ui/core'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ThemeProvider from 'src/App/theme/theme-provider'
import { ReactComponent as Card } from '../../images/icons/32px/card-pay.svg'
import { ReactComponent as Request } from '../../images/icons/32px/request.svg'
import { MuiList, MuiListItem, MuiListItemText } from '../list/list'
import PaymentDialog from '../payment-dialog/payment-dialog'
import styles from './payment-options-list.module.scss'
import { RegisterPaymentActions } from '../stepper-actions/stepper-actions'
import { environmentSettings } from 'src/config'
import { useRouteMembership } from 'src/hooks/use-existing-membership'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'

interface PaymentOptionsListProps {
  isChangePayment?: boolean
}

const stripePromise = loadStripe(environmentSettings.REACT_APP_STRIPE_KEY)

export const Radio: React.FC<RadioProps> = ({ children, ...props }) => (
  <ThemeProvider>
    <MuiRadio disableRipple disableFocusRipple color="primary" {...props} />
  </ThemeProvider>
)

const PaymentOptionsList: React.FC<PaymentOptionsListProps> = ({ isChangePayment }) => {
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = React.useState(-1)
  const [open, setOpen] = React.useState<boolean>(false)
  const membershipCode = useRouteMembership()

  const onListItemClick = (
    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ): void => {
    setSelectedIndex(index)
  }

  return (
    <Elements stripe={stripePromise} data-testid="stripe-popup">
      <MuiList className={styles.list}>
        <MuiListItem
          button
          selected={selectedIndex === 0}
          onClick={e => onListItemClick(e, 0)}
          className={styles.listItem}
          classes={{ selected: styles.itemSelected }}
        >
          <Radio checked={selectedIndex === 0} />
          <MuiListItemText
            primary={t('pay option', { option: 'I want to pay with credit / debit card' })}
            classes={{ primary: styles.primaryText }}
          />
          <Card />
        </MuiListItem>

        {!isChangePayment && membershipCode === MembershipCodeEnum.CPM && (
          <MuiListItem
            selected={selectedIndex === 1}
            onClick={e => onListItemClick(e, 1)}
            className={styles.listItem}
            classes={{ selected: styles.itemSelected }}
          >
            <Radio checked={selectedIndex === 1} />
            <MuiListItemText
              primary={t('pay option', { option: 'Send an invoice via email?' })}
              classes={{ primary: styles.primaryText }}
            />
            <Request />
          </MuiListItem>
        )}
      </MuiList>
      <Grid container item xs={12} md={11} lg={11}>
        <RegisterPaymentActions
          setOpen={setOpen}
          isSelected={selectedIndex > -1}
          selectedIndex={selectedIndex}
        />
      </Grid>
      <CustomDialog
        title=""
        open={open}
        onClose={() => setOpen(false)}
        content={<PaymentDialog setOpen={setOpen} />}
      />
    </Elements>
  )
}

export default PaymentOptionsList
