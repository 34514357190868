import gql from 'graphql-tag'

export const GET_SCHOOLS = gql`
  query GetSchools {
    schools {
      id
      name
      manId
      womanId
      teamType
      division
    }
  }
`

export const GET_PRICE_FOR_SCHOOL = gql`
  query GetPriceForSchool($getPriceForSchoolInput: GetPriceForSchoolInput!) {
    getPriceForSchool(getPriceForSchoolInput: $getPriceForSchoolInput) {
      amount
      basePrice
      currency
      discount {
        start
        end
        type
        amount
      }
    }
  }
`
