import {
  Hidden,
  Paper,
  Step,
  StepConnector,
  StepConnectorProps,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'
import Loading from '../loading-component/loading'
import styles from './stepper.module.scss'

interface MuiStepperProps {
  steps: string[]
  children: React.ReactNode
  activeStep: number
  loading?: boolean
  loadingPage?: boolean
}

const MuiStepper: React.FC<MuiStepperProps> = ({
  steps,
  children,
  activeStep,
  loading,
  loadingPage
}) => {
  return (
    <div className={styles.widthContainer}>
      <Paper className={styles.paper}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<MuiStepConnector />}
          classes={{ root: styles.stepperRoot }}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                className={styles.labelRoot}
                classes={{
                  label: styles.labelRoot,
                  iconContainer: styles.circle,
                  active: styles.activeLabel,
                  completed: styles.completedLabel
                }}
                StepIconComponent={StepIcon}
              >
                <Hidden only="xs">{label}</Hidden>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {loading || loadingPage ? (
          <Loading />
        ) : (
          <Typography component="span" className={styles.instructions}>
            {children}
          </Typography>
        )}
      </Paper>
    </div>
  )
}

const MuiStepConnector: React.FC<StepConnectorProps> = props => {
  return (
    <StepConnector
      classes={{ root: styles.line, active: styles.activeLine, completed: styles.completedLine }}
      {...props}
    />
  )
}

const StepIcon: React.FC<StepIconProps> = ({ active, completed }: StepIconProps) => {
  return (
    <div
      className={cx(styles.iconRoot, {
        [styles.active]: active,
        [styles.completed]: completed
      })}
    ></div>
  )
}

export default MuiStepper
