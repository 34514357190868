import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ServerError } from 'apollo-link-http-common'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { environmentSettings } from 'src/config'
import { setContext } from 'apollo-link-context'
import { retrieveToken } from 'src/utils/localStorage/local-storage'
import { from } from 'apollo-link'

const errorLink = onError(({ networkError }) => {
  const returnUrl = window.location.href

  if (networkError && (networkError as ServerError).statusCode === 401) {
    if (window !== 'undefined') {
      window.location.href = `${environmentSettings.REACT_APP_CLASSIC_API}/Account/SignIn?returnUrl=${returnUrl}`
    }
  }
})

const setToken = setContext((_, ctx = {}) => {
  const token = retrieveToken()
  return (
    token && {
      ...ctx,
      headers: { ...(ctx.headers ?? {}), authorization: `ClubSpark-Auth ${token}` }
    }
  )
})

const createClient = () => {
  const client = new ApolloClient({
    link: from([
      errorLink,
      setToken,
      new HttpLink({
        uri: environmentSettings.REACT_APP_GATEWAY_GQL_URL,
        fetch
      })
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: o => {
        switch (o.__typename) {
          default:
            return defaultDataIdFromObject(o)
        }
      }
    }),
    resolvers: {},
    connectToDevTools: true
  })

  client.writeData({
    data: {
      registrationStep: '0',
      selectedTeam: '',
      externalId: '',
      isLoggedIn: false,
      itaId: '',
      additionalInfo: '',
      conferenceDivision: ''
    }
  })

  return client
}

export default createClient
