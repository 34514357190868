import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { Box, Hidden } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser } from 'src/api-service/api-service'
import { GET_EXTERNAL_ID } from 'src/App/apollo/local-state'
import { ApplicableSteps, InitialStep, useMembershipTerms } from 'src/App/auth/register/register'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useExistingMembership } from 'src/hooks/use-existing-membership'
import { usePrice } from 'src/hooks/use-price'
import { useToken } from 'src/hooks/use-token'
import {
  PAYMENT_CONFIRMATION_ROUTE,
  PAYMENT_ROUTE,
  REGISTER_DETAILS_ROUTE,
  REGISTER_SCHOOL_DETAILS_ROUTE,
  SC_PAYMENT_CONFIRMATION_ROUTE,
  SC_PAYMENT_ROUTE,
  SC_TERMS_ROUTE
} from 'src/utils/constants/routes'
import { RouteCheck } from 'src/utils/helper/helper'
import {
  getCurrentStep,
  getCurrentUser,
  getTermId,
  setCurrentStep
} from 'src/utils/localStorage/local-storage'
import RegisterPayment from '../../components/register-payment/register-payment'
import MembershipSummary from '../membership-summary/membership-summary'
import PageContainer from '../page-container/page-container'
import PageWidth from '../page-width/page-width'
import RegisterTerms from '../register-terms/register-terms'
import MuiStepper from '../stepper/stepper'
import SummerCircuitConfirmation from '../summer-circuit-confirmation/summer-circuit-confirmation'
import SummerCircuitDetails from '../summer-circuit-details/summer-circult-details'

interface SummerCircuitProps extends RouteComponentProps {}

export const REGISTER_ROUTES = [
  REGISTER_DETAILS_ROUTE,
  REGISTER_SCHOOL_DETAILS_ROUTE,
  PAYMENT_ROUTE,
  PAYMENT_CONFIRMATION_ROUTE
]

interface SummerCircuitViewProps {
  amount: number
  hasMultipleActiveTerms?: boolean
  loadingExistingMembership?: boolean
}

function SummerCircuitView({
  hasMultipleActiveTerms = true,
  loadingExistingMembership = true,
  amount
}: SummerCircuitViewProps) {
  const { t } = useTranslation()
  const currentStep = getCurrentStep()

  const initialSteps: InitialStep[] = [
    {
      key: '1',
      value: t('terms'),
      visible: hasMultipleActiveTerms || false,
      Component: <RegisterTerms code={MembershipCodeEnum.PLA} navigateRoute={SC_TERMS_ROUTE} />
    },
    { key: '2', value: t('player details'), visible: true, Component: <SummerCircuitDetails /> },
    {
      key: '3',
      value: t('payment'),
      visible: true,
      Component: <RegisterPayment path={SC_PAYMENT_ROUTE} />
    },
    {
      key: '4',
      value: t('confirmation'),
      visible: true,
      Component: <SummerCircuitConfirmation path={SC_PAYMENT_CONFIRMATION_ROUTE} />
    }
  ]

  const applicableSteps = initialSteps.filter(s => s.visible)
  const stepNames = applicableSteps.map(s => s.value)
  return (
    <>
      <Hidden smUp>
        <RouteCheck>
          <MembershipSummary cost={amount} />
        </RouteCheck>
      </Hidden>
      <Box clone p={{ xs: '0 20px 0 20px', sm: '0 20px 0 20px', md: '0 135px 0 135px' }}>
        <PageContainer>
          <PageWidth>
            <MuiStepper
              steps={stepNames}
              children={
                <ApplicableSteps steps={applicableSteps} currentStep={currentStep ?? '0'} />
              }
              activeStep={Number(currentStep)}
              loading={loadingExistingMembership}
              loadingPage={loadingExistingMembership}
            />
          </PageWidth>
        </PageContainer>
      </Box>
    </>
  )
}

function SummerCircuitContainer({ token }: { token: string }) {
  const { loadingExistingMembership } = useExistingMembership(MembershipCodeEnum.PLA, !token)
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.PLA, !token)
  const { amount } = usePrice({
    skip: !getTermId(),
    variables: { input: { membershipCode: MembershipCodeEnum.PLA, termId: getTermId() } }
  })

  useEffect(() => {
    setCurrentStep('0')
  }, [])

  return (
    <SummerCircuitView
      loadingExistingMembership={loadingExistingMembership}
      hasMultipleActiveTerms={hasMultipleActiveTerms}
      amount={amount || 0}
    />
  )
}

const SummerCircuit: React.FC<SummerCircuitProps> = ({ location }: SummerCircuitProps) => {
  const client = useApolloClient()
  const { token } = useToken()

  const currentStep = getCurrentStep()
  const user = getCurrentUser()

  const { data: userId } = useQuery(GET_EXTERNAL_ID, { client })

  useEffect(() => {
    if (!user) getUser(client, MembershipCodeEnum.PLA)
    if (!currentStep || currentStep == null) setCurrentStep('0')
  }, [userId, currentStep, location, client, user])

  if (!token) {
    return <SummerCircuitView amount={0} />
  }

  return <SummerCircuitContainer token={token} />
}

export default SummerCircuit
