import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { BodySmallBold, Button, H2, Spinner } from '@clubspark-react/clubspark-react-tools'
import { Grid, Hidden } from '@material-ui/core'
import { navigate, RouteComponentProps } from '@reach/router'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GET_CONFERENCE_DIVISION, setConferenceDivision } from 'src/App/apollo/local-state'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { usePrice } from 'src/hooks/use-price'
import { ConferenceInfo } from 'src/types/types'
import { CONFERENCE_PAYMENT_ROUTE, HOME_ROUTE } from 'src/utils/constants/routes'
import {
  getConferenceInfo,
  getTermId,
  setConferenceInfo,
  setCurrentStep
} from 'src/utils/localStorage/local-storage'
import { usePlayerDetailsOptions } from 'src/utils/player-details-options'
import * as yup from 'yup'
import { ReactComponent as DownArrorSVG } from '../../../assets/icn_arrow-down.svg'
import AddressForm, { Input } from '../address-form/address-form'
import FormErrorMessage from '../form-error-message/form-error-message'
import MembershipSummary from '../membership-summary/membership-summary'
import Select from '../select/select'
import styles from './conference-details.module.scss'

export const getCost = (division: any) => {
  if (division === 'division i' || division === 'division ii') return 300
  else if (division === 'NA') return 0
  else return 150
}

interface ConferenceDetailsProps extends RouteComponentProps {}

const ConferenceDetails: React.FC<ConferenceDetailsProps> = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.CONF)

  const conferenceInfo = getConferenceInfo()
  const client = useApolloClient()
  const { data: clientData } = useQuery(GET_CONFERENCE_DIVISION, { client })
  const { amount } = usePrice({
    skip: !clientData?.conferenceDivision || !getTermId(),
    variables: {
      input: {
        membershipCode: MembershipCodeEnum.CONF,
        termId: getTermId(),
        division: clientData?.conferenceDivision
      }
    }
  })

  let initialValues: ConferenceInfo = {
    conferenceName: conferenceInfo ? conferenceInfo.conferenceName : '',
    division: conferenceInfo ? conferenceInfo.division : '',
    city: conferenceInfo ? conferenceInfo.city : '',
    mailingAddress: conferenceInfo ? conferenceInfo.mailingAddress : '',
    state: conferenceInfo ? conferenceInfo.state : '',
    zipCode: conferenceInfo ? conferenceInfo.zipCode : ''
  }

  const validationSchema = yup.object().shape({
    conferenceName: yup.string().required(t('is required', { type: 'conference name' })),
    division: yup.string().required(t('is required', { type: 'division' })),
    mailingAddress: yup.string().required(t('is required', { type: 'mailing address' })),
    city: yup.string().required(t('is required', { type: 'city' })),
    state: yup.string().required(t('is required', { type: 'state' })),
    zipCode: yup.string().required(t('is required', { type: 'zip code' }))
  })

  const handleBack = () => {
    navigate(HOME_ROUTE)
  }

  const onSubmit = async (values: ConferenceInfo, actions) => {
    setLoading(true)
    actions.setSubmitting(true)
    setConferenceInfo(values)
    setLoading(false)
    actions.setSubmitting(false)

    if (!loading) {
      navigate(CONFERENCE_PAYMENT_ROUTE)
      setCurrentStep(hasMultipleActiveTerms ? '2' : '1')
    }
  }

  const { COLLEGIATE_DIVISION_OPTIONS } = usePlayerDetailsOptions()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <H2 spacing={{ margins: { xxs: 'bottom' } }}>{t('conference details')}</H2>
                </Grid>
                <Grid container>
                  <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('conference name')} *
                  </BodySmallBold>
                  <Field name="conferenceName" component={Input} />
                  {errors?.conferenceName && touched?.conferenceName && (
                    <FormErrorMessage message={errors.conferenceName} />
                  )}
                </Grid>
                <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('division')} *
                </BodySmallBold>
                <Field
                  name="division"
                  defaultValue={values.division}
                  options={COLLEGIATE_DIVISION_OPTIONS}
                  component={Select}
                  // value={values.state}
                  onSelect={option => {
                    setFieldValue('division', option.value)
                    setConferenceDivision(option.value, client)
                  }}
                  IconComponent={() => <DownArrorSVG />}
                  style={{ borderRadius: 0 }}
                />
                {errors?.division && touched?.division && (
                  <FormErrorMessage message={errors.division} />
                )}
                <AddressForm
                  errors={errors}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
                {isSubmitting ? (
                  <Grid container justify="flex-start" className={styles.spinnerContainer}>
                    <Spinner />
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={styles.actionButtonContainer}
                  >
                    <div className={styles.root}>
                      <Button
                        type="button"
                        classname={styles.buttonBack}
                        size="lg"
                        level="secondary"
                        onClick={handleBack}
                      >
                        {t('back')}
                      </Button>
                      <Button type="submit" size="lg" classname={styles.button}>
                        {t('continue')}
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Hidden xsDown>
              <MembershipSummary cost={amount} />
            </Hidden>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ConferenceDetails
