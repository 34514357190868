import classnames from 'classnames/bind'
import React from 'react'
import Select from 'react-select'
import styles from './advanced-dropdown.module.scss'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.data.custom ? '#01a2ff' : 'black'
  })
}

type Props = {
  options: Array<any>
  onSelect?: any
  defaultValue: any
  field?: {
    name: string
    value: string
  }
  setFieldValue?: Function
  props?: any
}

const AdvancedDropdown: React.FC<Props> = ({
  onSelect,
  options,
  field,
  setFieldValue,
  defaultValue,
  ...props
}: Props) => {
  const cx = classnames.bind(styles)

  const handleChange = (selectedOption: any, { action }) => {
    if (setFieldValue) {
      setFieldValue(field?.name, selectedOption ? selectedOption.name : '')
    }
    onSelect && onSelect(selectedOption ? selectedOption : '')
  }

  return (
    <Select
      className={cx('container')}
      data-testid="school dropdown"
      classNamePrefix="cs-react-select"
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => {
        return option.manId ? option.manId : option.womanId
      }}
      defaultValue={defaultValue}
      styles={customStyles}
      onChange={handleChange}
      options={options}
      isClearable={true}
      {...props}
    />
  )
}

export default AdvancedDropdown
