import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import { BodyLargeBold, Spinner } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { navigate } from '@reach/router'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { confirmPayment, createPayment, getUser } from 'src/api-service/api-service'
import { useMembershipTerms } from 'src/App/auth/register/register'
import ITALogo from 'src/assets/ITALogoLight@3x.png'
import { GetSchools } from 'src/graphql-types/GetSchools'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { IntegrateCollegeMembershipTransaction } from 'src/graphql-types/IntegrateCollegeMembershipTransaction'
import { IntegrateSummerCircuitTransaction } from 'src/graphql-types/IntegrateSummerCircuitTransaction'
import { useRouteMembership } from 'src/hooks/use-existing-membership'
import { CreatePaymentRequest } from 'src/types/types'
import {
  ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE,
  CONFERENCE_PAYMENT_CONFIRMATION_ROUTE,
  CORPORATE_PAYMENT_CONFIRMATION_ROUTE,
  EMERITUS_PAYMENT_CONFIRMATION_ROUTE,
  PAYMENT_CONFIRMATION_ROUTE,
  SC_PAYMENT_CONFIRMATION_ROUTE,
  USTA_SECTION_PAYMENT_CONFIRMATION_ROUTE
} from 'src/utils/constants/routes'
import {
  createPaymentPayload,
  getAssociateProfessionalTransaction,
  getConferenceTransaction,
  getCorporateTransaction,
  getEmeritusTransaction,
  getMetadata,
  getSCTransaction,
  getStripeMetadata,
  getTeamMembershipTransaction,
  getUSTASectionTransaction
} from 'src/utils/helper/transactionPayloads'
import { getOrgData, getTermId, setCurrentStep } from 'src/utils/localStorage/local-storage'
import {
  generateITAId,
  generateStripeMetadata,
  getDescription,
  useDates,
  useOrderItems
} from '../../../utils/helper/helper'
import FormErrorMessage from '../form-error-message/form-error-message'
import styles from '../register-payment/register-payment.module.scss'
import { GET_SCHOOLS } from '../register-school-form/register-school-form-queries'
import {
  ASSOCIATE_MEMBERSHIP_TRANSACTION,
  COACH_MEMBERSHIP_TRANSACTION,
  COLLEGE_MEMBERSHIP_TRANSACTION,
  CONFERENCE_MEMBERSHIP_TRANSACTION,
  CORPORATE_MEMBERSHIP_TRANSACTION,
  SUMMER_CIRCUIT_TRANSACTION,
  USTA_MEMBERSHIP_TRANSACTION
} from './payment-dialog-queries'

interface PaymentDialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PaymentDialog: React.FC<PaymentDialogProps> = ({ setOpen }) => {
  const elements = useElements()
  const stripe = useStripe()
  const client = useApolloClient()
  const { t } = useTranslation()
  const orgData = getOrgData()
  const school = orgData?.school || ''

  const [cardNumError, setCardNumError] = useState<string>('')
  const [cardExpireError, setCardExpireError] = useState<string>('')
  const [cardCVCError, setCardCVCError] = useState<string>('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState<string>('')

  const [integrateSummerCircuitTransaction] = useMutation<IntegrateSummerCircuitTransaction>(
    SUMMER_CIRCUIT_TRANSACTION
  )
  const [integrateCollegeMembershipTransaction] = useMutation<
    IntegrateCollegeMembershipTransaction
  >(COLLEGE_MEMBERSHIP_TRANSACTION)

  const [integrateCorporateMembershipTransaction] = useMutation(CORPORATE_MEMBERSHIP_TRANSACTION)
  const [integrateConferenceMembershipTransaction] = useMutation(CONFERENCE_MEMBERSHIP_TRANSACTION)
  const [integrateUSTASectionTransaction] = useMutation(USTA_MEMBERSHIP_TRANSACTION)
  const [integrateAssociateMembershipTransaction] = useMutation(ASSOCIATE_MEMBERSHIP_TRANSACTION)
  const [integrateEmeritusMembershipTransaction] = useMutation(COACH_MEMBERSHIP_TRANSACTION)

  const orderItems = useOrderItems()
  const total = orderItems?.amount || 0

  const handleCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
    if (event.error) {
      setCardNumError(event.error.message)
    } else {
      setCardNumError('')
    }
  }

  const handleCardExpirationChange = (event: StripeCardExpiryElementChangeEvent) => {
    if (event.error) {
      setCardExpireError(event.error.message)
    } else {
      setCardExpireError('')
    }
  }

  const handleCardCVCChange = (event: StripeCardCvcElementChangeEvent) => {
    if (event.error) {
      setCardCVCError(event.error.message)
    } else {
      setCardCVCError('')
    }
  }

  const membershipCode = useRouteMembership()
  const { hasMultipleActiveTerms } = useMembershipTerms(membershipCode as MembershipCodeEnum)
  const { startDate, endDate } = useDates(membershipCode as MembershipCodeEnum)

  const { data: schoolsInfo } = useQuery<GetSchools>(GET_SCHOOLS, {
    skip: membershipCode !== MembershipCodeEnum.CPM
  })
  const schoolObject = schoolsInfo?.schools?.find(s => s?.name === school)

  const finishTransaction = (route: string) => {
    setProcessing(false)
    setOpen(false)
    navigate(route)
    // If multiple active terms, there's an additional first step to select the term
    setCurrentStep(hasMultipleActiveTerms ? '3' : '2')
  }

  // Handle form submission.
  const handleSubmit = async event => {
    event.preventDefault()
    setProcessing(true)

    if (!stripe || !elements) return

    let user = JSON.parse(localStorage.getItem('user')!)
    if (!user) user = await getUser(client, membershipCode)
    const isTeamMembership = membershipCode === MembershipCodeEnum.CPM

    const description = getDescription(school, membershipCode)
    const itaId = generateITAId(user.Index ? user.Index : 0)
    const stripeMetadata = getStripeMetadata(
      user,
      itaId,
      isTeamMembership,
      schoolObject,
      description,
      {
        startDate,
        endDate
      }
    )
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)!,
      billing_details: {
        email: user.EmailAddress,
        name: `${user.FirstName} ${user.LastName}`
      }
    })

    if (error) {
      setError(error.message ? error.message : '')
      setProcessing(false)
    } else {
      if (paymentMethod) {
        client.writeData({ data: { itaId } })
        const metadata = getMetadata(membershipCode, schoolObject)
        const membershipMetadata = generateStripeMetadata(stripeMetadata)
        const fullMetadata = { ...metadata, ...membershipMetadata }
        const paymentData: CreatePaymentRequest = createPaymentPayload(
          total,
          paymentMethod.id,
          fullMetadata,
          description || 'No description provided'
        )

        try {
          const payment = await createPayment(paymentData)

          if (!payment.Error) {
            if (payment.RequiresAction) {
              const handleCardActionResult = await stripe.handleCardAction(payment.ExternalID)

              if (handleCardActionResult.error) {
                setError(handleCardActionResult.error?.message || '')
                setProcessing(false)

                return
              }

              const confirmPaymentResult = await confirmPayment({ PaymentIntentId: payment.ID })

              if (confirmPaymentResult.Error) {
                setError(confirmPaymentResult.Error)
                setProcessing(false)

                return
              }
            }

            if (membershipCode === MembershipCodeEnum.PLA) {
              const transaction = getSCTransaction(payment.ID)
              const summerCircuitTransaction = await integrateSummerCircuitTransaction({
                variables: { input: { ...transaction, termId: getTermId() } }
              })
              if (summerCircuitTransaction) finishTransaction(SC_PAYMENT_CONFIRMATION_ROUTE)
            } else if (membershipCode === MembershipCodeEnum.CPM) {
              const transaction = getTeamMembershipTransaction(schoolsInfo, orderItems, payment.ID)

              const collegeMembershipTransaction = await integrateCollegeMembershipTransaction({
                variables: { input: { ...transaction, termId: getTermId(), organisation: { ...transaction.organisation} } }
              })
              if (collegeMembershipTransaction) finishTransaction(PAYMENT_CONFIRMATION_ROUTE)
            } else if (membershipCode === MembershipCodeEnum.CORP) {
              const transaction = getCorporateTransaction(payment.ID)
              const corporateMembershipTransaction = await integrateCorporateMembershipTransaction({
                variables: { input: { ...transaction, termId: getTermId() } }
              })
              if (corporateMembershipTransaction)
                finishTransaction(CORPORATE_PAYMENT_CONFIRMATION_ROUTE)
            } else if (membershipCode === MembershipCodeEnum.CONF) {
              const transaction = getConferenceTransaction(payment.ID)
              const conferenceMembershipTransaction = await integrateConferenceMembershipTransaction(
                { variables: { input: { ...transaction, termId: getTermId() } } }
              )
              if (conferenceMembershipTransaction)
                finishTransaction(CONFERENCE_PAYMENT_CONFIRMATION_ROUTE)
            } else if (membershipCode === MembershipCodeEnum.USTASEC) {
              const transaction = getUSTASectionTransaction(payment.ID)
              const ustaSectionTransaction = await integrateUSTASectionTransaction({
                variables: { input: { ...transaction, termId: getTermId() } }
              })
              if (ustaSectionTransaction) finishTransaction(USTA_SECTION_PAYMENT_CONFIRMATION_ROUTE)
            } else if (membershipCode === MembershipCodeEnum.ASCPRO) {
              const transaction = getAssociateProfessionalTransaction(payment.ID)
              const associateMembershipTransaction = await integrateAssociateMembershipTransaction({
                variables: { input: { ...transaction, termId: getTermId() } }
              })
              if (associateMembershipTransaction)
                finishTransaction(ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE)
            } else if (membershipCode === MembershipCodeEnum.COEM) {
              const transaction = getEmeritusTransaction(payment.ID)
              const emeritusMembershipTransaction = await integrateEmeritusMembershipTransaction({
                variables: { input: { ...transaction, termId: getTermId() } }
              })
              if (emeritusMembershipTransaction)
                finishTransaction(EMERITUS_PAYMENT_CONFIRMATION_ROUTE)
            }
          } else {
            setProcessing(false)
            setError(payment.Error)
          }
        } catch (error) {
          console.log('error', error)
          setProcessing(false)
          setError(error.message)
        }
      }
    }
  }

  return (
    <Grid container justify="center">
      <Grid
        container
        justify="center"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={styles.logoContainer}
      >
        <img src={ITALogo} alt="logo" className={styles.logo} />
      </Grid>
      <Grid container justify="center" item xs={12} sm={12} md={12} lg={12}>
        <BodyLargeBold spacing={{ margins: { md: ['top', 'bottom'] } }}>
          {t('payment dialog message')}
        </BodyLargeBold>
      </Grid>
      <form method="post" onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <StripeCardNumberElementWrapper onChange={handleCardNumberChange} />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <FormErrorMessage message={cardNumError} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StripeCardExpirationElementWrapper onChange={handleCardExpirationChange} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormErrorMessage message={cardExpireError} />
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StripeCardCVCElementWrapper onChange={handleCardCVCChange} />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <FormErrorMessage message={cardCVCError} />
            </Grid>
          </Grid>
        </Grid>
        {processing ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            <button className={styles.submitButton} type="submit" disabled={processing || !stripe}>
              {`Pay $${total.toFixed(2)}`}
            </button>
            {error && <FormErrorMessage message={error} />}
          </>
        )}
      </form>
    </Grid>
  )
}

interface CardNumberProps {
  onChange: (event: StripeCardNumberElementChangeEvent) => any
}

const StripeCardNumberElementWrapper: React.FC<CardNumberProps> = ({ onChange }) => (
  <CardNumberElement
    className={styles.cardNumber}
    options={{
      placeholder: 'Card number',
      showIcon: true,
      style: {
        base: {
          fontSize: '16px'
        }
      },
      classes: {
        base: styles.CardElement,
        invalid: styles.CardElementInvalid
      }
    }}
    onChange={onChange}
  />
)

interface CardExpireProps {
  onChange: (event: StripeCardExpiryElementChangeEvent) => any
}

const StripeCardExpirationElementWrapper: React.FC<CardExpireProps> = ({ onChange }) => (
  <CardExpiryElement
    options={{
      placeholder: 'MM/YY',
      style: {
        base: {
          fontSize: '16px'
        }
      },
      classes: {
        base: styles.CardExpiryElement,
        invalid: styles.CardExpiryElementInvalid
      }
    }}
    onChange={onChange}
  />
)

interface CardCVCProps {
  onChange: (event: StripeCardCvcElementChangeEvent) => any
}

const StripeCardCVCElementWrapper: React.FC<CardCVCProps> = ({ onChange }) => (
  <CardCvcElement
    options={{
      placeholder: 'CVC',
      style: {
        base: {
          fontSize: '16px'
        }
      },
      classes: {
        base: styles.CardCVCElement,
        invalid: styles.CardCVCElementInvalid
      }
    }}
    onChange={onChange}
  />
)

export default PaymentDialog
