import gql from 'graphql-tag'

export const GET_ALL_MEMBERS_BY_EXTERNAL_ID = gql`
  query GetAllMemberByExternalId($externalUserId: String!) {
    allMembersByExternalId(externalUserId: $externalUserId) {
      id
      name
      type
      startDate
      endDate
      state
      membership {
        id
        name
      }
      additionalInfo {
        name
        value
      }
      transactions {
        id
        amount
        success
        timestamp
        paymentType
      }
    }
  }
`

export const GET_OWN_MEMBERS = gql`
  query GetOwnMembers($membershipId: ID!, $termIds: [ID]!) {
    getOwnMembers(membershipId: $membershipId, termIds: $termIds) {
      id
      name
      type
      startDate
      endDate
      state
      venueRole
      termId
      membershipId {
        id
        name
        code
      }
      additionalInfo {
        name
        value
      }
      transactionIds {
        id
        amount
        success
        timestamp
        paymentType
      }
      organisationId {
        name
        externalId
        mailingAddress {
          state
          city
          zipCode
          street
        }
        physicalAddress {
          state
          city
          zipCode
          street
        }
        teamIds
      }
    }
  }
`

export const GET_ORGANISATION_PURCHASE_STATUS = gql`
  query GetOrganisationPurchaseStatus($input: WomanIdManId!, $membershipId: ID!, $termId: ID!) {
    getOrganisationPurchaseStatus(input: $input, membershipId: $membershipId, termId: $termId)
  }
`

export const GET_MEMBERSHIP_PACKAGE_DATES = gql`
  query GetMembershipPackageDates($code: MembershipCodeEnum!) {
    getDates(code: $code) {
      start
      end
    }
  }
`
