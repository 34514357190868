import { ApolloProvider } from '@apollo/react-hooks'
import { StyleProvider } from '@clubspark-react/clubspark-react-tools'
import React from 'react'
import ReactDOM from 'react-dom'
import 'src/styles/shared.scss'
import 'src/utils/i18n'
import App from './App'
import createClient from './App/apollo/client'
import './index.css'

ReactDOM.render(
  <StyleProvider>
    <ApolloProvider client={createClient()}>
      <App />
    </ApolloProvider>
  </StyleProvider>,
  document.getElementById('root')
)
