import i18n from 'i18next'
import { environmentSettings } from 'src/config'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import {
  CapturePaymentRequest,
  CapturePaymentResponse,
  ConfirmPaymentRequest,
  ConfirmPaymentResponse,
  CreatePaymentRequest,
  CreatePaymentResponse,
  User
} from 'src/types/types'
import { membershipNameStrings } from 'src/utils/constants/strings'
import { generateITAId, getRoute } from 'src/utils/helper/helper'
import { setCurrentStep, setCurrentUser, storeToken } from 'src/utils/localStorage/local-storage'
import { http, itaClient } from './http'

export const getUser = async (
  client,
  membershipCode: MembershipCodeEnum | undefined
): Promise<User> => {
  const user = await http
    .get('/User/GetCurrentUser')
    .then(user => {
      client.writeData({ data: { externalId: generateITAId(user.data.Index) } })
      client.writeData({ data: { isLoggedIn: true } })
      setCurrentUser(JSON.stringify(user.data))
      return user.data
    })
    .catch(error => {
      setCurrentStep('0')
      const returnUrl = getRoute(membershipCode)

      const getMembershipName = () => {
        switch (membershipCode) {
          case MembershipCodeEnum.PLA:
            return membershipNameStrings.SUMMER_CIRCUIT
          case MembershipCodeEnum.CPM:
            return membershipNameStrings.TEAM_MEMBERSHIP
          case MembershipCodeEnum.CORP:
            return membershipNameStrings.CORPORATE_MEMBERSHIP
          case MembershipCodeEnum.CONF:
            return membershipNameStrings.CONFERENCE_MEMBERSHIP
          case MembershipCodeEnum.USTASEC:
            return membershipNameStrings.USTA_SECTION
          case MembershipCodeEnum.ASCPRO:
            return membershipNameStrings.ASSOCIATE_PROFFESIONAL
          case MembershipCodeEnum.COEM:
            return membershipNameStrings.COACH_EMERITUS_MEMBERSHIP
        }
      }

      const membershipName = getMembershipName()

      const membershipLabel = i18n.t(membershipName || 'membership')
      const membershipCookie = encodeURI(`type=${membershipName}&label=${membershipLabel}`)

      document.cookie = `membership=${membershipCookie}; domain=${environmentSettings.REACT_APP_CLASSIC_DOMAIN}`

      if (window !== 'undefined') {
        window.location.href = `${environmentSettings.REACT_APP_CLASSIC_API}/Account/YourDetails?returnUrl=${window.location.origin}${returnUrl}`
      }
    })
  return user
}

export async function getToken() {
  try {
    const tokenRes = await itaClient.get(`/Account/Tokens?clientId=clubspark-app`)
    const token = tokenRes.headers['x-api-token']
    storeToken(token)
    return token
  } catch (error) {
    console.log('error occured while trying to get token', error)
  }
}

export async function setToken() {
  const token = await getToken()
  if (token) storeToken(token)
}

export const createPayment = async (data: CreatePaymentRequest): Promise<CreatePaymentResponse> => {
  const response = await itaClient
    .post('/Payment/CreatePayment', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log('ERROR ', error)
    })
  return response
}

export const confirmPayment = async (
  data: ConfirmPaymentRequest
): Promise<ConfirmPaymentResponse> => {
  const response = await itaClient
    .post('/Payment/ConfirmPayment', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
  return response
}

export const capturePayment = async (
  data: CapturePaymentRequest
): Promise<CapturePaymentResponse> => {
  const response = await itaClient
    .post('/Payment/CapturePayment', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
  return response
}

export const getVenueAdmins = async (venueId: string): Promise<User[]> => {
  const venueAdmins = await itaClient
    .get('v1/Administrators/GetVenueAdministrators', {
      params: {
        venueID: venueId
      }
    })
    .then(result => result.data)
    .catch()

  return venueAdmins
}
