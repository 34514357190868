import { BodySmallBold, Button, H1 } from '@clubspark-react/clubspark-react-tools'
import { Grid, Hidden } from '@material-ui/core'
import { Field, Form } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LIST_OF_STATES } from 'src/utils/listOfStates'
import { usePlayerDetailsOptions } from 'src/utils/player-details-options'
import { ReactComponent as DownArrorSVG } from '../../../assets/icn_arrow-down.svg'
import FormErrorMessage from '../form-error-message/form-error-message'
import MembershipSummary from '../membership-summary/membership-summary'
import Select from '../select/select'
import styles from './summer-circuit-details-form.module.scss'
import { getRegistrationYear } from 'src/utils/helper/helper'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { usePrice } from 'src/hooks/use-price'
import { getTermId } from 'src/utils/localStorage/local-storage'

type InputProps = {
  field?: {
    name: string
    value: string
  }
}

const Input: React.FC<InputProps> = ({ field, ...props }: InputProps) => (
  <input className={styles.inputOutlined} {...field} {...props} />
)

const SummerCircuitDetailsForm = ({ values, setFieldValue, errors, touched }) => {
  const { t } = useTranslation()
  const { amount } = usePrice({
    skip: !getTermId(),
    variables: { input: { membershipCode: MembershipCodeEnum.PLA, termId: getTermId() } }
  })
  const {
    COLLEGIATE_DIVISION_OPTIONS,
    STATUS_OPTIONS,
    YEAR_IN_SCHOOL_OPTIONS
  } = usePlayerDetailsOptions()

  return (
    <Form>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <H1 spacing={{ margins: { md: 'top' } }}>{t('player details')}</H1>
          <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
            {t('city')}
          </BodySmallBold>
          <Field name="city" component={Input} />
          {errors?.city && touched?.city && <FormErrorMessage message={errors.city} />}
          <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
            {t('state')}
          </BodySmallBold>
          <Field
            name="state"
            // defaultValue={values.state}
            options={LIST_OF_STATES}
            component={Select}
            // value={values.state}
            onSelect={option => setFieldValue('state', option.abbreviation)}
            IconComponent={() => <DownArrorSVG />}
            style={{ borderRadius: 0 }}
          />
          {errors?.state && touched?.state && <FormErrorMessage message={errors.state} />}
          <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
            {t('status')}
          </BodySmallBold>
          <Field
            name="status"
            // defaultValue={values.state}
            options={STATUS_OPTIONS}
            component={Select}
            // value={values.state}
            onSelect={option => setFieldValue('status', option.value)}
            IconComponent={() => <DownArrorSVG />}
            style={{ borderRadius: 0 }}
          />
          {errors?.status && touched?.status && <FormErrorMessage message={errors.status} />}
          <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
            {t('year in school')} ({getRegistrationYear()})
          </BodySmallBold>
          <Field
            name="yearInSchool"
            // defaultValue={values.state}
            options={YEAR_IN_SCHOOL_OPTIONS}
            component={Select}
            // value={values.state}
            onSelect={option => setFieldValue('yearInSchool', option.value)}
            IconComponent={() => <DownArrorSVG />}
            style={{ borderRadius: 0 }}
          />
          {errors?.yearInSchool && touched?.yearInSchool && (
            <FormErrorMessage message={errors.yearInSchool} />
          )}
          <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
            {t('collegiate division')} ({getRegistrationYear()})
          </BodySmallBold>
          <Field
            name="collegiateDivision"
            // defaultValue={values.state}
            options={COLLEGIATE_DIVISION_OPTIONS}
            component={Select}
            // value={values.state}
            onSelect={option => setFieldValue('collegiateDivision', option.value)}
            IconComponent={() => <DownArrorSVG />}
            style={{ borderRadius: 0 }}
          />
          {errors?.collegiateDivision && touched?.collegiateDivision && (
            <FormErrorMessage message={errors.collegiateDivision} />
          )}
          <Button
            type="submit"
            size="lg"
            spacing={{ margins: { lg: 'top' } }}
            classname={styles.button}
          >
            {t('continue')}
          </Button>
        </Grid>
        <Hidden xsDown>
          <MembershipSummary cost={amount} />
        </Hidden>
      </Grid>
    </Form>
  )
}

export default SummerCircuitDetailsForm
