import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Spinner } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { navigate, useLocation } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { setRegistrationStep } from 'src/App/apollo/local-state'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { environmentSettings } from 'src/config'
import { GetSchools } from 'src/graphql-types/GetSchools'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useRouteMembership } from 'src/hooks/use-existing-membership'
import { HOME_ROUTE, PAYMENT_CONFIRMATION_ROUTE } from 'src/utils/constants/routes'
import { getRoute, useOrderItems } from 'src/utils/helper/helper'
import { getTeamMembershipTransaction } from 'src/utils/helper/transactionPayloads'
import {
  getCurrentStep,
  getTermId,
  setCurrentStep,
  setPaymentMethod
} from 'src/utils/localStorage/local-storage'
import { COLLEGE_MEMBERSHIP_TRANSACTION } from '../payment-dialog/payment-dialog-queries'
import { GET_SCHOOLS } from '../register-school-form/register-school-form-queries'
import styles from './stepper-actions.module.scss'

interface ActionProps {
  setStep?: React.Dispatch<React.SetStateAction<number>>
  loading?: boolean
  disabled?: boolean
  isSchoolSelected?: boolean
}

export const RegisterDetailsActions: React.FC<ActionProps> = ({
  loading,
  disabled,
  isSchoolSelected
}) => {
  const { t } = useTranslation()
  const isButtonDisabled = disabled || (getCurrentStep() === '0' && !isSchoolSelected)

  const handleBack = () => {
    navigate(HOME_ROUTE)
  }

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.root}>
          <Button
            type="button"
            classname={styles.buttonBack}
            size="lg"
            level="secondary"
            onClick={handleBack}
          >
            {t('back')}
          </Button>
          <Button type="submit" size="lg" classname={styles.button} disabled={isButtonDisabled}>
            {t('continue')}
          </Button>
        </div>
      )}
    </>
  )
}

type RegisterPaymentActionProps = ActionProps & {
  isSelected: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedIndex: number
}

export const RegisterPaymentActions: React.FC<RegisterPaymentActionProps> = ({
  isSelected,
  setOpen,
  selectedIndex
}) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [integrateCollegeMembershipTransaction] = useMutation(COLLEGE_MEMBERSHIP_TRANSACTION)
  const membershipCode = useRouteMembership()
  const { loading: loadingTerms, hasMultipleActiveTerms } = useMembershipTerms(
    membershipCode as MembershipCodeEnum
  )
  const { search } = useLocation()
  const isChangePayment = search?.includes('change-payment')

  const { data: schoolsInfo } = useQuery<GetSchools>(GET_SCHOOLS, {
    skip: membershipCode !== MembershipCodeEnum.CPM
  })
  const [loading, setLoading] = React.useState<boolean>(false)
  const orderItems = useOrderItems(false, isChangePayment)

  const handleContinue = async () => {
    // Credit Card
    if (selectedIndex === 0) {
      setPaymentMethod('credit-card')
      setOpen(true)
    } else {
      // Invoice
      setPaymentMethod('invoice')
      setLoading(true)
      const transaction = getTeamMembershipTransaction(schoolsInfo, orderItems)

      const invoiceTransaction = {
        ...transaction,
        organisation: {
          ...transaction.organisation
        }
      }

      const collegeMembershipTransaction = await integrateCollegeMembershipTransaction({
        variables: { input: { ...invoiceTransaction, termId: getTermId() } }
      })
      if (collegeMembershipTransaction) {
        setLoading(false)
        setCurrentStep(hasMultipleActiveTerms ? '3' : '2')
        setRegistrationStep('2', client)
        navigate(PAYMENT_CONFIRMATION_ROUTE)
      }
    }
  }

  const handleBack = () => {
    setCurrentStep('0')
    setRegistrationStep('0', client)
    const route = getRoute(membershipCode)
    navigate(route)
  }

  return (
    <>
      {loading || loadingTerms ? (
        <Spinner />
      ) : (
        <Grid container alignItems="center">
          <Button
            classname={styles.buttonBack}
            size="lg"
            level="secondary"
            type="button"
            spacing={{ margins: { md: ['right', 'bottom'], lg: 'top' } }}
            onClick={handleBack}
          >
            {t('back')}
          </Button>
          <Button
            size="lg"
            disabled={!isSelected}
            onClick={handleContinue}
            spacing={{ margins: { md: 'bottom', lg: 'top' } }}
            classname={styles.button}
          >
            {isSelected ? t('submit') : t('continue')}
          </Button>
        </Grid>
      )}
    </>
  )
}

export const RegisterPaymentConfirmationActions: React.FC<ActionProps> = ({ setStep }) => {
  const { t } = useTranslation()
  const handleContinue = async () => {
    navigate(`${environmentSettings.REACT_APP_CLASSIC_API}/Admin`)
  }
  return (
    <>
      <Button
        onClick={handleContinue}
        spacing={{ margins: { md: 'bottom', lg: 'top' } }}
        classname={styles.button}
      >
        {t('manage team')}
      </Button>
    </>
  )
}
