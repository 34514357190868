import { TextInput } from '@clubspark-react/clubspark-react-tools'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { useState } from 'react'
import { ReactComponent as DownArrorSVG } from '../../../assets/icn_arrow-down.svg'
import styles from './select.module.scss'

type Props = {
  options: any
  onSelect?: any
  defaultValue?: any
  field?: {
    name: string
    value: string
  }
  placeholder?: string
}

const MUISelect: React.FC<Props> = ({
  options,
  onSelect,
  field,
  placeholder,
  defaultValue = ''
}: Props) => {
  const [selected, setSelected] = useState(defaultValue)
  const [open, setOpen] = useState(false)

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const option = options.find(o => o.value === e.target.value)
    option && onSelect && onSelect(option)
    setSelected(option.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Select
      {...field}
      open={open}
      value={selected}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      className={styles.select}
      classes={{
        outlined: styles.rootOverride,
        select: styles.selectOverride
      }}
      input={<TextInput outlined disableUnderline fullWidth placeholder="placeholder" />}
      IconComponent={DownArrorSVG}
    >
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default MUISelect
