import {
  BodyRegular,
  BodyRegularBold,
  BodySmall,
  Button,
  H3,
  H4,
  H5
} from '@clubspark-react/clubspark-react-tools'
import { Box, Grid, GridProps, Hidden } from '@material-ui/core'
import graphql from 'graphql-tag'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getOrgData, getTeams, getTermId } from 'src/utils/localStorage/local-storage'
import SelectedSchool from 'src/App/components/selected-school/selected-school'
import styles from './membership-summary.module.scss'
import { Teams } from 'src/types/types'
import usePackageDates from 'src/hooks/use-package-dates'
import { useRouteMembership } from 'src/hooks/use-existing-membership'
import moment from 'moment'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { getMembershipName } from 'src/utils/helper/helper'

type Props = {
  selectedTeam?: string
  cost?: number | null
  name?: string
}

export const GET_SELECTED_TEAM = graphql`
  query GetSelectedTeam {
    selectedTeam @client
  }
`

interface DiscountProps extends GridProps {
  discount?: number
  cost?: number
}

const Discount: React.FC<DiscountProps> = ({ cost, discount, ...props }) => {
  const { t } = useTranslation()

  const discountValue = (Number(cost) * Number(discount)) / 100

  return (
    <Grid {...props} container justify="space-between" alignItems="center">
      <Grid item>
        <BodyRegularBold classnames={styles.discountText}>
          {t('early discount', { discount })}
        </BodyRegularBold>
      </Grid>
      <Grid item>
        <BodyRegularBold classnames={styles.discountText}>
          -${(discountValue || 0).toFixed(2)}
        </BodyRegularBold>
      </Grid>
    </Grid>
  )
}

const MembershipSummary: React.FC<Props> = ({ cost = 0 }: Props) => {
  const { t } = useTranslation()
  const membershipCode = useRouteMembership()
  const orgData = getOrgData()
  const packageDates = usePackageDates(membershipCode)
  const { start, end } = packageDates || {}
  const { startDate, endDate } = {
    startDate: moment.utc(start).format('MMM DD, YYYY'),
    endDate: moment.utc(end).format('MMM DD, YYYY')
  }

  const { terms } = useMembershipTerms(membershipCode)
  const termId = getTermId()
  const term = terms?.find(term => term?.id === termId)
  const termName = term?.name || ''

  const [expanded, setExpanded] = useState(false)
  const schoolName = orgData?.school || ''
  const teams = getTeams() as Teams

  const showSelectedSchool = membershipCode === MembershipCodeEnum.CPM
  const showDiscount = false

  const handleClick = () => {
    setExpanded(!expanded)
  }

  // avoid null
  const safeCost = cost ? cost : 0

  // Expanded mobile view
  const Items = () => {
    return (
      <>
        <Grid container>
          <H5>{getMembershipName({ membershipCode, teams, termName })}</H5>
          <H5 spacing={{ margins: { auto: 'left' } }}>{`$${safeCost.toFixed(2)}`}</H5>
        </Grid>
        <BodySmall spacing={{ margins: { xs: 'bottom' } }}>{`${startDate} - ${endDate}`}</BodySmall>
        {showSelectedSchool && (
          <SelectedSchool schoolName={schoolName} teams={teams} className={styles.selectedSchool} />
        )}
        {showDiscount && (
          <Discount cost={safeCost} discount={40} className={styles.discountContainer} />
        )}
        <>
          <div className={styles.separator}></div>
          <Grid container>
            <BodyRegularBold spacing={{ margins: { xs: 'top', sm: 'bottom' } }}>
              {t('total')}
            </BodyRegularBold>
            <H5 spacing={{ margins: { auto: 'left', xs: 'top' } }}>{`$${safeCost.toFixed(2)}`}</H5>
          </Grid>
        </>
      </>
    )
  }

  const DesktopMembershipSummary = () => {
    return (
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Hidden only="xs">
          <Grid container item xs={12} md={12} lg={12} justify="flex-end">
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <Box m={{ xs: 0, sm: '20px 0 20px 0', md: '20px 0 20px 0' }}>
                <div className={styles.summary}>
                  <H3 spacing={{ margins: { md: 'bottom' } }}>{t('membership summary')}</H3>
                  <Grid container>
                    <BodyRegularBold>
                      {getMembershipName({ membershipCode, teams, termName })}
                    </BodyRegularBold>
                    <BodyRegularBold spacing={{ margins: { auto: 'left' } }}>
                      {`$${safeCost.toFixed(2)}`}
                    </BodyRegularBold>
                  </Grid>
                  <BodyRegular>{`${startDate} - ${endDate}`}</BodyRegular>
                  {showSelectedSchool && (
                    <SelectedSchool
                      schoolName={schoolName}
                      teams={teams}
                      className={styles.selectedSchool}
                    />
                  )}
                  {showDiscount && (
                    <Discount cost={safeCost} discount={40} className={styles.discountContainer} />
                  )}
                  <Grid container className={styles.expandibleSection}>
                    <Grid container justify="center" className={styles.totalContainer}>
                      <H4>{t('total')}</H4>
                      <H4 spacing={{ margins: { auto: 'left' } }}>${safeCost.toFixed(2)}</H4>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    )
  }

  return (
    <>
      <Hidden xsDown>
        <DesktopMembershipSummary />
      </Hidden>
      <Hidden smUp>
        <Grid className={expanded ? styles.expanded : styles.hidden}></Grid>
        <Grid container direction="column" className={expanded ? styles.container : styles.regular}>
          <h1 className={expanded ? styles.expandedHeading : styles.condensedHeading}>
            {t('membership summary')}
          </h1>
          {expanded && <Items />}
          <Grid item xs={6}>
            <Button
              level="link"
              linkStyle
              onClick={handleClick}
              classname={styles.button}
              style={{ paddingLeft: 0 }}
            >
              {expanded ? t('close summary') : t('show summary')}
            </Button>
          </Grid>
        </Grid>
      </Hidden>
    </>
  )
}

export default MembershipSummary
