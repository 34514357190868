import { BodyLarge, H1, H3 } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MEMBERSHIPS_INFO, useRouteMembership } from 'src/hooks/use-existing-membership'
import { getPaymentMethod } from 'src/utils/localStorage/local-storage'
import SpanningTable from '../spanning-table/spanning-table'
import styles from './usta-section-confirmation.module.scss'

interface USTASectionPaymentConfirmationProps extends RouteComponentProps {}

const USTASectionPaymentConfirmation: React.FC<USTASectionPaymentConfirmationProps> = () => {
  const { t } = useTranslation()
  const method = getPaymentMethod()
  const membershipCode = useRouteMembership()
  const membershipName = MEMBERSHIPS_INFO.find(m => m.code === membershipCode)?.name || ''

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12}>
          <H1 spacing={{ margins: { md: 'top' } }}>{t('thank you')}</H1>
        </Grid>
        <Grid item xs={12} md={10} lg={9}>
          <BodyLarge spacing={{ margins: { md: ['top', 'bottom'] } }}>
            {method === 'invoice'
              ? t('confirmation text invoice', { membership: membershipName })
              : t('confirmation text cc', { membership: membershipName })}
          </BodyLarge>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.membershipSummary}>
            <div className={styles.summaryTitle}>
              <H3>{t('order confirmation')}</H3>
            </div>
            <SpanningTable isConfirmation />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default USTASectionPaymentConfirmation
