import axios from 'axios'
import { environmentSettings } from 'src/config'

export const http = axios.create({
  baseURL: `${environmentSettings.REACT_APP_CLASSIC_API}/v2`,
  withCredentials: true
})

export const itaClient = axios.create({
  baseURL: `${environmentSettings.REACT_APP_CLASSIC_API}`,
  withCredentials: true
})
