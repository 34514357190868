import { useQuery } from '@apollo/react-hooks'
import { GET_OWN_MEMBERS } from 'src/App/auth/register/register-queries'
import { GetOwnMembers, GetOwnMembersVariables } from 'src/graphql-types/GetOwnMembers'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { getTermId, retrieveToken } from 'src/utils/localStorage/local-storage'
import { useMemberships } from './use-memberships'

interface UseOwnMembersProps {
  code: MembershipCodeEnum
  skip?: boolean
}

export function useOwnMembers({ code, skip }: UseOwnMembersProps) {
  const termId = getTermId()
  const { membership } = useMemberships({
    code
  })
  return useQuery<GetOwnMembers, GetOwnMembersVariables>(GET_OWN_MEMBERS, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    variables: {
      membershipId: membership?.id as string,
      termIds: [termId] as string[]
    },
    skip: !membership?.id || !termId || !retrieveToken() || skip
  })
}
