import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { Box, Hidden } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser, setToken } from 'src/api-service/api-service'
import { GET_EXTERNAL_ID } from 'src/App/apollo/local-state'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import {
  EMERITUS_DETAILS_ROUTE,
  EMERITUS_PAYMENT_CONFIRMATION_ROUTE,
  EMERITUS_PAYMENT_ROUTE
} from 'src/utils/constants/routes'
import { COACH_EMERITUS_MEMBERSHIP } from 'src/utils/constants/strings'
import { RouteCheck } from 'src/utils/helper/helper'
import {
  getCurrentStep,
  getCurrentUser,
  getTermId,
  setCurrentStep
} from 'src/utils/localStorage/local-storage'
import EmeritusPaymentConfirmation from '../emeritus-confirmation/emertius-confirmation'
import EmeritusDetails from '../emeritus-details/emeritus-details'
import MembershipSummary from '../membership-summary/membership-summary'
import PageContainer from '../page-container/page-container'
import PageWidth from '../page-width/page-width'
import RegisterPayment from '../register-payment/register-payment'
import MuiStepper from '../stepper/stepper'
import usePackageDates from 'src/hooks/use-package-dates'
import { usePrice } from 'src/hooks/use-price'
import { ApplicableSteps, InitialStep, useMembershipTerms } from 'src/App/auth/register/register'
import RegisterTerms from '../register-terms/register-terms'
import { useExistingMembership } from 'src/hooks/use-existing-membership'
import { useToken } from 'src/hooks/use-token'

interface EmeritusMembershipProps extends RouteComponentProps {}

interface EmeritusMembershipViewProps {
  amount: number
  hasMultipleActiveTerms?: boolean
  loadingExistingMembership?: boolean
}

function EmeritusMembershipView({
  amount,
  hasMultipleActiveTerms = true,
  loadingExistingMembership = true
}: EmeritusMembershipViewProps) {
  const { t } = useTranslation()
  const currentStep = getCurrentStep()

  const initialSteps: InitialStep[] = [
    {
      key: '1',
      value: t('terms'),
      visible: hasMultipleActiveTerms,
      Component: (
        <RegisterTerms code={MembershipCodeEnum.COEM} navigateRoute={EMERITUS_DETAILS_ROUTE} />
      )
    },
    { key: '2', value: t('details'), visible: true, Component: <EmeritusDetails /> },
    {
      key: '3',
      value: t('payment'),
      visible: true,
      Component: (
        <RegisterPayment path={EMERITUS_PAYMENT_ROUTE} membership={COACH_EMERITUS_MEMBERSHIP} />
      )
    },
    {
      key: '4',
      value: t('confirmation'),
      visible: true,
      Component: <EmeritusPaymentConfirmation path={EMERITUS_PAYMENT_CONFIRMATION_ROUTE} />
    }
  ]

  const applicableSteps = initialSteps.filter(s => s.visible)
  const stepNames = applicableSteps.map(s => s.value)

  return (
    <>
      <Hidden smUp>
        <RouteCheck>
          <MembershipSummary cost={amount} />
        </RouteCheck>
      </Hidden>
      <Box clone p={{ xs: '0 20px 0 20px', sm: '0 20px 0 20px', md: '0 135px 0 135px' }}>
        <PageContainer>
          <PageWidth>
            <MuiStepper
              steps={stepNames}
              children={
                <ApplicableSteps steps={applicableSteps} currentStep={currentStep ?? '0'} />
              }
              activeStep={Number(currentStep)}
              loadingPage={loadingExistingMembership}
            />
          </PageWidth>
        </PageContainer>
      </Box>
    </>
  )
}

function EmeritusMembershipContainer({ token }: { token: string }) {
  const { loadingExistingMembership } = useExistingMembership(MembershipCodeEnum.COEM, !token)
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.COEM, !token)
  const { amount } = usePrice({
    skip: !getTermId(),
    variables: { input: { membershipCode: MembershipCodeEnum.COEM, termId: getTermId() } }
  })
  usePackageDates(MembershipCodeEnum.COEM)

  return (
    <EmeritusMembershipView
      loadingExistingMembership={loadingExistingMembership}
      hasMultipleActiveTerms={hasMultipleActiveTerms}
      amount={amount || 0}
    />
  )
}

const EmeritusMembership: React.FC<EmeritusMembershipProps> = () => {
  const client = useApolloClient()

  const currentStep = getCurrentStep()
  const user = getCurrentUser()
  const { token } = useToken()

  const { data: userId } = useQuery(GET_EXTERNAL_ID, { client })

  useEffect(() => {
    if (!user) getUser(client, MembershipCodeEnum.COEM)
    if (!token) setToken()
    if (!currentStep || currentStep == null) setCurrentStep('0')
  }, [userId, currentStep, client, user, token])

  if (!token) {
    return <EmeritusMembershipView amount={0} />
  }

  return <EmeritusMembershipContainer token={token} />
}

export default EmeritusMembership
