import { useQuery } from '@apollo/react-hooks'
import { navigate, useLocation } from '@reach/router'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { GET_SCHOOLS } from 'src/App/components/register-school-form/register-school-form-queries'
import { environmentSettings } from 'src/config'
import { GetSchools, GetSchools_schools as School } from 'src/graphql-types/GetSchools'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useRouteMembership } from 'src/hooks/use-existing-membership'
import { useOwnMembers } from 'src/hooks/use-own-members'
import usePackageDates from 'src/hooks/use-package-dates'
import { usePrice } from 'src/hooks/use-price'
import { useSchoolPrice } from 'src/hooks/use-school-price'
import { StripeMetadata, Teams } from 'src/types/types'
import {
  ASSOCIATE_DETAILS_ROUTE,
  CONFERENCE_DETAILS_ROUTE,
  CORPORATE_DETAILS_ROUTE,
  EMERITUS_DETAILS_ROUTE,
  REGISTER_SCHOOL_DETAILS_ROUTE,
  SC_DETAILS_ROUTE,
  USTA_SECTION_DETAILS_ROUTE
} from '../constants/routes'
import {
  ASSOCIATE_PROFFESIONAL,
  COACH_EMERITUS_MEMBERSHIP,
  CONFERENCE_MEMBERSHIP,
  CORPORATE_MEMBERSHIP,
  SUMMER_CIRCUIT,
  TEAM_MEMBERSHIP,
  USTA_SECTION
} from '../constants/strings'
import {
  getTeams,
  getTermId,
  retrieveToken,
  getOrgData,
  getConferenceInfo,
  setCurrentStep
} from '../localStorage/local-storage'

export const validateMail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const useScrollToTop = () => {
  const location = useLocation()
  useEffect(() => window.scrollTo(0, 0), [location.pathname])
}

export const useStepReset = () => {
  const { search, pathname } = useLocation()
  const step = new URLSearchParams(search).get('step')
  if (step === '0') {
    setCurrentStep(step)
    navigate(pathname)
  }

}

export const getSchool = (name: string, schools: any): School => {
  return schools.find(s => s.name === name)!
}

export const getDescription = (school, membershipCode: MembershipCodeEnum | undefined) => {
  let teamString = ''
  const teams = getTeams()

  switch (teams) {
    case Teams.MEN:
      teamString = "Men's Team"
      break
    case Teams.WOMEN:
      teamString = "Women's Team"
      break
    case Teams.MEN_AND_WOMEN:
      teamString = "Men's & Women’s Team"
      break
    default:
      teamString = ''
  }

  switch (membershipCode) {
    case MembershipCodeEnum.CPM:
      return `${school} ${teamString}`
    case MembershipCodeEnum.PLA:
      return 'Summer Circuit'
    case MembershipCodeEnum.CORP:
      return 'ITA Corporate Membership'
    case MembershipCodeEnum.CONF:
      return 'ITA Conference Membership'
    case MembershipCodeEnum.USTASEC:
      return 'ITA USTA Section Membership'
    case MembershipCodeEnum.ASCPRO:
      return 'ITA Associate/Professional Membership'
    default:
      return ''
  }
}

export const generateSCAditionalData = (data: any) => {
  const additionalInfo = Object.entries(data).map(([key, value]) => {
    return { name: key, value: value }
  })
  return additionalInfo
}

export const getMembershipCode = (membership: string | undefined | null) => {
  switch (membership) {
    case TEAM_MEMBERSHIP:
      return MembershipCodeEnum.CPM
    case SUMMER_CIRCUIT:
      return MembershipCodeEnum.PLA
    case CORPORATE_MEMBERSHIP:
      return MembershipCodeEnum.CORP
    case CONFERENCE_MEMBERSHIP:
      return MembershipCodeEnum.CONF
    case USTA_SECTION:
      return MembershipCodeEnum.USTASEC
    case ASSOCIATE_PROFFESIONAL:
      return MembershipCodeEnum.ASCPRO
    case COACH_EMERITUS_MEMBERSHIP:
      return MembershipCodeEnum.COEM
    default:
      return null
  }
}

export const getMembershipByCode = (code: MembershipCodeEnum | null) => {
  switch (code) {
    case MembershipCodeEnum.CPM:
      return TEAM_MEMBERSHIP
    case MembershipCodeEnum.PLA:
      return SUMMER_CIRCUIT
    case MembershipCodeEnum.CORP:
      return CORPORATE_MEMBERSHIP
    case MembershipCodeEnum.CONF:
      return CONFERENCE_MEMBERSHIP
    case MembershipCodeEnum.USTASEC:
      return USTA_SECTION
    case MembershipCodeEnum.ASCPRO:
      return ASSOCIATE_PROFFESIONAL
    case MembershipCodeEnum.COEM:
      return COACH_EMERITUS_MEMBERSHIP
    default:
      return ''
  }
}

function getTeamMembershipName(teams: Teams | undefined, termName: string) {
  switch (teams) {
    case Teams.MEN:
    case Teams.WOMEN:
      return `${termName} ITA Team Membership`
    case Teams.MEN_AND_WOMEN:
    case Teams.MEN_AND_WOMEN_ALTERNATIVE:
    case Teams.TEAMS:
      return `${termName} ITA Team Membership x 2`
    default:
      return ''
  }
}

function getOtherMembershipName(membershipCode: MembershipCodeEnum | undefined, termName: string) {
  switch (membershipCode) {
    case MembershipCodeEnum.PLA:
      return `${termName} ITA Player Membership`
    case MembershipCodeEnum.CORP:
      return `${termName} ITA Corporate Membership`
    case MembershipCodeEnum.CONF:
      return `${termName} ITA Conference Membership`
    case MembershipCodeEnum.USTASEC:
      return `${termName} USTA Section Membership`
    case MembershipCodeEnum.ASCPRO:
      return `${termName} ITA Associate/Professional Membership`
    case MembershipCodeEnum.COEM:
      return `${termName} ITA Coach Emeritus`
    default:
      return ''
  }
}

export function getMembershipName({
  membershipCode,
  termName,
  teams
}: {
  membershipCode: MembershipCodeEnum | undefined
  termName: string
  teams: Teams | undefined
}) {
  if (membershipCode === MembershipCodeEnum?.CPM) {
    return getTeamMembershipName(teams, termName)
  }

  return getOtherMembershipName(membershipCode, termName)
}

export function findSchool(
  schoolsInfo: GetSchools | undefined,
  isConfirmation: boolean | undefined,
  externalIds?: (string | null | undefined)[]
) {
  const orgData = getOrgData()

  if (isConfirmation) {
    return schoolsInfo?.schools?.find(
      school =>
        externalIds?.includes(school?.manId as string) ||
        externalIds?.includes(school?.womanId as string)
    )
  }

  return schoolsInfo?.schools?.find(school => school?.name === orgData?.school)
}

export const useOrderItems = (isConfirmation?: boolean, isChangePayment?: boolean) => {
  const membershipCode = useRouteMembership()
  const termId = getTermId()

  const { data } = useOwnMembers({
    code: membershipCode as MembershipCodeEnum
  })

  const { data: schoolsInfo } = useQuery<GetSchools>(GET_SCHOOLS, {
    skip: !retrieveToken()
  })

  const externalIds = data?.getOwnMembers?.map(m => m?.organisationId?.externalId)

  const school = findSchool(schoolsInfo, isConfirmation, externalIds)
  const teams = getTeams()
  const { manId, womanId } = school || {}

  const member = data?.getOwnMembers?.[0]
  const division =
    getConferenceInfo()?.division ||
    member?.additionalInfo?.find(a => a?.name === 'division')?.value

  const { amount: teamMembershipAmount } = useSchoolPrice({
    skip: !termId || (!manId && !womanId) || membershipCode !== MembershipCodeEnum.CPM,
    variables: {
      getPriceForSchoolInput: {
        membershipCode: MembershipCodeEnum.CPM,
        termId,
        teamIds: {
          ...(teams === Teams.WOMEN || teams === Teams.MEN_AND_WOMEN
            ? { femaleTeamId: school?.womanId }
            : {}),
          ...(teams === Teams.MEN || teams === Teams.MEN_AND_WOMEN
            ? { maleTeamId: school?.manId }
            : {})
        }
      }
    }
  })

  const { amount } = usePrice({
    variables: {
      input: {
        membershipCode: membershipCode,
        termId,
        ...(membershipCode === MembershipCodeEnum.CONF && {
          division: division
        })
      }
    },
    skip: membershipCode === MembershipCodeEnum.CPM
  })

  const { terms } = useMembershipTerms(membershipCode)
  const term = terms?.find(term => term?.id === termId)
  const termName = term?.name || ''
  const { startDate, endDate } = useDates(membershipCode as MembershipCodeEnum)
  const period = startDate && endDate ? `${startDate} - ${endDate}` : ''

  return {
    name: getMembershipName({
      membershipCode,
      teams: membershipCode === MembershipCodeEnum.CPM ? teams : undefined,
      termName
    }),
    period,
    amount: membershipCode === MembershipCodeEnum.CPM ? teamMembershipAmount : (amount as number)
  }
}

export function useDates(membershipCode: MembershipCodeEnum) {
  const packageDates = usePackageDates(membershipCode)
  const { start, end } = packageDates || {}
  return {
    startDate: moment.utc(start).format('MMM DD, YYYY'),
    endDate: moment.utc(end).format('MMM DD, YYYY')
  }
}

export const generateITAId = (index: number): string => {
  let itaId: string = index ? String(index) : ''

  while (itaId.length < 7) {
    itaId = '0' + itaId
  }
  itaId = 'ITA' + itaId
  return itaId
}

// prettier-ignore
export const generateStripeMetadata = (metadata: StripeMetadata): any => {
  return {
    "Customer First Name": metadata.firstName,
    "Customer Surname": metadata.lastName,
    "Description": metadata.description,
    "Category": metadata.category,
    "Sub Category": metadata.subCategory,
    "Number Of Teams": metadata.numberOfTeams,
    "Organization": metadata.organization,
    "Team": metadata.team,
    "Start Date": metadata.startDate,
    "Expiry Date": metadata.expiryDate,
    "Email": metadata.email,
    "ITA ID": metadata.itaId
  }
}

export const getRoute = (membershipCode: MembershipCodeEnum | undefined) => {
  switch (membershipCode) {
    case MembershipCodeEnum.CPM:
      return `${REGISTER_SCHOOL_DETAILS_ROUTE}`
    case MembershipCodeEnum.CORP:
      return `${CORPORATE_DETAILS_ROUTE}`
    case MembershipCodeEnum.CONF:
      return `${CONFERENCE_DETAILS_ROUTE}`
    case MembershipCodeEnum.USTASEC:
      return `${USTA_SECTION_DETAILS_ROUTE}`
    case MembershipCodeEnum.ASCPRO:
      return `${ASSOCIATE_DETAILS_ROUTE}`
    case MembershipCodeEnum.COEM:
      return `${EMERITUS_DETAILS_ROUTE}`
    default:
      return ''
  }
}

export const getReturnUrl = (membership: string) => {
  const rootUrl = environmentSettings.REACT_APP_CLIENT_ROOT
  switch (membership) {
    case TEAM_MEMBERSHIP:
      return `${rootUrl}${REGISTER_SCHOOL_DETAILS_ROUTE}`
    case SUMMER_CIRCUIT:
      return `${rootUrl}${SC_DETAILS_ROUTE}`
    case CORPORATE_MEMBERSHIP:
      return `${rootUrl}${CORPORATE_DETAILS_ROUTE}`
    case CONFERENCE_MEMBERSHIP:
      return `${rootUrl}${CONFERENCE_DETAILS_ROUTE}`
    case USTA_SECTION:
      return `${rootUrl}${USTA_SECTION_DETAILS_ROUTE}`
    case ASSOCIATE_PROFFESIONAL:
      return `${rootUrl}${ASSOCIATE_DETAILS_ROUTE}`
    case COACH_EMERITUS_MEMBERSHIP:
      return `${rootUrl}${EMERITUS_DETAILS_ROUTE}`
    default:
      return `${rootUrl}${REGISTER_SCHOOL_DETAILS_ROUTE}`
  }
}

export const RouteCheck = ({ children }) => {
  const location = useLocation()

  // Hides MembershipSummary from payment and payment-confirmation routes
  if (location?.pathname.match(/payment|payment-confirmation/)) {
    return null
  }

  return <>{children}</>
}

export const getRegistrationYear = () => {
  const thisYear = new Date().getFullYear()

  const nextYear = thisYear + 1

  return `${thisYear}-${nextYear}`
}
