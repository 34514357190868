import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText, { ListItemTextProps } from '@material-ui/core/ListItemText'
import React from 'react'

interface MuiListProps {
  children: React.ReactNode
  className?: string
}

export const MuiList: React.FC<MuiListProps> = ({ children, className }) => {
  return <List className={className}>{children}</List>
}

interface ListItemProps {
  selected: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  className?: string
  classes?: any
  children: React.ReactNode
  button?: boolean
}

export const MuiListItem: React.FC<ListItemProps> = ({
  children,
  selected,
  onClick,
  className,
  classes
}) => {
  return (
    <ListItem
      button
      disableRipple
      selected={selected}
      onClick={onClick}
      className={className}
      classes={classes}
    >
      {children}
    </ListItem>
  )
}

interface MuiListItemTextProps extends ListItemTextProps {}

export const MuiListItemText: React.FC<MuiListItemTextProps> = ({ ...props }) => {
  return <ListItemText {...props} />
}
