import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { GET_MEMBERSHIPS } from 'src/App/apollo/queries'
import { GetMemberships, GetMembershipsVariables } from 'src/graphql-types/GetMemberships'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { retrieveToken } from 'src/utils/localStorage/local-storage'

interface Props {
  options?: QueryHookOptions<GetMemberships, GetMembershipsVariables>
  code?: MembershipCodeEnum
}

export function useMemberships(props?: Props) {
  const { options, code } = props || {}
  const query = useQuery(GET_MEMBERSHIPS, {
    fetchPolicy: 'network-only',
    variables: { pagination: { page: 1, entriesPerPage: 20 }, ...options?.variables },
    skip: !retrieveToken(),
    ...options,
  })
  return { ...query, membership: query.data?.memberships?.data?.find(m => m?.code === code) }
}
