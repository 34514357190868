import { Spinner } from '@clubspark-react/clubspark-react-tools'
import React from 'react'
import styles from './loading.module.scss'

const Loading: React.FC = () => {
  return (
    <div className={styles.loadingState}>
      <Spinner />
    </div>
  )
}

export default Loading
