import { BodyLargeBold, BodyRegular } from '@clubspark-react/clubspark-react-tools'
import { Grid, Divider } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useRouteMembership } from 'src/hooks/use-existing-membership'
import { OrderItem } from 'src/types/types'
import { useOrderItems } from 'src/utils/helper/helper'
import { getOrgData, getPaymentMethod, getTeams } from 'src/utils/localStorage/local-storage'
import Loading from '../loading-component/loading'
import SelectedSchool from '../selected-school/selected-school'
import localStyles from './spinning-table.module.scss'

interface Props {
  isConfirmation?: boolean
  isChangePayment?: boolean
  totalPrice?: number
}

const SpanningTable: React.FC<Props> = ({ isConfirmation, isChangePayment, totalPrice }) => {
  const { t } = useTranslation()
  const method = getPaymentMethod()
  const membershipCode = useRouteMembership()

  const totalText = !isConfirmation
    ? t('total to pay')
    : method === 'invoice'
    ? t('total to pay')
    : t('total paid')

  // This flag is passed to `useOrderItems` hook and hook forwards it to `findSchool()` function
  //  We need it to be false for CPM memberships so that `findSchools()` function gets the correct school by looking into local storage and not externalId's
  const isConfirmationCPM = membershipCode === MembershipCodeEnum.CPM && isConfirmation

  const orderItems = useOrderItems(isConfirmationCPM ? false : isConfirmation, isChangePayment)

  const total = orderItems?.amount
  const finalTotal = totalPrice ?? total

  if (!Number.isFinite(finalTotal)) {
    return (
      <Grid container direction="column" className={localStyles.tableContainer}>
        <Loading />
      </Grid>
    )
  }

  return (
    <Grid container direction="column" className={localStyles.tableContainer}>
      <BodyRegular
        classnames={localStyles.typographyMembership}
        spacing={{ margins: { md: 'bottom' } }}
      >
        {t('membership')}
      </BodyRegular>
      <OrderSummary orderItems={orderItems} finalTotal={finalTotal} />
      <Divider className={localStyles.divider} />
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        justify="space-between"
        className={localStyles.containerTotal}
      >
        <BodyLargeBold classnames={localStyles.typographyTotal}>{totalText}</BodyLargeBold>
        <BodyLargeBold classnames={localStyles.typographyTotal}>
          ${(finalTotal || 0).toFixed(2)}
        </BodyLargeBold>
      </Grid>
    </Grid>
  )
}

interface OrderSummaryProps {
  orderItems?: OrderItem
  finalTotal?: number | null
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ orderItems: item, finalTotal }) => {
  const membershipCode = useRouteMembership()
  const orgData = getOrgData()
  const teams = getTeams()

  return (
    <>
      {item?.name && item?.amount && item?.period && (
        <OrderSummaryItem
          name={item?.name}
          amount={finalTotal ?? item?.amount}
          period={item?.period}
        />
      )}
      {membershipCode === MembershipCodeEnum.CPM && (
        <SelectedSchool
          schoolName={orgData?.school || ''}
          teams={teams}
          className={localStyles.selectedSchool}
        />
      )}
    </>
  )
}

export const OrderSummaryItem: React.FC<OrderItem> = ({ name, period, amount }) => (
  <Grid container justify="space-between">
    <Grid item>
      <Grid container item direction="column">
        <BodyLargeBold>{name}</BodyLargeBold>
        <p className={localStyles.dates}>{period}</p>
      </Grid>
    </Grid>
    <Grid item>
      <BodyLargeBold>{`$${amount?.toFixed(2)}`}</BodyLargeBold>
    </Grid>
  </Grid>
)

export default SpanningTable
