import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { Box, Hidden } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import React, { Fragment, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser } from 'src/api-service/api-service'
import { GET_COST, GET_EXTERNAL_ID } from 'src/App/apollo/local-state'
import MembershipSummary from 'src/App/components/membership-summary/membership-summary'
import PageContainer from 'src/App/components/page-container/page-container'
import PageWidth from 'src/App/components/page-width/page-width'
import RegisterSchoolInfo from 'src/App/components/register-school-info/register-school-info'
import RegisterTerms from 'src/App/components/register-terms/register-terms'
import MuiStepper from 'src/App/components/stepper/stepper'
import { MembershipCodeEnum, MembershipStateEnum } from 'src/graphql-types/globalTypes'
import { useMemberships } from 'src/hooks/use-memberships'
import {
  PAYMENT_CONFIRMATION_ROUTE,
  REGISTER_SCHOOL_DETAILS_ROUTE
} from 'src/utils/constants/routes'
import { RouteCheck } from 'src/utils/helper/helper'
import {
  getCurrentStep,
  getCurrentUser,
  setCurrentStep,
  setTermId
} from 'src/utils/localStorage/local-storage'
import RegisterPaymentConfirmation from '../../components/register-payment-confirmation/register-payment-confirmation'
import RegisterPayment from '../../components/register-payment/register-payment'
import { useToken } from 'src/hooks/use-token'

interface RegisterProps extends RouteComponentProps {
  paymentMethod?: number
}

export interface InitialStep {
  key: string
  value: string
  visible: boolean
  Component: ReactNode
}

interface ApplicableStepsProps {
  steps: InitialStep[]
  currentStep: string | null
}

export function ApplicableSteps({ steps, currentStep }: ApplicableStepsProps) {
  return (
    <>
      {steps.map(({ value, Component }, i) => {
        if (String(i) === currentStep) return <Fragment key={value}>{Component}</Fragment>
        return null
      })}
    </>
  )
}

export function useMembershipTerms(code: MembershipCodeEnum | undefined, skip?: boolean) {
  const { data: membershipsData, ...otherQueryProps } = useMemberships({ options: { skip } })
  const membership = membershipsData?.memberships?.data?.find(m => m?.code === code)
  const terms = membership?.terms

  const activeTerms = terms?.filter(m => m?.state === MembershipStateEnum.ACTIVE)
  if (activeTerms?.length === 1) {
    setTermId(activeTerms?.[0]?.id)
  }
  return {
    terms,
    activeTerms,
    hasMultipleActiveTerms: (activeTerms?.length || 0) > 1,
    ...otherQueryProps
  }
}

const Register: React.FC<RegisterProps> = ({ location }) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [loadingPage, setLoadingPage] = React.useState<boolean>(false)
  const { token} = useToken()
  const currentStep = getCurrentStep()
  const user = getCurrentUser()

  const { data: userId } = useQuery(GET_EXTERNAL_ID, { client })
  const { data: costData } = useQuery(GET_COST, { client })

  const { hasMultipleActiveTerms, loading: loadingTerms } = useMembershipTerms(MembershipCodeEnum.CPM, !token)
  useEffect(() => {
    setLoadingPage(true)
    if (!user) getUser(client, MembershipCodeEnum.CPM)
    if (!currentStep || currentStep == null) setCurrentStep('0')
    setLoadingPage(false)
  }, [userId, currentStep, location, client, user])

  const initialSteps: InitialStep[] = [
    {
      key: '1',
      value: t('terms'),
      visible: hasMultipleActiveTerms,
      Component: (
        <RegisterTerms
          code={MembershipCodeEnum.CPM}
          navigateRoute={REGISTER_SCHOOL_DETAILS_ROUTE}
        />
      )
    },
    { key: '2', value: t('school details'), visible: true, Component: <RegisterSchoolInfo /> },
    { key: '3', value: t('payment'), visible: true, Component: <RegisterPayment /> },
    {
      key: '4',
      value: t('confirmation and add staff'),
      visible: true,
      Component: <RegisterPaymentConfirmation path={PAYMENT_CONFIRMATION_ROUTE} />
    }
  ]

  const applicableSteps = initialSteps.filter(s => s.visible)
  const stepNames = applicableSteps.map(s => s.value)

  return (
    <>
      <Hidden smUp>
        <RouteCheck>
          <MembershipSummary cost={costData?.cost} />
        </RouteCheck>
      </Hidden>
      <Box clone p={{ xs: '0 20px 0 20px', sm: '0 20px 0 20px', md: '0 135px 0 135px' }}>
        <PageContainer>
          <PageWidth>
            <MuiStepper
              steps={stepNames}
              children={<ApplicableSteps steps={applicableSteps} currentStep={currentStep} />}
              activeStep={Number(currentStep)}
              loadingPage={loadingPage || loadingTerms}
            />
          </PageWidth>
        </PageContainer>
      </Box>
    </>
  )
}

export default Register
