export type OrderItem = {
  name: string
  period: string
  amount: number | undefined | null
}

export type CreatePaymentRequest = {
  PaymentMethodId: string
  Cost: number
  Description: string
  ScopeID: string
  Metadata: any
  VenueID: string
}

export type CreatePaymentResponse = {
  ID: string
  ExternalID: string
  Status: string
  Error: string
  RequiresAction: boolean
}

export type ConfirmPaymentRequest = {
  PaymentIntentId: string
}

export type ConfirmPaymentResponse = {
  ID: string
  ExternalID: string
  Status: string
  Error: string
}

export type CapturePaymentRequest = {
  PaymentIntentId: string
  Amount: number
}

export type CapturePaymentResponse = {
  ID: string
  ExternalID: string
  Status: string
  Error: string
  AmountReceived: number
}

export type Discount = {
  name: string
  percent: number
}

export type SchoolInfo = {
  school: string
  mailingAddress: string
  city: string
  state: string
  zipCode: string
  primaryRole: string
  teams: Teams
}

export type CorporateInfo = {
  companyName: string
  mailingAddress: string
  city: string
  state: string
  zipCode: string
}

export type ConferenceInfo = {
  conferenceName: string
  division: string
  mailingAddress: string
  city: string
  state: string
  zipCode: string
}

export type USTASectionInfo = {
  ustaSectionName: string
  mailingAddress: string
  city: string
  state: string
  zipCode: string
}

export type AssociateInfo = {
  orgName: string
  mailingAddress: string
  city: string
  state: string
  zipCode: string
}

export type StripeMetadata = {
  firstName: string
  lastName: string
  description: string
  category: string
  subCategory: string
  numberOfTeams?: string
  organization?: string
  team?: string
  itaId?: string
  email?: string
  startDate: string
  expiryDate: string
}

export interface User {
  ID: string
  FirstName?: string
  LastName?: string
  IsFemale?: boolean
  Postcode?: string
  Index?: number
  Role: string
  BirthDate?: Date
  EmailAddress?: string
  Status?: number
  Providers: Provider[] //CS VenueContacts
  Token?: string
  VenueContacts?: any
  VenueRoles?: VenueRole[]
}

export type VenueRoleName =
  | 'Head Coach'
  | 'Assistant Coach'
  | 'Sports Information Director'
  | 'Director of Operations'
  | 'Business Admin'

export interface VenueRole {
  ID: string
  Name: VenueRoleName
  Type: { Value: number; Name: string }
  Value: number
  VenueRoleID: string
}

export interface Provider {
  id: string //VenueID *
  contactId: string //VenueContactID
  pin: string
  roles: number //VenueSystemRoles *
}

export interface Dates {
  startDate: string
  endDate: string
}

export enum Teams {
  MEN = 'mens',
  WOMEN = 'womens',
  MEN_AND_WOMEN = 'mens&womens',
  TEAMS = 'teams',
  MEN_AND_WOMEN_ALTERNATIVE = 'Men_And_Women'
}

export enum TeamRolesEnum {
  HEAD_COACH = 'HEAD_COACH',
  ASSISTANT_COACH = 'ASSISTANT_COACH',
  SPORTS_INFORMATION_DIRECTOR = 'SPORTS_INFORMATION_DIRECTOR',
  DIRECTOR_OF_OPERATIONS = 'DIRECTOR_OF_OPERATIONS',
  BUSINESS_ADMIN = 'BUSINESS_ADMIN'
}

export enum PaymentStatus {
  Paid = 'PAID',
  NotPaid = 'NOT_PAID'
}
