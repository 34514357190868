import {
  AssociateInfo,
  ConferenceInfo,
  CorporateInfo,
  SchoolInfo,
  Teams,
  User,
  USTASectionInfo
} from 'src/types/types'

const CURRENT_STEP = 'current_step'
const TEAMS = 'teams'
const TEAM_IDS = 'team_ids'
const TERM = 'term'
const PAYMENT_METHOD = 'payment_method'
const PAYMENT_STATUS = 'payment_status'
const PROGRAM = 'program'
const ORGS = 'organisations'
const PLAYER_DETAILS = 'player_details'
const CORPORATE_INFO = 'corporate_info'
const CONFERENCE_INFO = 'conference_info'
const USTA_SECTION_INFO = 'usta_section_info'
const ASSOCIATE_INFO = 'associate_info'
const EMERITUS_INFO = 'emeritus_info'
const ORG_DATA = 'org_data'
const USER = 'user'
const CLUBSPARK_TOKEN = 'clubspark_token'

export const setCurrentStep = (step: string) => {
  localStorage.setItem(CURRENT_STEP, step)
}

export const getCurrentStep = () => {
  return localStorage.getItem(CURRENT_STEP)
}

export const setCurrentUser = (user: string) => {
  localStorage.setItem(USER, user)
}

export const getCurrentUser = (): User => {
  return JSON.parse(localStorage.getItem(USER)!)
}

export const setTeams = (teams: string) => {
  localStorage.setItem(TEAMS, teams)
}

export const getTeams = (): Teams | undefined => {
  return localStorage.getItem(TEAMS) as Teams
}

export const setTermId = (term?: string) => {
  localStorage.setItem(TERM, term || '')
}

export const getTermId = () => {
  return localStorage.getItem(TERM)
}

export const setProgram = (program: string) => {
  localStorage.setItem(PROGRAM, program)
}

export const getProgram = () => {
  return localStorage.getItem(PROGRAM)
}

export const setOrgs = (orgs: any) => {
  localStorage.setItem(ORGS, JSON.stringify(orgs))
}

export const getOrgs = () => {
  return localStorage.getItem(ORGS)
}

export const setOrgData = (data: Partial<SchoolInfo>) => {
  localStorage.setItem(ORG_DATA, JSON.stringify(data))
}

export const getOrgData = (): SchoolInfo | undefined => {
  return JSON.parse(localStorage.getItem(ORG_DATA)!)
}

export const clearOrgsData = () => {
  localStorage.removeItem(ORGS)
}

export const setPaymentMethod = (method: string) => {
  localStorage.setItem(PAYMENT_METHOD, method)
}

export const getPaymentMethod = () => {
  return localStorage.getItem(PAYMENT_METHOD)
}

export const setPaymentStatus = (status: string) => {
  localStorage.setItem(PAYMENT_STATUS, status)
}

export const getPaymentStatus = () => {
  return localStorage.getItem(PAYMENT_STATUS)
}

export const setPlayerDetails = playerDetails => {
  return localStorage.setItem(PLAYER_DETAILS, JSON.stringify(playerDetails))
}

export const getPlayerDetails = () => {
  return localStorage.getItem(PLAYER_DETAILS)
}

export const setCorporateInfo = (info: CorporateInfo) => {
  return localStorage.setItem(CORPORATE_INFO, JSON.stringify(info))
}

export const getCorporateInfo = (): CorporateInfo => {
  return JSON.parse(localStorage.getItem(CORPORATE_INFO)!)
}

export const setConferenceInfo = (info: ConferenceInfo) => {
  return localStorage.setItem(CONFERENCE_INFO, JSON.stringify(info))
}

export const getConferenceInfo = (): ConferenceInfo => {
  return JSON.parse(localStorage.getItem(CONFERENCE_INFO)!)
}

export const setUSTAInfo = (info: USTASectionInfo) => {
  return localStorage.setItem(USTA_SECTION_INFO, JSON.stringify(info))
}

export const getUSTASectionInfo = (): USTASectionInfo => {
  return JSON.parse(localStorage.getItem(USTA_SECTION_INFO)!)
}

export const setAssociateInfo = (info: AssociateInfo) => {
  return localStorage.setItem(ASSOCIATE_INFO, JSON.stringify(info))
}

export const getAssociateInfo = (): AssociateInfo => {
  return JSON.parse(localStorage.getItem(ASSOCIATE_INFO)!)
}

export const setEmeritusInfo = (info: AssociateInfo) => {
  return localStorage.setItem(EMERITUS_INFO, JSON.stringify(info))
}

export const getEmeritusInfo = () => {
  return JSON.parse(localStorage.getItem(EMERITUS_INFO)!)
}

export const storeTeamIds = (teamIds: string[]) => {
  return localStorage.setItem(TEAM_IDS, JSON.stringify(teamIds))
}

export const retrieveTeamIds = () => {
  return JSON.parse(localStorage.getItem(TEAM_IDS)!)
}

export const storeToken = (token: string) => {
  return localStorage.setItem(CLUBSPARK_TOKEN, token)
}

export const retrieveToken = () => {
  const token = localStorage.getItem(CLUBSPARK_TOKEN)
  return token ? token : null
}

export const clearLocalStorage = () => {
  const token = retrieveToken()
  const user = getCurrentUser()
  const currentStep = getCurrentStep()
  window?.localStorage?.clear()
  token && storeToken(token)
  user && setCurrentUser(JSON.stringify(user))
  currentStep && setCurrentStep(currentStep)
}
