import { Grid } from '@material-ui/core'
import { navigate } from '@reach/router'
import React from 'react'
import ITALogo from 'src/assets/ITALogoLight@3x.png'
import { HOME_ROUTE } from '../../../utils/constants/routes'
import styles from './header.module.scss'

const Header: React.FC = () => {
  return (
    <Grid container justify="flex-start" alignItems="center" className={styles.container}>
      <div className={styles.alignmentContainer}>
        <div className={styles.logoContainer}>
          <img
            src={ITALogo}
            alt="logo"
            className={styles.logo}
            onClick={() => navigate(HOME_ROUTE)}
          />
        </div>
      </div>
    </Grid>
  )
}

export default Header
