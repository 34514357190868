import { BodyLarge, H1, H3 } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { RouteComponentProps, useLocation } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PaymentOptionsList from '../payment-options-list/payment-options-list'
import SpanningTable from '../spanning-table/spanning-table'
import styles from './register-payment.module.scss'

interface PaymentProps extends RouteComponentProps<any> {
  membership?: string
}

const RegisterPayment: React.FC<PaymentProps> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const isChangePayment = search?.includes('change-payment')

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12}>
          <H1 spacing={{ margins: { md: 'top' } }} classnames={styles.header}>
            {t('review and pay')}
          </H1>
          <BodyLarge spacing={{ margins: { sm: 'top' } }}>
            Check you have everything you need
          </BodyLarge>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.membershipSummary}>
            <div className={styles.summaryTitle}>
              <H3>{t('order summary')}</H3>
            </div>
            {/* When returning discounts make everything lowercase */}
            <SpanningTable isChangePayment={isChangePayment} />
          </div>
          <H3 spacing={{ margins: { md: 'top', xs: 'bottom' } }} classnames={styles.paymentLabel}>
            {t('payment option question')}
          </H3>
          <Grid item xs={12} md={8} lg={8}>
            <PaymentOptionsList isChangePayment={isChangePayment} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RegisterPayment
