import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-fetch-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en-US',
    fallbackLng: 'en-US',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        if (format === 'requiredFlag') {
          return value || ''
        }

        return value
      }
    },
    react: {
      useSuspense: false
    },
    resources: {
      'en-US': { translation: require('src/locales/en-US/translation.json') }
    }
  })

export default i18n
