import { useApolloClient, useQuery } from '@apollo/react-hooks'
import {
  BodyLarge,
  BodySmall,
  BodySmallBold,
  H2,
  Spinner
} from '@clubspark-react/clubspark-react-tools'
import {
  Grid,
  Hidden,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps
} from '@material-ui/core'
import { Field, Form, FieldProps, FormikProps } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SearchSVG } from 'src/assets/icn_search.svg'
import { GetSchools, GetSchools_schools as School } from 'src/graphql-types/GetSchools'
import {
  GetOrganisationPurchaseStatus,
  GetOrganisationPurchaseStatusVariables
} from 'src/graphql-types/GetOrganisationPurchaseStatus'
import {
  getOrgData,
  getOrgs,
  getTeams,
  getTermId,
  retrieveToken,
  setOrgData,
  setTeams
} from 'src/utils/localStorage/local-storage'
import ThemeProvider from 'src/App/theme/theme-provider'
import AddressForm from '../address-form/address-form'
import AdvancedDropdown from '../advanced-dropdown/advanced-dropdown'
import FormErrorMessage from '../form-error-message/form-error-message'
import MembershipSummary from '../membership-summary/membership-summary'
import { GET_ORGANISATION } from '../register-school-info/register-school-info-queries'
import Select from '../select/select'
import { RegisterDetailsActions } from '../stepper-actions/stepper-actions'
import { GET_SCHOOLS } from './register-school-form-queries'
import styles from './register-school-form.module.scss'
import { Teams } from 'src/types/types'
import { GET_ORGANISATION_PURCHASE_STATUS } from 'src/App/auth/register/register-queries'
import { MembershipCodeEnum, TeamTypeEnum } from 'src/graphql-types/globalTypes'
import { useMemberships } from 'src/hooks/use-memberships'
import { useSchoolPrice } from 'src/hooks/use-school-price'

type SelectedTeams = Record<Teams.MEN | Teams.WOMEN, boolean>
type TeamsCheckboxProps = FieldProps<MuiCheckboxProps> & {
  availableOptions?: Teams | null
  purchasedMembership?: Teams
}

const TeamsSelect: React.FC<TeamsCheckboxProps> = ({
  field,
  form,
  availableOptions,
  purchasedMembership,
  ...props
}) => {
  const { t } = useTranslation()

  const { setFieldValue } = form

  const [selectedTeam, setSelectedTeam] = React.useState<SelectedTeams>({
    [Teams.MEN]: [Teams.MEN, Teams.MEN_AND_WOMEN].includes(field?.value as Teams),
    [Teams.WOMEN]: [Teams.WOMEN, Teams.MEN_AND_WOMEN].includes(field?.value as Teams)
  })

  const mensVisible = Boolean(
    availableOptions && [Teams.MEN, Teams.MEN_AND_WOMEN].includes(availableOptions)
  )

  const womensVisible = Boolean(
    availableOptions && [Teams.WOMEN, Teams.MEN_AND_WOMEN].includes(availableOptions)
  )

  const mensAlreadyPurchased = Boolean(
    purchasedMembership && [Teams.MEN, Teams.MEN_AND_WOMEN].includes(purchasedMembership)
  )

  const womensAlreadyPurchased = Boolean(
    purchasedMembership && [Teams.WOMEN, Teams.MEN_AND_WOMEN].includes(purchasedMembership)
  )

  const handleChange = event => {
    const { value, checked } = event.target as HTMLInputElement
    const newSelectedTeam = { ...selectedTeam, [value]: checked }

    let teams = ''

    if (newSelectedTeam[Teams.MEN] && newSelectedTeam[Teams.WOMEN]) {
      teams = Teams.MEN_AND_WOMEN
    } else if (newSelectedTeam[Teams.MEN]) {
      teams = Teams.MEN
    } else if (newSelectedTeam[Teams.WOMEN]) {
      teams = Teams.WOMEN
    }

    setFieldValue(field.name, teams)

    setTeams(teams)

    setSelectedTeam(newSelectedTeam)
  }

  useEffect(() => {
    setSelectedTeam({
      [Teams.MEN]: [Teams.MEN, Teams.MEN_AND_WOMEN].includes(field?.value as Teams),
      [Teams.WOMEN]: [Teams.WOMEN, Teams.MEN_AND_WOMEN].includes(field?.value as Teams)
    })
    setTeams((field.value || '').toString())
  }, [field])

  return (
    <ThemeProvider>
      {mensVisible ? (
        <>
          <FormControlLabel
            {...props}
            {...field}
            checked={selectedTeam[Teams.MEN] || mensAlreadyPurchased}
            disabled={mensAlreadyPurchased}
            label={<BodySmall>{t('mens team')}</BodySmall>}
            control={<MuiCheckbox disableRipple disableFocusRipple color="primary" />}
            value={Teams.MEN}
            onChange={handleChange}
          />
          {mensAlreadyPurchased && (
            <FormErrorMessage message={t('membership purchased generic', { team: 'mens team' })} />
          )}
        </>
      ) : null}

      {womensVisible ? (
        <>
          <FormControlLabel
            {...props}
            {...field}
            checked={selectedTeam[Teams.WOMEN] || womensAlreadyPurchased}
            disabled={womensAlreadyPurchased}
            label={<BodySmall>{t('womens team')}</BodySmall>}
            control={<MuiCheckbox disableRipple disableFocusRipple color="primary" />}
            value={Teams.WOMEN}
            onChange={handleChange}
          />
          {womensAlreadyPurchased && (
            <FormErrorMessage
              message={t('membership purchased generic', { team: 'womens team' })}
            />
          )}
        </>
      ) : null}
    </ThemeProvider>
  )
}

export interface RegisterSchoolFormValues {}

export interface RegisterSchoolFormProps extends FormikProps<any> {}

export const getAvailableTeamsForSchool = (school?: School) => {
  switch (school?.teamType) {
    case TeamTypeEnum.Men_And_Women:
      return Boolean(school?.manId && school?.womanId) ? Teams.MEN_AND_WOMEN : undefined
    case TeamTypeEnum.Men_s:
      return Boolean(school?.manId) ? Teams.MEN : undefined
    case TeamTypeEnum.Women_s:
      return Boolean(school?.womanId) ? Teams.WOMEN : undefined
    default:
      return
  }
}

const RegisterSchoolForm: React.FC<RegisterSchoolFormProps> = ({
  values,
  errors,
  setFieldValue,
  touched,
  isSubmitting
}) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const teams = getTeams() as Teams
  const orgData = getOrgData()
  const school = orgData?.school || ''

  const { data: schoolsInfo, loading: schoolsLoading } = useQuery<GetSchools>(GET_SCHOOLS, {
    skip: !retrieveToken()
  })

  const handleSetFieldValue = useCallback((name, field) => setFieldValue(name, field), [
    setFieldValue
  ])

  const handleSchoolObject = useCallback(
    name => schoolsInfo?.schools?.find(school => school?.name === name),
    [schoolsInfo]
  )

  const schoolObject = handleSchoolObject(school)

  const { amount } = useSchoolPrice({
    skip: !getTermId() || !teams,
    variables: {
      getPriceForSchoolInput: {
        membershipCode: MembershipCodeEnum.CPM,
        termId: getTermId(),
        teamIds: {
          ...(teams === Teams.WOMEN || teams === Teams.MEN_AND_WOMEN
            ? { femaleTeamId: schoolObject?.womanId }
            : {}),
          ...(teams === Teams.MEN || teams === Teams.MEN_AND_WOMEN
            ? { maleTeamId: schoolObject?.manId }
            : {})
        }
      }
    }
  })

  const [availableOptions, setAvailableOptions] = useState<Teams | undefined | null>(null)

  const orgId = getOrgs()
  let parsedOrgs

  if (orgId) {
    ;[parsedOrgs] = JSON.parse(orgId)
  }

  const { data: orgInfo, loading: orgLoading } = useQuery(GET_ORGANISATION, {
    variables: { id: parsedOrgs?.orgId },
    skip: !parsedOrgs?.orgId || !retrieveToken()
  })

  const { membership } = useMemberships({
    code: MembershipCodeEnum.CPM
  })

  const { data: orgPurchaseStatusData } = useQuery<
    GetOrganisationPurchaseStatus,
    GetOrganisationPurchaseStatusVariables
  >(GET_ORGANISATION_PURCHASE_STATUS, {
    variables: {
      input: {
        womanId: schoolObject?.womanId || undefined,
        manId: schoolObject?.manId || undefined
      },
      termId: getTermId() as string,
      membershipId: membership?.id as string
    },
    skip: !schoolObject || !membership,
    fetchPolicy: 'network-only'
  })

  const manMembership =
    schoolObject &&
    schoolObject.manId &&
    orgPurchaseStatusData?.getOrganisationPurchaseStatus[schoolObject.manId]

  const womanMembership =
    schoolObject &&
    schoolObject.womanId &&
    orgPurchaseStatusData?.getOrganisationPurchaseStatus[schoolObject.womanId]

  const [purchasedMembership, setPurchasedMembership] = useState<Teams | undefined>(undefined)
  const [hasAvailableTeams, setHasAvailableTeams] = useState(true)

  useEffect(() => {
    if (manMembership && womanMembership) {
      setPurchasedMembership(Teams.MEN_AND_WOMEN)
      setHasAvailableTeams(false)
    } else if (manMembership) {
      setPurchasedMembership(Teams.MEN)
      setHasAvailableTeams(availableOptions !== Teams.MEN)
    } else if (womanMembership) {
      setPurchasedMembership(Teams.WOMEN)
      setHasAvailableTeams(availableOptions !== Teams.WOMEN)
    } else {
      setPurchasedMembership(undefined)
      setHasAvailableTeams(true)
    }
  }, [manMembership, womanMembership, availableOptions])

  useEffect(() => {
    school && handleSetFieldValue('school', school)
    const schoolObject = handleSchoolObject(school)

    if (!schoolObject) return

    const availableTeams = getAvailableTeamsForSchool(schoolObject)
    setAvailableOptions(availableTeams)
  }, [school, handleSetFieldValue, handleSchoolObject, schoolsInfo])

  const parsedOrgsId = parsedOrgs?.orgId

  // Pre-populate fields if there is an existing organisation
  useEffect(() => {
    if (orgInfo && school) {
      handleSetFieldValue('school', school)
      handleSetFieldValue('city', orgInfo?.organisation?.mailingAddress?.city)
      handleSetFieldValue('state', orgInfo?.organisation?.mailingAddress?.state)
      handleSetFieldValue('mailingAddress', orgInfo?.organisation?.mailingAddress?.street)
      handleSetFieldValue('zipCode', orgInfo?.organisation?.mailingAddress?.zipCode)
    }
  }, [parsedOrgsId, orgLoading, orgInfo, handleSetFieldValue, school])

  // Clear fields if no school is selected
  useEffect(() => {
    if (values.school === '') {
      handleSetFieldValue('city', '')
      handleSetFieldValue('state', '')
      handleSetFieldValue('mailingAddress', '')
      handleSetFieldValue('zipCode', '')
      handleSetFieldValue('teams', '')
    }
  }, [values.school, handleSetFieldValue])

  const isSchoolSelected = !!values?.school
  client.writeData({ data: { selectedTeam: values?.teams } })

  if (orgLoading || schoolsLoading) {
    return (
      <Grid container justify="center">
        <Spinner />
      </Grid>
    )
  }

  return (
    <Form>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <H2 spacing={{ margins: { xxs: 'bottom' } }}>{t('school details')}</H2>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <BodyLarge>{t('choose a school')}</BodyLarge>
              <BodyLarge spacing={{ margins: { xxs: 'top' } }}>
                {t('choose a school mandatory fields')}
              </BodyLarge>
            </Grid>
            <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('select school')}
            </BodySmallBold>
            <Grid container>
              <Grid container>
                <Field
                  aria-label="school"
                  name="school"
                  component={AdvancedDropdown}
                  options={schoolsInfo?.schools}
                  validateOnChange={true}
                  value={handleSchoolObject(school)}
                  onSelect={(option?: School | null) => {
                    setFieldValue('teams', '')

                    if (option) {
                      const availableTeams = getAvailableTeamsForSchool(option)

                      setTeams(availableTeams || '')
                      setOrgData({ school: option.name })
                      setAvailableOptions(availableTeams)
                    } else {
                      setTeams('')
                      setOrgData({ school: '' })
                      setAvailableOptions(null)
                      client.writeData({ data: { cost: '' } })
                    }
                  }}
                  placeholder={t('enter school name')}
                  setFieldValue={setFieldValue}
                />
                <SearchSVG className={styles.searchIcon} />
              </Grid>
              {errors?.school && touched?.school && (
                <FormErrorMessage>{errors.school}</FormErrorMessage>
              )}
              {purchasedMembership === Teams.MEN_AND_WOMEN && (
                <FormErrorMessage message={t('both teams purchased')} />
              )}
              {purchasedMembership === Teams.MEN && (
                <FormErrorMessage message={t('mens team purchased')} />
              )}
              {purchasedMembership === Teams.WOMEN && (
                <FormErrorMessage message={t('womens team purchased')} />
              )}
            </Grid>
            {values?.school && (
              <>
                <AddressForm
                  errors={errors}
                  showRequired={false}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
                <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('primary role')}
                </BodySmallBold>
                <Grid container>
                  <Field
                    name="primaryRole"
                    options={[
                      { value: 'head coach', label: 'Head Coach' },
                      { value: 'assistant coach', label: 'Assistant Coach' },
                      {
                        value: 'sports information director',
                        label: 'Sports Information Director'
                      },
                      { value: 'business admin', label: 'Business Admin' },
                      { value: 'Director of Operations', label: 'Director of Operations' }
                    ]}
                    defaultValue={values.primaryRole}
                    component={Select}
                    onSelect={option => setFieldValue('primaryRole', option.value)}
                    style={{ borderRadius: 0 }}
                  />
                  {errors?.primaryRole && touched?.primaryRole && (
                    <FormErrorMessage>{errors.primaryRole}</FormErrorMessage>
                  )}
                </Grid>
                <BodySmallBold spacing={{ margins: { md: 'top' } }}>
                  {t('choose one team')}
                </BodySmallBold>
                <Grid container direction="column">
                  <Field
                    component={TeamsSelect}
                    availableOptions={availableOptions}
                    purchasedMembership={purchasedMembership}
                    defaultValue={purchasedMembership}
                    name="teams"
                  />
                  {errors?.teams && touched?.teams && (
                    <FormErrorMessage>{errors.teams}</FormErrorMessage>
                  )}
                </Grid>
              </>
            )}
            {isSubmitting ? (
              <Grid container justify="flex-start" className={styles.spinnerContainer}>
                <Spinner />
              </Grid>
            ) : (
              <Grid container item xs={12} md={12} lg={12} className={styles.actionButtonContainer}>
                <RegisterDetailsActions
                  isSchoolSelected={isSchoolSelected}
                  disabled={!hasAvailableTeams}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Hidden xsDown>
          <MembershipSummary cost={values.school ? amount : 0} />
        </Hidden>
      </Grid>
    </Form>
  )
}

export default RegisterSchoolForm
