import React from 'react'
import styles from './page-width.module.scss'

interface PageWidthProps {
  children: React.ReactNode
}
const PageWidth: React.FC<PageWidthProps> = ({ children }) => {
  return <div className={styles.widthContainer}>{children}</div>
}

export default PageWidth
