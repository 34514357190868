import { BodyRegular, BodySmallBold, Button, H3 } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Tick } from '../../images/icons/24px/icn_tick.svg'
import ITABackground from '../../images/ITA-pic3.jpg'
import styles from './main-membership.module.scss'

// const INCLUDED_LIST = [
//   // 'Includes coaches and coaching assistants membership',
//   'Access to the National and Regional Oracle ITA Collegiate Tennis Rankings',
//   'Direct access to online score reporting',
//   'Participate in ITA tournaments: regional and national championships',
//   'Host an ITA event: regional or national championships, summer circuit, sanctioned tournaments',
//   'Eligibility for the ITA Awards Program',
//   'Attend the ITA Coaches Convention',
//   'Receive an ITA Scorebook and Rulebook',
//   'Access to ITA governance features (rules interpretations, ability to protest) regarding regular season, non-conference play',
//   'Participate on ITA National and Regional Committees',
//   'Post your job opportunities on the ITA Classifieds page',
//   'Post your available scheduling dates on the ITA Open Dates page',
//   'Receive discounts from ITA Partners and Corporate Members'
// ]

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  loading: boolean
}

type BenefitsProps = { list: string[]; expanded?: boolean }

export const Benefits: React.FC<BenefitsProps> = ({ list, expanded = false }: BenefitsProps) => {
  return (
    <div className={styles.listContainer} data-testid="benefits container">
      {list.map((item, index) => {
        if (!expanded && index > 3) {
          return null
        }
        return (
          <div key={index} className={styles.included}>
            <div className={styles.tickContainer}>
              <Tick />
            </div>
            <div className={styles.textItemContainer}>
              <span className={styles.item}>{item}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const MainMembership: React.FC<Props> = ({ onClick, loading }) => {
  const { t } = useTranslation()
  const [benefitsExpanded, setBenefitsExpanded] = useState(false)

  const toggleExpandedBenefits = () => setBenefitsExpanded(!benefitsExpanded)

  const INCLUDED_LIST = [
    t('ranking access'),
    t('access online score'),
    t('participate in tournaments'),
    t('coach edu access'),
    t('eligibility for awards'),
    t('receive scorebook'),
    t('governance features'),
    t('event hosting'),
    t('attend convetion'),
    t('participate in committies'),
    t('post job opportunities'),
    t('post schedules'),
    t('receive discounts'),
    t('eligibility for TfA')
  ]

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} sm={12} md={12} className={styles.textContainer}>
        <H3>{t('ITA Team Membership')}</H3>
        <BodyRegular spacing={{ margins: { md: 'bottom' } }}>
          {/* {t('For coaches and colleges')} */}
          {t('home subheading')}
        </BodyRegular>
        <BodySmallBold spacing={{ margins: { lg: 'bottom' } }}>
          {t('main membership subheading')}
        </BodySmallBold>
        <Benefits list={INCLUDED_LIST} expanded={benefitsExpanded} />
        <Button
          role="benefits button"
          level="link"
          linkStyle
          classname={styles.linkButton}
          onClick={toggleExpandedBenefits}
        >
          {benefitsExpanded ? 'Hide full benefits and terms' : 'Read full benefits and terms'}
        </Button>
        <div className={styles.buttonContainer}>
          <Button
            size="lg"
            loading={loading}
            fluid
            spacing={{ margins: { sm: 'top' } }}
            onClick={onClick}
          >
            {t('join now')}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={styles.imgGrid}>
        <Grid container className={styles.imgContainer}>
          <img src={ITABackground} alt="ITA background" className={styles.backgroundImg} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MainMembership
