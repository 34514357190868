import { getTermId } from 'src/utils/localStorage/local-storage'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useMembershipTerms } from 'src/App/auth/register/register'

const usePackageDates = (code: MembershipCodeEnum | undefined) => {
  const { terms } = useMembershipTerms(code)
  const termId = getTermId()
  const selectedTerm = terms?.find(t => t?.id === termId)
  return { start: selectedTerm?.start, end: selectedTerm?.end }
}

export default usePackageDates
