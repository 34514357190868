import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { navigate, useLocation } from '@reach/router'
import { useEffect, useState } from 'react'
import {
  ASSOCIATE_DETAILS_ROUTE,
  ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE,
  ASSOCIATE_ROUTE,
  CONFERENCE_DETAILS_ROUTE,
  CONFERENCE_PAYMENT_CONFIRMATION_ROUTE,
  CONFERENCE_ROUTE,
  CORPORATE_DETAILS_ROUTE,
  CORPORATE_PAYMENT_CONFIRMATION_ROUTE,
  CORPORATE_ROUTE,
  EMERITUS_DETAILS_ROUTE,
  EMERITUS_PAYMENT_CONFIRMATION_ROUTE,
  EMERITUS_ROUTE,
  PAYMENT_CONFIRMATION_ROUTE,
  REGISTER_ROUTE,
  REGISTER_SCHOOL_DETAILS_ROUTE,
  SC_DETAILS_ROUTE,
  SC_PAYMENT_CONFIRMATION_ROUTE,
  SUMMER_CIRCUIT_ROUTE,
  USTA_SECTION_DETAILS_ROUTE,
  USTA_SECTION_PAYMENT_CONFIRMATION_ROUTE,
  USTA_SECTION_ROUTE
} from 'src/utils/constants/routes'
import {
  getCurrentStep,
  getTermId,
  retrieveToken,
  setCurrentStep
} from 'src/utils/localStorage/local-storage'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { GetOwnMembers } from 'src/graphql-types/GetOwnMembers'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { useOwnMembers } from './use-own-members'
import { GetSchools } from 'src/graphql-types/GetSchools'
import { GET_SCHOOLS } from 'src/App/components/register-school-form/register-school-form-queries'

export const MEMBERSHIPS_INFO = [
  {
    name: 'Team',
    baseRoute: REGISTER_SCHOOL_DETAILS_ROUTE,
    confirmationRoute: PAYMENT_CONFIRMATION_ROUTE,
    code: MembershipCodeEnum.CPM
  },
  {
    name: 'Associate/Professional',
    baseRoute: ASSOCIATE_DETAILS_ROUTE,
    confirmationRoute: ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE,
    code: MembershipCodeEnum.ASCPRO
  },
  {
    name: 'Corporate',
    baseRoute: CORPORATE_DETAILS_ROUTE,
    confirmationRoute: CORPORATE_PAYMENT_CONFIRMATION_ROUTE,
    code: MembershipCodeEnum.CORP
  },
  {
    name: 'Conference',
    baseRoute: CONFERENCE_DETAILS_ROUTE,
    confirmationRoute: CONFERENCE_PAYMENT_CONFIRMATION_ROUTE,
    code: MembershipCodeEnum.CONF
  },
  {
    name: 'USTA Section',
    baseRoute: USTA_SECTION_DETAILS_ROUTE,
    confirmationRoute: USTA_SECTION_PAYMENT_CONFIRMATION_ROUTE,
    code: MembershipCodeEnum.USTASEC
  },
  {
    name: 'Player',
    baseRoute: SC_DETAILS_ROUTE,
    confirmationRoute: SC_PAYMENT_CONFIRMATION_ROUTE,
    code: MembershipCodeEnum.PLA
  },
  {
    name: 'Emeritus',
    baseRoute: EMERITUS_DETAILS_ROUTE,
    confirmationRoute: EMERITUS_PAYMENT_CONFIRMATION_ROUTE,
    code: MembershipCodeEnum.COEM
  }
] as const

export function useRouteMembership(): MembershipCodeEnum | undefined {
  const location = useLocation()
  const pathname = location?.pathname

  if (pathname?.includes(REGISTER_ROUTE)) return MembershipCodeEnum.CPM
  if (pathname?.includes(SUMMER_CIRCUIT_ROUTE)) return MembershipCodeEnum.PLA
  if (pathname?.includes(CORPORATE_ROUTE)) return MembershipCodeEnum.CORP
  if (pathname?.includes(CONFERENCE_ROUTE)) return MembershipCodeEnum.CONF
  if (pathname?.includes(USTA_SECTION_ROUTE)) return MembershipCodeEnum.USTASEC
  if (pathname?.includes(ASSOCIATE_ROUTE)) return MembershipCodeEnum.ASCPRO
  if (pathname?.includes(EMERITUS_ROUTE)) return MembershipCodeEnum.COEM

  return undefined
}

// This hooks checks if the user already purchased the specified membership
// If true - the user gets redirected to the confirmation route of that membership
export const useExistingMembership = (code: MembershipCodeEnum, _skip?: boolean) => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const { activeTerms, hasMultipleActiveTerms } = useMembershipTerms(code)
  const membershipInfo = MEMBERSHIPS_INFO.find(m => m.code === code)!
  const termId = getTermId()
  const { data: schoolsInfo } = useQuery<GetSchools>(GET_SCHOOLS, {
    skip: !retrieveToken()
  })

  const { data, loading: loadingOwnMembers } = useOwnMembers({
    code
  })

  const { search } = useLocation()
  const isChangePayment = search?.includes('change-payment')

  useEffect(() => {
    const currentStep = getCurrentStep()
    const isLastStep = (hasMultipleActiveTerms ? '3' : '2') === currentStep

    const ownMember = data?.getOwnMembers?.find(member => member?.termId === termId)
    const notFirstStep = hasMultipleActiveTerms ? currentStep !== '0' : true

    if (currentStep && notFirstStep && ownMember && !isChangePayment && !isLastStep) {
      // If multiple active terms, there's an additional first step to select the term
      setCurrentStep(hasMultipleActiveTerms ? '3' : '2')
      navigate(membershipInfo.confirmationRoute)
      setLoading(false)
    } else if (data) {
      if (!getTermId()) setCurrentStep('0')
      setLoading(false)
    }

    if (hasMultipleActiveTerms && !getTermId()) {
      setLoading(false)
    }
  }, [
    data,
    code,
    client,
    loading,
    hasMultipleActiveTerms,
    loadingOwnMembers,
    membershipInfo,
    activeTerms,
    termId,
    schoolsInfo,
    isChangePayment
  ])

  return {
    loadingExistingMembership: loading
  }
}

export function getMember(
  code: MembershipCodeEnum,
  getOwnMembers?: GetOwnMembers['getOwnMembers']
) {
  const member = (getOwnMembers || []).find(m => {
    const hasPayments = m?.transactionIds && m?.transactionIds?.length > 0
    return m?.membershipId?.code === code && hasPayments
  })

  return member
}

export function getTeamIds(
  code: MembershipCodeEnum,
  getOwnMembers?: GetOwnMembers['getOwnMembers']
) {
  const member = getMember(code, getOwnMembers)
  const org = member?.organisationId as any
  return org?.teamIds
}
