import { useApolloClient, useQuery } from '@apollo/react-hooks'
import {
  BodyLarge,
  BodyRegular,
  BodySmallBold,
  H1,
  H3,
  H4
} from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { navigate, RouteComponentProps } from '@reach/router'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser } from 'src/api-service/api-service'
import { GET_EXTERNAL_ID } from 'src/App/apollo/local-state'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import usePackageDates from 'src/hooks/use-package-dates'
import { OrderItem } from 'src/types/types'
import { SC_PAYMENT_CONFIRMATION_ROUTE } from 'src/utils/constants/routes'
import { setCurrentStep } from 'src/utils/localStorage/local-storage'
import SpanningTable from '../spanning-table/spanning-table'
import styles from './summer-circuit-confirmation.module.scss'

interface SummerCircuitConfirmationProps extends RouteComponentProps {
  setStep?: React.Dispatch<React.SetStateAction<number>>
}

const SummerCircuitConfirmation: React.FC<SummerCircuitConfirmationProps> = () => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const { data } = useQuery(GET_EXTERNAL_ID, { client })
  const dates = usePackageDates(MembershipCodeEnum.PLA)

  React.useEffect(() => {
    getUser(client, MembershipCodeEnum.PLA)
  }, [client])

  const currentYear = moment().year()
  const nextYear = moment()
    .add(1, 'years')
    .year()

  const membershipYear = `${currentYear}-${nextYear % 100}`
  const startDate = dates ? moment.utc(dates.start).format('MMM DD, YYYY') : ''
  const endDate = dates ? moment.utc(dates.end).format('MMM DD, YYYY') : ''

  useEffect(() => {
    window.onpopstate = () => {
      setCurrentStep('2')
      navigate(SC_PAYMENT_CONFIRMATION_ROUTE)
    }
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12}>
          <H1 spacing={{ margins: { md: 'top' } }}>{t('sc thank you')}</H1>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <BodyLarge spacing={{ margins: { md: 'top' } }}>
            {t('sc message', {
              membershipYear,
              startDate,
              endDate
            })}
          </BodyLarge>
        </Grid>
        <Grid item xs={12} md={10} lg={9}>
          <BodyLarge spacing={{ margins: { md: 'top' } }}>{t('sc ita id note')}</BodyLarge>
        </Grid>
        <Grid container item xs={12} md={8} lg={8}>
          <BodyLarge>
            {t('sc ita id')}
            <span className={styles.itaId}>&nbsp;{data?.externalId}</span>
          </BodyLarge>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <BodyLarge spacing={{ margins: { md: 'vertical' } }}>{t('sc ita id message')}</BodyLarge>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.membershipSummary}>
            <div className={styles.summaryTitle}>
              <H3>{t('order confirmation')}</H3>
            </div>
            <SpanningTable isConfirmation />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <BodyLarge spacing={{ margins: { md: 'top' } }}>
            {t('sc tournaments')}
            <a href="https://www.wearecollegetennis.com/newsletters/" className={styles.link}>
              <span>wearecollegetennis.com/newsletters</span>
            </a>
          </BodyLarge>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <BodyLarge spacing={{ margins: { md: 'top' } }}>{t('sc final text')}</BodyLarge>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <H4 spacing={{ margins: { xs: 'top' } }}>{t('thank you')}</H4>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <H4>{t('intercollegiate tennis association')}</H4>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <H4 spacing={{ margins: { md: 'bottom' } }}>www.itatennis.com</H4>
        </Grid>
        {/* <Grid item xs={12} md={8} lg={8}>
          <RegisterPaymentConfirmationActions setStep={setStep!} />
        </Grid> */}
      </Grid>
    </Grid>
  )
}

export const OrderSummaryItem: React.FC<OrderItem> = ({ name, period, amount }) => {
  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Grid container item direction="column">
            <BodySmallBold>{name}</BodySmallBold>
            <BodyRegular>{period}</BodyRegular>
          </Grid>
        </Grid>
        <Grid item>
          <BodySmallBold>{amount}</BodySmallBold>
        </Grid>
      </Grid>
    </>
  )
}

export default SummerCircuitConfirmation
