import React from 'react'
import { BodyRegular } from '@clubspark-react/clubspark-react-tools'
import { Grid, GridProps, List, ListItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styles from './selected-school.module.scss'
import { Teams } from 'src/types/types'

export interface SelectedSchoolProps extends GridProps {
  teams?: Teams
  schoolName?: string
}

const SelectedSchool: React.FC<SelectedSchoolProps> = ({ teams, schoolName, ...props }) => {
  const { t } = useTranslation()

  const showMens = teams && [Teams.MEN, Teams.MEN_AND_WOMEN].includes(teams)
  const showWomens = teams && [Teams.WOMEN, Teams.MEN_AND_WOMEN].includes(teams)

  if (!schoolName && !showMens && !showWomens) {
    return null
  }

  return (
    <Grid {...props} container direction="column">
      {!!schoolName && <BodyRegular classnames={styles.schoolName}>{schoolName}</BodyRegular>}
      <List className={styles.teamsList}>
        {showMens && (
          <ListItem dense disableGutters>
            <BodyRegular>{t('mens team')}</BodyRegular>
          </ListItem>
        )}
        {showWomens && (
          <ListItem dense disableGutters>
            <BodyRegular>{t('womens team')}</BodyRegular>
          </ListItem>
        )}
      </List>
    </Grid>
  )
}

export default SelectedSchool
