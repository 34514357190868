import { GetSchools, GetSchools_schools as School } from 'src/graphql-types/GetSchools'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { CreatePaymentRequest, OrderItem, StripeMetadata, User, Teams } from 'src/types/types'
import { generateSCAditionalData } from 'src/utils/helper/helper'
import {
  getAssociateInfo,
  getConferenceInfo,
  getCorporateInfo,
  getEmeritusInfo,
  getOrgData,
  getPaymentMethod,
  getPlayerDetails,
  getTeams,
  getUSTASectionInfo
} from 'src/utils/localStorage/local-storage'

export const getMetadata = (
  membershipCode: MembershipCodeEnum | undefined,
  school: School | undefined | null
) => {
  const teams = getTeams()
  if (membershipCode && membershipCode !== MembershipCodeEnum.PLA) {
    if (teams && school) {
      return teams === Teams.MEN
        ? { Mens: school.manId }
        : teams === Teams.WOMEN
        ? { Female: school.womanId }
        : { Male: school.manId, Female: school.womanId }
    }
  } else return {}
}

export const getStripeMetadata = (
  user: User,
  itaId = '',
  isTeamMembership = false,
  school: School | undefined | null,
  description: string,
  dates: { startDate: string; endDate: string }
): StripeMetadata => {
  const teams = getTeams()
  const numOfTeams = teams && teams === Teams.MEN_AND_WOMEN ? '2' : '1'
  const team =
    teams && teams === Teams.MEN_AND_WOMEN
      ? 'Both'
      : teams === Teams.MEN
      ? "Men's"
      : teams === Teams.WOMEN
      ? "Women's"
      : ''
  return {
    firstName: user.FirstName ?? '',
    lastName: user.LastName ?? '',
    description: description,
    category: 'Membership',
    subCategory: !isTeamMembership ? description : school?.division ? school?.division : '',
    ...(isTeamMembership && { numberOfTeams: numOfTeams }),
    ...(isTeamMembership && { organization: school?.name ? school?.name : '' }),
    ...(isTeamMembership && { team: team }),
    ...(!isTeamMembership && { email: user.EmailAddress ?? '' }),
    ...(!isTeamMembership && { itaId: itaId }),
    startDate: dates.startDate,
    expiryDate: dates.endDate
  }
}

export const createPaymentPayload = (
  total: number,
  methodId: string,
  metadata,
  description: string
): CreatePaymentRequest => {
  return {
    Cost: total,
    Description: description,
    Metadata: metadata,
    PaymentMethodId: methodId,
    VenueID: '00000000-0000-0000-0000-000000000001',
    ScopeID: '00000000-0000-0000-0000-000000001894'
  }
}

export const getTeamMembershipTransaction = (
  schools: GetSchools | undefined,
  orderItem: OrderItem,
  paymentId = ''
) => {
  const teams = getTeams()
  const orgData = getOrgData()!
  const school = schools?.schools?.find(school => school?.name === orgData?.school)
  const role = orgData?.primaryRole

  const organisation = {
    name: orgData.school,
    mailingAddress: {
      state: orgData.state,
      city: orgData.city,
      zipCode: orgData.zipCode,
      street: orgData.mailingAddress
    },
    teamIds: [
      teams === Teams.WOMEN || teams === Teams.MEN_AND_WOMEN ? school?.womanId : '',
      teams === Teams.MEN || teams === Teams.MEN_AND_WOMEN ? school?.manId : ''
    ]
  }
  const method = getPaymentMethod()
  const addInfo = {
    name: orderItem.name,
    value: orderItem?.amount?.toString()
  }

  const transactionPayload = {
    teamIds: {
      ...(teams === Teams.WOMEN || teams === Teams.MEN_AND_WOMEN
        ? { femaleTeamId: school?.womanId }
        : {}),
      ...(teams === Teams.MEN || teams === Teams.MEN_AND_WOMEN ? { maleTeamId: school?.manId } : {})
    },
    paymentId: paymentId,
    venueRole: role,
    ...(method === 'credit-card' && { additionalInfo: addInfo }),
    organisation: organisation
  }

  return transactionPayload
}

export const getSCTransaction = (paymentId: string) => {
  const additionalInfo = generateSCAditionalData(JSON.parse(getPlayerDetails()!))
  return {
    paymentId: paymentId,
    additionalInfo: additionalInfo
  }
}

export const getCorporateTransaction = (paymentId: string) => {
  const corpInfo = getCorporateInfo()
  const additionalInfo = { name: 'companyName', value: corpInfo.companyName }
  const organisation = {
    name: corpInfo.companyName,
    mailingAddress: {
      state: corpInfo.state,
      city: corpInfo.city,
      zipCode: corpInfo.zipCode,
      street: corpInfo.mailingAddress
    }
  }
  return {
    paymentId: paymentId,
    organisation: organisation,
    additionalInfo: additionalInfo
  }
}

export const getConferenceTransaction = (paymentId: string) => {
  const confInfo = getConferenceInfo()
  const additionalInfo = [
    { name: 'confName', value: confInfo.conferenceName },
    { name: 'division', value: confInfo.division }
  ]
  const organisation = {
    name: confInfo.conferenceName,
    mailingAddress: {
      state: confInfo.state,
      city: confInfo.city,
      zipCode: confInfo.zipCode,
      street: confInfo.mailingAddress
    }
  }
  return {
    paymentId: paymentId,
    division: confInfo ? confInfo.division : '',
    organisation: organisation,
    additionalInfo: additionalInfo
  }
}

export const getUSTASectionTransaction = (paymentId: string) => {
  const ustaInfo = getUSTASectionInfo()
  const additionalInfo = { name: 'sectionName', value: ustaInfo.ustaSectionName }
  const organisation = {
    name: ustaInfo.ustaSectionName,
    mailingAddress: {
      state: ustaInfo.state,
      city: ustaInfo.city,
      zipCode: ustaInfo.zipCode,
      street: ustaInfo.mailingAddress
    }
  }
  return {
    paymentId: paymentId,
    additionalInfo: additionalInfo,
    organisation: organisation
  }
}

export const getAssociateProfessionalTransaction = (paymentId: string) => {
  const associateInfo = getAssociateInfo()
  const additionalInfo = { name: 'organisationName', value: associateInfo.orgName }
  const organisation = {
    name: associateInfo.orgName,
    mailingAddress: {
      state: associateInfo.state,
      city: associateInfo.city,
      zipCode: associateInfo.zipCode,
      street: associateInfo.mailingAddress
    }
  }
  return {
    paymentId: paymentId,
    additionalInfo: additionalInfo,
    organisation: organisation
  }
}

export const getEmeritusTransaction = (paymentId: string) => {
  const emeritusInfo = getEmeritusInfo()

  const {
    firstName,
    lastName,
    state,
    city,
    zipCode,
    mailingAddress,
    school,
    lastCoachedSchool
  } = emeritusInfo

  const additionalInfo = [
    { name: 'firstName', value: firstName },
    { name: 'lastName', value: lastName },
    { name: 'state', value: state },
    { name: 'city', value: city },
    { name: 'zipCode', value: zipCode },
    { name: 'street', value: mailingAddress }
  ]

  return {
    paymentId: paymentId,
    additionalInfo: additionalInfo,
    school: {
      schoolId: school ?? '',
      name: lastCoachedSchool ?? ''
    }
  }
}
