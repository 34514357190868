import { BodySmall, BodySmallBold } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useRouteMembership } from 'src/hooks/use-existing-membership'
import { LIST_OF_STATES } from 'src/utils/listOfStates'
import { ReactComponent as DownArrorSVG } from '../../../assets/icn_arrow-down.svg'
import FormErrorMessage from '../form-error-message/form-error-message'
import Select from '../select/select'
import styles from './address-form.module.scss'

interface AddressFormProps {
  values: any
  errors: any
  setFieldValue: any
  touched: any
  showRequired?: boolean
  isSubmitting?: boolean
}

type InputProps = {
  field?: {
    name: string
    value: string
  }
}

export const Input: React.FC<InputProps> = ({ field, ...props }: InputProps) => (
  <input className={styles.inputOutlined} {...field} {...props} />
)

const AddressForm: React.FC<AddressFormProps> = ({
  values,
  errors,
  setFieldValue,
  touched,
  showRequired
}) => {
  const { t } = useTranslation()
  const membershipCode = useRouteMembership()
  const flag = showRequired !== false ? '*' : ''

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container>
          <>
            <Grid container>
              <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                {t('mailing address', { flag })}
              </BodySmallBold>
              {membershipCode === MembershipCodeEnum.CPM && (
                <BodySmall spacing={{ margins: { md: 'top', xs: 'left' } }}>
                  {t('scorebook and rulebook')}
                </BodySmall>
              )}
              <Field name="mailingAddress" component={Input} />
              {errors?.mailingAddress && touched?.mailingAddress && (
                <FormErrorMessage message={errors.mailingAddress} />
              )}
            </Grid>
            <Grid container>
              <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                {t('city', { flag })}
              </BodySmallBold>
              <Field name="city" component={Input} />
              {errors?.city && touched?.city && <FormErrorMessage message={errors.city} />}
            </Grid>
            <Grid container>
              <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                {t('zip code', { flag })}
              </BodySmallBold>
              <Field name="zipCode" component={Input} />
              {errors?.zipCode && touched?.zipCode && <FormErrorMessage message={errors.zipCode} />}
            </Grid>
            <Grid container>
              <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                {t('state', { flag })}
              </BodySmallBold>
              <Field
                name="state"
                defaultValue={values.state}
                options={LIST_OF_STATES}
                component={Select}
                value={values.state}
                onSelect={option => setFieldValue('state', option.abbreviation)}
                IconComponent={() => <DownArrorSVG />}
                style={{ borderRadius: 0 }}
              />
              {errors?.state && touched?.state && <FormErrorMessage message={errors.state} />}
            </Grid>
          </>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddressForm
