import React from 'react'
import SummerCircuitDetailsForm from '../summer-circuit-details-form/summer-circuit-details-form'
import { Formik } from 'formik'
import { RouteComponentProps, navigate } from '@reach/router'
import { SC_PAYMENT_ROUTE } from 'src/utils/constants/routes'
import { setCurrentStep, setPlayerDetails } from '../../../utils/localStorage/local-storage'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'

interface PaymentProps extends RouteComponentProps<any> {
  setStep?: React.Dispatch<React.SetStateAction<number>>
  membership?: string
  isSummerCircuit?: boolean
}

const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    city: yup.string().required(t('is required', { type: 'city' })),
    state: yup.string().required(t('is required', { type: 'state' })),
    status: yup.string().required(t('is required', { type: 'school' })),
    yearInSchool: yup.string().required(t('is required', { type: 'year in school' })),
    collegiateDivision: yup.string().required(t('is required', { type: 'collegiate division' }))
  })

const SummerCircuitDetails: React.FC<PaymentProps> = setStep => {
  const { t } = useTranslation()
  const validationSchema = getValidationSchema(t)

  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.PLA)

  const initialValues = {
    city: '',
    state: '',
    status: '',
    yearInSchool: '',
    collegiateDivision: ''
  }

  const handleSubmit = (values, actions) => {
    setPlayerDetails(values)
    navigate(SC_PAYMENT_ROUTE)
    // If multiple active terms, there's an additional first step to select the term
    setCurrentStep(hasMultipleActiveTerms ? '2' : '1')
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {formikProps => <SummerCircuitDetailsForm {...formikProps} />}
    </Formik>
  )
}

export default SummerCircuitDetails
