import { BodyLarge, BodyRegular, H1, H3, Button } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { navigate, RouteComponentProps } from '@reach/router'
import React, { AnchorHTMLAttributes, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import {
  PAYMENT_CONFIRMATION_ROUTE,
  PAYMENT_ROUTE,
  SC_PAYMENT_CONFIRMATION_ROUTE
} from 'src/utils/constants/routes'
import {
  clearOrgsData,
  setCurrentStep,
  getTermId,
  retrieveToken
} from 'src/utils/localStorage/local-storage'
import SpanningTable from '../spanning-table/spanning-table'
import StaffRoles from './staff-roles'
import styles from './register-payment-confirmation.module.scss'
import cx from 'classnames'
import { environmentSettings } from 'src/config'
import { GET_INVOICE_URL } from './register-payment-confirmation.queries'
import { useQuery } from '@apollo/react-hooks'
import { MembershipCodeEnum, TransactionSuccessEnum } from 'src/graphql-types/globalTypes'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { useOwnMembers } from 'src/hooks/use-own-members'
import { useTeamIds } from 'src/hooks/use-team-ids'
import { useRouteMembership } from 'src/hooks/use-existing-membership'

interface PaymentConfirmationProps extends RouteComponentProps {
  setStep?: React.Dispatch<React.SetStateAction<number>>
}

interface InvoicePanelProps {
  isUnpaidInvoice: boolean
}

function InvoicePanel({ isUnpaidInvoice }: InvoicePanelProps) {
  const { t } = useTranslation()
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.CPM)
  const termId = getTermId()

  const { data } = useOwnMembers({
    code: MembershipCodeEnum.CPM
  })

  const members = data?.getOwnMembers?.filter(member => member?.termId === termId)

  const [transaction] = members?.[members?.length - 1]?.transactionIds || []
  const isCreditCardPayment = transaction?.paymentType === 'CREDIT_CARD'
  const transactionId = transaction?.id

  const { data: invoiceData } = useQuery(GET_INVOICE_URL, {
    variables: { transactionId },
    skip: !transactionId || !retrieveToken() || isCreditCardPayment
  })

  return (
    <div>
      {invoiceData?.invoiceUrl ? (
        <div className={styles.invoiceContainer}>
          <H3 spacing={{ margins: { lg: 'top' } }}>{t('invoice details')}</H3>
          <BodyLarge spacing={{ margins: { xs: 'top', md: 'bottom' } }}>
            {t('invoice download desc')}
          </BodyLarge>
          <a href={invoiceData?.invoiceUrl} download className={styles.downloadInvoiceButton}>
            {t('download invoice')}
          </a>
        </div>
      ) : null}
      {isUnpaidInvoice && (
        <div className={styles.changePaymentContainer}>
          <H3 spacing={{ margins: { lg: 'top' } }}>{t('change payment method')}</H3>
          <BodyLarge spacing={{ margins: { xs: 'top' } }}>
            {t('change payment method paragraph one')}
          </BodyLarge>
          <BodyLarge>{t('change payment method paragraph two')}</BodyLarge>
          <Button
            spacing={{ margins: { sm: 'top' } }}
            level="secondary"
            classname={styles.invoiceButton}
            onClick={() => {
              setCurrentStep(hasMultipleActiveTerms ? '2' : '1')
              navigate(`${PAYMENT_ROUTE}?change-payment=true`)
            }}
          >
            {t('pay with credit card')}
          </Button>
        </div>
      )}
    </div>
  )
}

const RegisterPaymentConfirmation: React.FC<PaymentConfirmationProps> = () => {
  const { t } = useTranslation()
  const membershipCode = useRouteMembership()
  const termId = getTermId()
  const { mensId, womensId } = useTeamIds(MembershipCodeEnum.CPM)
  const { data } = useOwnMembers({
    code: MembershipCodeEnum.CPM
  })
  const member = data?.getOwnMembers?.filter(member => member?.termId === termId)
  const latestMemberTransaction = member?.[member?.length -1]?.transactionIds ?? []

  const paidInvoice = latestMemberTransaction?.some(t =>
    t?.paymentType === 'INVOICE' && t?.success === TransactionSuccessEnum.SUCCESS
  )

  const successfulCardPayment = latestMemberTransaction?.some(t =>
    t?.paymentType === 'CREDIT_CARD' && t?.success === TransactionSuccessEnum.SUCCESS
  )

  useEffect(() => {
    clearOrgsData()
    window.onpopstate = () => {
      setCurrentStep('2')
      navigate(
        membershipCode === MembershipCodeEnum.PLA
          ? SC_PAYMENT_CONFIRMATION_ROUTE
          : PAYMENT_CONFIRMATION_ROUTE
      )
    }
  }, [membershipCode])

  const showStaffDetails = successfulCardPayment || paidInvoice

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12}>
          <H1 spacing={{ margins: { md: 'top' } }}>
            {showStaffDetails ? t('confirmation and add staff') : t('confirmation')}
          </H1>
        </Grid>
        <Grid item xs={12} md={12} lg={9}>
          <BodyLarge spacing={{ margins: { md: ['top', 'bottom'] } }}>
            {showStaffDetails ? (
              <Trans
                i18nKey="team membership confirmation text cc"
                components={{
                  bold: <b />
                }}
              />
            ) : (
              t('team membership confirmation text invoice')
            )}
          </BodyLarge>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.membershipSummary}>
            <div className={styles.summaryTitle}>
              <H3>{t('order confirmation')}</H3>
            </div>
            <SpanningTable isConfirmation />
          </div>
        </Grid>
        {!showStaffDetails && (
          <InvoicePanel isUnpaidInvoice={!successfulCardPayment && !paidInvoice} />
        )}

        {(mensId || womensId) && showStaffDetails && (
          <>
            <H3>{t('manage team question')}</H3>
            <BodyRegular spacing={{ margins: { md: 'bottom' } }}>
              <Trans
                i18nKey="manage team text"
                components={{
                  bold: <b />
                }}
              />
            </BodyRegular>
            <Grid item container className={styles.manageTeamButtonContainer}>
              {mensId && (
                <ButtonLink
                  href={`${environmentSettings.REACT_APP_CLASSIC_API}/Admin/vidar/${mensId}`}
                >
                  {womensId && t('manage men team')}
                  {!womensId && t('manage team')}
                </ButtonLink>
              )}
              {womensId && (
                <ButtonLink
                  href={`${environmentSettings.REACT_APP_CLASSIC_API}/Admin/vidar/${womensId}`}
                >
                  {mensId && t('manage women team')}
                  {!mensId && t('manage team')}
                </ButtonLink>
              )}
            </Grid>
            <StaffRoles mensId={mensId} womensId={womensId} />
          </>
        )}
        {showStaffDetails && (
          <InvoicePanel isUnpaidInvoice={!successfulCardPayment && !paidInvoice} />
        )}
      </Grid>
    </Grid>
  )
}

interface ButtonLinkInterface extends AnchorHTMLAttributes<HTMLAnchorElement> {
  indent?: boolean
}

const ButtonLink: React.FC<ButtonLinkInterface> = ({ children, indent, ...props }) => {
  return (
    <a {...props} className={cx(styles.manageTeamButton, { [styles.manageSpacing]: indent })}>
      {children}
    </a>
  )
}

export default RegisterPaymentConfirmation
