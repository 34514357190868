export const SUMMER_CIRCUIT = 'summer-circuit'
export const TEAM_MEMBERSHIP = 'team'
export const CORPORATE_MEMBERSHIP = 'corporate-membership'
export const CONFERENCE_MEMBERSHIP = 'conference-membership'
export const USTA_SECTION = 'usta-section-membership'
export const ASSOCIATE_PROFFESIONAL = 'associate/proffesional'
export const COACH_EMERITUS_MEMBERSHIP = 'coach-emeritus-membership'

export const membershipNameStrings = {
  SUMMER_CIRCUIT,
  TEAM_MEMBERSHIP,
  CORPORATE_MEMBERSHIP,
  CONFERENCE_MEMBERSHIP,
  USTA_SECTION,
  ASSOCIATE_PROFFESIONAL,
  COACH_EMERITUS_MEMBERSHIP
}
