import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { BodySmallBold, Button, H2, Spinner } from '@clubspark-react/clubspark-react-tools'
import { Grid, Hidden } from '@material-ui/core'
import { navigate, RouteComponentProps } from '@reach/router'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetSchools } from 'src/graphql-types/GetSchools'
import { EMERITUS_PAYMENT_ROUTE, HOME_ROUTE } from 'src/utils/constants/routes'
import {
  getTermId,
  setCurrentStep,
  setEmeritusInfo,
  setTeams
} from 'src/utils/localStorage/local-storage'
import * as yup from 'yup'
import AddressForm, { Input } from '../address-form/address-form'
import AdvancedDropdown from '../advanced-dropdown/advanced-dropdown'
import FormErrorMessage from '../form-error-message/form-error-message'
import MembershipSummary from '../membership-summary/membership-summary'
import { GET_SCHOOLS } from '../register-school-form/register-school-form-queries'
import styles from './emeritus-details.module.scss'
import { ReactComponent as SearchSVG } from 'src/assets/icn_search.svg'
import { Teams } from 'src/types/types'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { usePrice } from 'src/hooks/use-price'
import { useMembershipTerms } from '../../auth/register/register'
import { useToken } from '../../../hooks/use-token'

interface EmeritusDetailsProps extends RouteComponentProps {}

const EmeritusDetails: React.FC<EmeritusDetailsProps> = () => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const { token } = useToken()
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.COEM, !token)
  const { amount } = usePrice({
    skip: !getTermId(),
    variables: { input: { membershipCode: MembershipCodeEnum.COEM, termId: getTermId() } }
  })
  const { data: schoolsInfo } = useQuery<GetSchools>(GET_SCHOOLS)
  const [loading, setLoading] = useState<boolean>(false)

  const initialValues = {
    firstName: '',
    lastName: '',
    city: '',
    mailingAddress: '',
    state: '',
    zipCode: '',
    school: '',
    lastCoachedSchool: ''
  }

  const validationSchema = yup.object().shape(
    {
      firstName: yup.string().required(t('is required', { type: 'first name' })),
      lastName: yup.string().required(t('is required', { type: 'last name' })),
      mailingAddress: yup.string().required(t('is required', { type: 'mailing address' })),
      city: yup.string().required(t('is required', { type: 'city' })),
      state: yup.string().required(t('is required', { type: 'state' })),
      zipCode: yup.string().required(t('is required', { type: 'zip code' })),
      school: yup.string().when('lastCoachedSchool', {
        is: '',
        then: yup.string().required(t('is required', { type: 'school' })),
        otherwise: yup.string()
      }),
      lastCoachedSchool: yup.string().when('school', {
        is: '',
        then: yup.string().required(t('is required', { type: 'last coached school input' })),
        otherwise: yup.string()
      })
    },
    [['school', 'lastCoachedSchool']]
  )

  const handleBack = () => {
    navigate(HOME_ROUTE)
  }

  const onSubmit = async (values: any, actions) => {
    setLoading(true)
    actions.setSubmitting(true)
    setEmeritusInfo(values)
    setLoading(false)
    actions.setSubmitting(false)
    if (!loading) {
      navigate(EMERITUS_PAYMENT_ROUTE)
      setCurrentStep(hasMultipleActiveTerms ? '2' : '1')
    }
  }

  function getTeams(option: any): string {
    if (option && option.manId && !option.womanId) {
      return Teams.MEN
    } else if (option && !option.manId && option.womanId) {
      return Teams.WOMEN
    } else {
      return Teams.MEN_AND_WOMEN
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <H2 spacing={{ margins: { xxs: 'bottom' } }}>{t('details')}</H2>
                </Grid>
                <Grid container>
                  <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('first name')} *
                  </BodySmallBold>
                  <Field name="firstName" component={Input} />
                  {errors?.firstName && touched?.firstName && (
                    <FormErrorMessage message={errors.firstName} />
                  )}
                </Grid>
                <Grid container>
                  <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('last name')} *
                  </BodySmallBold>
                  <Field name="lastName" component={Input} />
                  {errors?.lastName && touched?.lastName && (
                    <FormErrorMessage message={errors.lastName} />
                  )}
                </Grid>
                <AddressForm
                  errors={errors}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
                <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('last coached school')}
                </BodySmallBold>
                <Grid container>
                  <Field
                    aria-label="school"
                    name="school"
                    component={AdvancedDropdown}
                    options={schoolsInfo?.schools}
                    validateOnChange={true}
                    disabled={Boolean(values.lastCoachedSchool)}
                    onSelect={option => {
                      if (option) {
                        setFieldValue('school', option.id)
                        setTeams(getTeams(option))
                        // setSelectedSchool(option.id)
                      } else {
                        setFieldValue('school', '')
                        setTeams('')
                        // setSelectedSchool('')
                        client.writeData({ data: { cost: '' } })
                      }
                    }}
                    placeholder={t('enter school name')}
                    setFieldValue={setFieldValue}
                  />
                  <SearchSVG className={styles.searchIcon} />
                </Grid>
                <Grid container>
                  <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('last coached school input')}
                  </BodySmallBold>
                  <Field
                    name="lastCoachedSchool"
                    component={Input}
                    disabled={Boolean(values.school)}
                  />
                  {errors?.lastCoachedSchool && touched?.lastCoachedSchool && (
                    <FormErrorMessage message={errors.lastCoachedSchool} />
                  )}
                </Grid>
                {isSubmitting ? (
                  <Grid container justify="flex-start" className={styles.spinnerContainer}>
                    <Spinner />
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={styles.actionButtonContainer}
                  >
                    <div className={styles.root}>
                      <Button
                        type="button"
                        classname={styles.buttonBack}
                        size="lg"
                        level="secondary"
                        onClick={handleBack}
                      >
                        {t('back')}
                      </Button>
                      <Button type="submit" size="lg" classname={styles.button}>
                        {t('continue')}
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Hidden xsDown>
              <MembershipSummary cost={amount} />
            </Hidden>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
export default EmeritusDetails
