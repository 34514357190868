import React from 'react'
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#4b90d9' }
  }
})

const ThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
)

export default ThemeProvider
