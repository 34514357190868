/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DiscountTypeEnum {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export enum DivisionEnum {
  DIV_I = "DIV_I",
  DIV_II = "DIV_II",
  DIV_III = "DIV_III",
  JUCO = "JUCO",
  NAIA = "NAIA",
}

export enum MemberStateEnum {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  LAPSED = "LAPSED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  UNKNOWN = "UNKNOWN",
}

export enum MemberTypeEnum {
  INDIVIDUAL = "INDIVIDUAL",
  ORGANISATION = "ORGANISATION",
}

export enum MembershipCodeEnum {
  ASCPRO = "ASCPRO",
  COEM = "COEM",
  CONF = "CONF",
  CORP = "CORP",
  CPM = "CPM",
  PLA = "PLA",
  SUM = "SUM",
  USTASEC = "USTASEC",
}

export enum MembershipStateEnum {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  DIRECT_LINK = "DIRECT_LINK",
  HIDDEN = "HIDDEN",
  UNKNOWN = "UNKNOWN",
}

export enum SortByFieldEnum {
  CODE = "CODE",
  END = "END",
  MEMBERS = "MEMBERS",
  NAME = "NAME",
  SOLD = "SOLD",
  START = "START",
  STATUS = "STATUS",
  TYPE = "TYPE",
}

export enum SortDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
  NONE = "NONE",
}

export enum TeamTypeEnum {
  Men_And_Women = "Men_And_Women",
  Men_s = "Men_s",
  Women_s = "Women_s",
}

export enum TransactionSuccessEnum {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  SUCCESS = "SUCCESS",
}

export interface CoachEmeritusTransaction {
  school: SchoolInput;
  paymentId: string;
  additionalInfo?: (MemberInfoInput | null)[] | null;
  termId: string;
}

export interface CollegeMembershipTransaction {
  organisation: PartialOrganisationInput;
  teamIds: TeamIds;
  paymentId?: string | null;
  venueRole: string;
  additionalInfo?: (MemberInfoInput | null)[] | null;
  termId: string;
}

export interface GetPriceForSchoolInput {
  membershipCode: string;
  termId: string;
  teamIds?: TeamIds | null;
}

export interface GetPriceInput {
  membershipCode: string;
  termId: string;
  division?: DivisionEnum | null;
}

export interface IndividualMembershipTransaction {
  paymentId: string;
  division?: DivisionEnum | null;
  additionalInfo?: (MemberInfoInput | null)[] | null;
  termId: string;
}

export interface MemberInfoInput {
  name?: string | null;
  value?: string | null;
}

export interface MembershipAddressInput {
  country?: string | null;
  countryCode?: string | null;
  state?: string | null;
  city?: string | null;
  zipCode?: string | null;
  street?: string | null;
  houseNumber?: string | null;
}

export interface OrganisationMembershipTransaction {
  organisation: PartialOrganisationInput;
  paymentId?: string | null;
  additionalInfo?: (MemberInfoInput | null)[] | null;
  division?: DivisionEnum | null;
  termId: string;
}

export interface PaginationInput {
  page?: number | null;
  entriesPerPage?: number | null;
}

export interface PartialOrganisationInput {
  name: string;
  physicalAddress?: MembershipAddressInput | null;
  mailingAddress?: MembershipAddressInput | null;
  billingAddress?: MembershipAddressInput | null;
  tags?: (string | null)[] | null;
  teamIds?: (string | null)[] | null;
}

export interface SchoolInput {
  schoolId?: string | null;
  name?: string | null;
}

export interface SortingInput {
  sortBy?: SortByFieldEnum | null;
  sortDirection?: SortDirectionEnum | null;
}

export interface TeamIds {
  maleTeamId?: string | null;
  femaleTeamId?: string | null;
}

export interface WomanIdManId {
  womanId?: string | null;
  manId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
