import { Box, Paper } from '@material-ui/core'
import React from 'react'
import styles from './page-container.module.scss'

interface PageContainerProps {
  children: React.ReactNode
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  return (
    <Box clone p={{ xs: '0 20px 0 20px', sm: '0 20px 0 20px', md: '0 135px 0 135px' }}>
      <Paper className={styles.paper}>{children}</Paper>
    </Box>
  )
}

export default PageContainer
