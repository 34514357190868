import { BodySmallBold, Button, H2, Spinner } from '@clubspark-react/clubspark-react-tools'
import { Grid, Hidden } from '@material-ui/core'
import { navigate, RouteComponentProps } from '@reach/router'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useExistingMembership } from 'src/hooks/use-existing-membership'
import { usePrice } from 'src/hooks/use-price'
import { CorporateInfo } from 'src/types/types'
import { CORPORATE_PAYMENT_ROUTE, HOME_ROUTE } from 'src/utils/constants/routes'
import {
  getCorporateInfo,
  getTermId,
  setCorporateInfo,
  setCurrentStep
} from 'src/utils/localStorage/local-storage'
import * as yup from 'yup'
import AddressForm, { Input } from '../address-form/address-form'
import FormErrorMessage from '../form-error-message/form-error-message'
import MembershipSummary from '../membership-summary/membership-summary'
import styles from './corporate-details.module.scss'

interface CorporateDetailsProps extends RouteComponentProps {}

const CorporateDetails: React.FC<CorporateDetailsProps> = () => {
  const { t } = useTranslation()
  const { loadingExistingMembership } = useExistingMembership(MembershipCodeEnum.CORP)
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.CORP)
  const { amount } = usePrice({
    skip: !getTermId(),
    variables: { input: { termId: getTermId(), membershipCode: MembershipCodeEnum.CORP } }
  })
  const [loading, setLoading] = useState<boolean>(false)
  const corporateInfo = getCorporateInfo()

  let initialValues: CorporateInfo = {
    companyName: corporateInfo ? corporateInfo.companyName : '',
    city: corporateInfo ? corporateInfo.city : '',
    mailingAddress: corporateInfo ? corporateInfo.mailingAddress : '',
    state: corporateInfo ? corporateInfo.state : '',
    zipCode: corporateInfo ? corporateInfo.zipCode : ''
  }

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('is required', { type: 'company name' })),
    mailingAddress: yup.string().required(t('is required', { type: 'mailing address' })),
    city: yup.string().required(t('is required', { type: 'city' })),
    state: yup.string().required(t('is required', { type: 'state' })),
    zipCode: yup.string().required(t('is required', { type: 'zip code' }))
  })

  const onSubmit = async (values: CorporateInfo, actions) => {
    setLoading(true)
    actions.setSubmitting(true)
    setCorporateInfo(values)
    setLoading(false)
    actions.setSubmitting(false)
    if (!loading) {
      navigate(CORPORATE_PAYMENT_ROUTE)
      // If multiple active terms, there's an additional first step to select the term
      setCurrentStep(hasMultipleActiveTerms ? '2' : '1')
    }
  }

  const handleBack = () => {
    navigate(HOME_ROUTE)
  }

  if (loadingExistingMembership) {
    return (
      <Grid container justify="flex-start" className={styles.spinnerContainer}>
        <Spinner />
      </Grid>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, touched, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <H2 spacing={{ margins: { xxs: 'bottom' } }}>{t('corporate details')}</H2>
                </Grid>
                <Grid container>
                  <BodySmallBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('company name')} *
                  </BodySmallBold>
                  <Field name="companyName" component={Input} />
                  {errors?.companyName && touched?.companyName && (
                    <FormErrorMessage message={errors.companyName} />
                  )}
                </Grid>
                <AddressForm
                  errors={errors}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
                {isSubmitting ? (
                  <Grid container justify="flex-start" className={styles.spinnerContainer}>
                    <Spinner />
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={styles.actionButtonContainer}
                  >
                    <div className={styles.root}>
                      <Button
                        type="button"
                        classname={styles.buttonBack}
                        size="lg"
                        level="secondary"
                        onClick={handleBack}
                      >
                        {t('back')}
                      </Button>
                      <Button type="submit" size="lg" classname={styles.button}>
                        {t('continue')}
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Hidden xsDown>
              <MembershipSummary cost={amount} />
            </Hidden>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
export default CorporateDetails
