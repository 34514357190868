import React from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { useTranslation } from 'react-i18next'
import { TeamRolesEnum } from 'src/types/types'
import { BodySmallBold, BodyLarge } from '@clubspark-react/clubspark-react-tools'
import HR from 'src/App/components/hr/hr'
import styles from './team-staff-details.module.scss'
import cx from 'classnames'
import { ReactComponent as IconAlert } from 'src/assets/icons-alert-filled.svg'

export interface TeamStaffDetailsProps {
  roles?: Partial<Record<TeamRolesEnum, string[]>>
}

const EmptyStaff = () => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center">
      <IconAlert />
      <BodyLarge classnames={styles.emptyStaffTypography}>{t('role not filled')}</BodyLarge>
    </Grid>
  )
}

const Staff: React.FC<{ staff?: string[]; title?: string }> = ({ staff, title }) => {
  if (!staff || !staff.length) {
    return (
      <div>
        <BodySmallBold classnames={styles.title}>{title}</BodySmallBold>
        <EmptyStaff />
      </div>
    )
  }

  return (
    <div>
      <BodySmallBold classnames={styles.title}>{title}</BodySmallBold>
      <ul className={styles.list}>
        {staff.map((staffItem, ind) => (
          <li key={ind}>
            <BodyLarge>{staffItem}</BodyLarge>
          </li>
        ))}
      </ul>
    </div>
  )
}

const TeamStaffDetails: React.FC<TeamStaffDetailsProps> = ({ roles }) => {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid container item className={styles.row}>
        <Grid item xs={12} sm={6}>
          <Staff staff={roles?.HEAD_COACH} title={t('head coach')} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Staff staff={roles?.ASSISTANT_COACH} title={t('assistant coaches')} />
        </Grid>
      </Grid>
      <Hidden smDown>
        <HR className={cx(styles.row, styles.separator)} />
      </Hidden>
      <Grid container item className={styles.row}>
        <Grid item xs={12} sm={6}>
          <Staff
            staff={roles?.SPORTS_INFORMATION_DIRECTOR}
            title={t('sports information director')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Staff staff={roles?.DIRECTOR_OF_OPERATIONS} title={t('director of operation')} />
        </Grid>
      </Grid>
      <Hidden smDown>
        <HR className={cx(styles.row, styles.separator)} />
      </Hidden>
      <Grid container item>
        <Grid item xs={12} sm={6}>
          <Staff staff={roles?.BUSINESS_ADMIN} title={t('business admin')} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TeamStaffDetails
