export const getEnvConfig = (): { [key: string]: string } => {
  if (typeof window !== 'undefined') {
    return window['__env__']
  }
  return {}
}

const env = getEnvConfig()

const allEnvironments = {
  // is taken out of docker image container

  REACT_APP_GATEWAY_GQL_URL:
    env?.REACT_APP_GATEWAY_GQL_URL || 'https://test-itat-services.clubspark.io/graphql',
  REACT_APP_CLIENT_ROOT: env?.REACT_APP_CLIENT_ROOT || 'https://tst-itat-membership.clubspark.io',
  REACT_APP_STRIPE_KEY: env?.REACT_APP_STRIPE_KEY || 'pk_test_J1OnVPyl9Y3zD7Ii2zjVv3Um',
  REACT_APP_CLASSIC_API: env?.REACT_APP_CLASSIC_API || 'https://test-itat.clubspark.io',
  REACT_APP_CLASSIC_DOMAIN: env?.REACT_APP_CLASSIC_DOMAIN || 'clubspark.io'
}
export const environmentSettings = {
  ...(allEnvironments as typeof allEnvironments)
}
