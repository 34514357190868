import { useTranslation } from 'react-i18next'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useRouteMembership } from 'src/hooks/use-existing-membership'

export const usePlayerDetailsOptions = () => {
  const { t } = useTranslation()
  const membershipCode = useRouteMembership()

  return {
    STATUS_OPTIONS: [
      { label: t('junior player'), value: 'current junior player' },
      { label: t('senior player'), value: 'current college player' },
      { label: 'NA', value: 'NA' }
    ],
    YEAR_IN_SCHOOL_OPTIONS: [
      { label: t('hs freshman'), value: 'hs freshman' },
      { label: t('hs sophomore'), value: 'hs sophomore' },
      { label: t('hs junior'), value: 'hs junior' },
      { label: t('hs senior'), value: 'hs senior' },
      { label: t('college freshman'), value: 'college freshman' },
      { label: t('college sophomore'), value: 'college sophomore' },
      { label: t('college junior'), value: 'college junior' },
      { label: t('college senior'), value: 'college senior' },
      { label: 'NA', value: 'NA' }
    ],
    COLLEGIATE_DIVISION_OPTIONS: [
      { label: t('division I'), value: 'DIV_I' },
      { label: t('division II'), value: 'DIV_II' },
      { label: t('division III'), value: 'DIV_III' },
      { label: t('naia'), value: 'NAIA' },
      { label: t('juco'), value: 'JUCO' },
      membershipCode === MembershipCodeEnum.PLA && { label: t('N/A'), value: 'N/A' }
    ]
  }
}
