import moment from 'moment'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import {
  ASSOCIATE_PROFFESIONAL,
  COACH_EMERITUS_MEMBERSHIP,
  CONFERENCE_MEMBERSHIP as CONFERENCE,
  CORPORATE_MEMBERSHIP as CORPORATE,
  USTA_SECTION
} from 'src/utils/constants/strings'

export interface MembershipInfo {
  name: string
  subText: string
  benefits: string[]
  type: string
  code: MembershipCodeEnum
}

export const CORPORATE_MEMBERSHIP: MembershipInfo = {
  name: 'Corporate Membership',
  subText:
    'The Corporate Membership is for companies who wish to do business with the ITA and ITA Members.',
  benefits: [
    'Access to the ITA College Tennis Coaches Directory (~1,300 head coaches)',
    'Inclusion in the ITA Corporate Member Offerings email sent to ITA coaches 2x per year',
    'Discounts to the ITA Coaches Convention, ITA Scorebook Ads, and more',
    'Placement on the ITA Corporate Members page of the ITA website',
    'Classified ads (for hiring only)',
    'Customized email lists for your email marketing efforts'
  ],
  type: CORPORATE,
  code: MembershipCodeEnum.CORP
}

export const CONFERENCE_MEMBERSHIP: MembershipInfo = {
  name: 'Conference Membership',
  subText:
    'The ITA Conference Membership is open to Division I, II, III, JUCO, and NAIA conferences.',
  benefits: [
    'Access to ITA Rules Interpretations upon request',
    'Staff assistance for ITA rules education (for officials or coaches)',
    'Two hard copies of the ITA Rulebook',
    'Complimentary sanctioning of your ITA individual tournament (if hosted/run by the conference)',
    'Custom ITA ranking lists (including “unpublished” ITA team rankings) used for seeding at conference tournaments',
    'Complimentary Classified Ads',
    'Access to the ITA Coaches Directory.'
  ],
  type: CONFERENCE,
  code: MembershipCodeEnum.CONF
}

const currentYear = moment().year()

export const USTA_MEMBERSHIP: MembershipInfo = {
  name: 'USTA Section',
  subText:
    'The ITA membership for USTA Sections is for the individual geographic sections of the United States Tennis Association.',
  benefits: [
    'Two hard copies of the ITA Rulebook',
    'Ability to sanction an ITA collegiate tournament',
    'Complimentary Classified Ads',
    'Access to the ITA Coaches Directory (year round), plus one custom Section list per year',
    'Promotion of your section showcase or other organized recruiting opportunities through one coaches e-mail and on the ITA Coaches’ Workspace',
    'Assistance in putting together coaches’ panels for info sessions and showcases.'
  ],
  type: USTA_SECTION,
  code: MembershipCodeEnum.USTASEC
}

export const ASSOCIATE_MEMBERSHIP: MembershipInfo = {
  name: 'Associate',
  subText:
    'The ITA membership for Associate/Professional is for teaching pros, high school coaches, tennis centers/clubs, recreational parks, and the general public.',
  benefits: [
    'Ability to sanction an ITA collegiate tournament',
    `Ability to host an ITA Summer Circuit and/or ${currentYear} Fall Circuit tournament`,
    'Complimentary Classified Ads',
    'Ability to attend the annual ITA Coaches Convention, held at the Naples Grande Beach Resort in Naples, Florida in December.'
  ],
  type: ASSOCIATE_PROFFESIONAL,
  code: MembershipCodeEnum.ASCPRO
}

export const EMERITUS_MEMBERSHIP: MembershipInfo = {
  name: 'Coach Emeritus Membership',
  subText:
    'The ITA membership for Associate/Professional is for teaching pros, high school coaches, tennis centers/clubs, recreational parks, and the general public.',
  benefits: [
    'Access to ITA Retired Coaches Directory',
    'Access to attend the ITA Coaches Convention',
    'Access to ITA Retired Coaches Networking Events',
    'Access and participation in ITA Mentorship Webinars',
    'Access to ITA Newsletter',
    'Receive discounts from ITA Partners and Corporate Members'
  ],
  type: COACH_EMERITUS_MEMBERSHIP,
  code: MembershipCodeEnum.COEM
}
