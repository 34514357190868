import { H1 } from '@clubspark-react/clubspark-react-tools'
import { Redirect, RouteComponentProps, Router } from '@reach/router'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Footer from 'src/App/components/footer/footer'
import Header from 'src/App/components/header/header'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import {
  ASSOCIATE_DETAILS_ROUTE,
  ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE,
  ASSOCIATE_PAYMENT_ROUTE,
  ASSOCIATE_ROUTE,
  ASSOCIATE_TERMS_ROUTE,
  CONFERENCE_DETAILS_ROUTE,
  CONFERENCE_PAYMENT_CONFIRMATION_ROUTE,
  CONFERENCE_PAYMENT_ROUTE,
  CONFERENCE_ROUTE,
  CONFERENCE_TERMS_ROUTE,
  CORPORATE_DETAILS_ROUTE,
  CORPORATE_PAYMENT_CONFIRMATION_ROUTE,
  CORPORATE_PAYMENT_ROUTE,
  CORPORATE_ROUTE,
  CORPORATE_TERMS_ROUTE,
  EMERITUS_DETAILS_ROUTE,
  EMERITUS_PAYMENT_CONFIRMATION_ROUTE,
  EMERITUS_PAYMENT_ROUTE,
  EMERITUS_ROUTE,
  EMERITUS_TERMS_ROUTE,
  HOME_ROUTE,
  PAYMENT_CONFIRMATION_ROUTE,
  PAYMENT_ROUTE,
  REGISTER_ROUTE,
  REGISTER_SCHOOL_DETAILS_ROUTE,
  REGISTER_TERMS_ROUTE,
  SC_DETAILS_ROUTE,
  SC_PAYMENT_CONFIRMATION_ROUTE,
  SC_PAYMENT_ROUTE,
  SC_TERMS_ROUTE,
  SUMMER_CIRCUIT_ROUTE,
  USTA_SECTION_DETAILS_ROUTE,
  USTA_SECTION_PAYMENT_CONFIRMATION_ROUTE,
  USTA_SECTION_PAYMENT_ROUTE,
  USTA_SECTION_ROUTE,
  USTA_SECTION_TERMS_ROUTE
} from 'src/utils/constants/routes'
import { useScrollToTop, useStepReset } from 'src/utils/helper/helper'
import Register from './auth/register/register'
import AssociatePaymentConfirmation from './components/associate-confirmation/associate-confirmation'
import AssociateDetails from './components/associate-details/associate-details'
import AssociateMembership from './components/associate-membership/associate-membership'
import ConferencePaymentConfirmation from './components/conference-confirmation/conference-confirmation'
import ConferenceDetails from './components/conference-details/conference-details'
import ConferenceMembership from './components/conference-membership/conference-membership'
import CorporatePaymentConfirmation from './components/corporate-confirmation/corporate-payment-confirmation'
import CorporateDetails from './components/corporate-details/corporate-details'
import CorporateMembership from './components/corporate-membership/corporate-membership'
import EmeritusPaymentConfirmation from './components/emeritus-confirmation/emertius-confirmation'
import EmeritusDetails from './components/emeritus-details/emeritus-details'
import EmeritusMembership from './components/emeritus-membership/emeritus-membership'
import RegisterPaymentConfirmation from './components/register-payment-confirmation/register-payment-confirmation'
import RegisterPayment from './components/register-payment/register-payment'
import RegisterSchoolInfo from './components/register-school-info/register-school-info'
import RegisterTerms from './components/register-terms/register-terms'
import SummerCircuitConfirmation from './components/summer-circuit-confirmation/summer-circuit-confirmation'
import SummerCircuitDetails from './components/summer-circuit-details/summer-circult-details'
import SummerCircuit from './components/summer-circuit/summer-circuit'
import USTASectionPaymentConfirmation from './components/usta-section-confirmation/usta-section-confirmation'
import USTASectionDetails from './components/usta-section-details/usta-section-details'
import USTASectionMembership from './components/usta-section/usta-section'
import Home from './home/home'
import styles from './index.module.scss'

const App: React.FC = (): ReactElement => {
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.container}>
        <Router className={styles.router} primary={false}>
          <RoutesWrapper path="/">
            <NotFound default />
            <Redirect from="/" to={HOME_ROUTE} noThrow />
            <Home path={HOME_ROUTE} />
            <Register path={REGISTER_ROUTE}>
              <RegisterTerms
                path={REGISTER_TERMS_ROUTE}
                code={MembershipCodeEnum.CPM}
                navigateRoute={REGISTER_SCHOOL_DETAILS_ROUTE}
              />
              <RegisterSchoolInfo path={REGISTER_SCHOOL_DETAILS_ROUTE} />
              <RegisterPayment path={PAYMENT_ROUTE} />
              <RegisterPaymentConfirmation path={PAYMENT_CONFIRMATION_ROUTE} />
            </Register>
            <SummerCircuit path={SUMMER_CIRCUIT_ROUTE}>
              <RegisterTerms
                path={SC_TERMS_ROUTE}
                code={MembershipCodeEnum.PLA}
                navigateRoute={SC_DETAILS_ROUTE}
              />
              <SummerCircuitDetails path={SC_DETAILS_ROUTE} />
              <RegisterPayment path={SC_PAYMENT_ROUTE} />
              <SummerCircuitConfirmation path={SC_PAYMENT_CONFIRMATION_ROUTE} />
            </SummerCircuit>
            <CorporateMembership path={CORPORATE_ROUTE}>
              <RegisterTerms
                path={CORPORATE_TERMS_ROUTE}
                code={MembershipCodeEnum.CORP}
                navigateRoute={CORPORATE_DETAILS_ROUTE}
              />
              <CorporateDetails path={CORPORATE_DETAILS_ROUTE} />
              <RegisterPayment path={CORPORATE_PAYMENT_ROUTE} />
              <CorporatePaymentConfirmation path={CORPORATE_PAYMENT_CONFIRMATION_ROUTE} />
            </CorporateMembership>
            <ConferenceMembership path={CONFERENCE_ROUTE}>
              <RegisterTerms
                path={CONFERENCE_TERMS_ROUTE}
                code={MembershipCodeEnum.CONF}
                navigateRoute={CONFERENCE_DETAILS_ROUTE}
              />
              <ConferenceDetails path={CONFERENCE_DETAILS_ROUTE} />
              <RegisterPayment path={CONFERENCE_PAYMENT_ROUTE} />
              <ConferencePaymentConfirmation path={CONFERENCE_PAYMENT_CONFIRMATION_ROUTE} />
            </ConferenceMembership>
            <USTASectionMembership path={USTA_SECTION_ROUTE}>
              <RegisterTerms
                path={USTA_SECTION_TERMS_ROUTE}
                code={MembershipCodeEnum.USTASEC}
                navigateRoute={USTA_SECTION_DETAILS_ROUTE}
              />
              <USTASectionDetails path={USTA_SECTION_DETAILS_ROUTE} />
              <RegisterPayment path={USTA_SECTION_PAYMENT_ROUTE} />
              <USTASectionPaymentConfirmation path={USTA_SECTION_PAYMENT_CONFIRMATION_ROUTE} />
            </USTASectionMembership>
            <AssociateMembership path={ASSOCIATE_ROUTE}>
              <RegisterTerms
                path={ASSOCIATE_TERMS_ROUTE}
                code={MembershipCodeEnum.ASCPRO}
                navigateRoute={ASSOCIATE_DETAILS_ROUTE}
              />
              <AssociateDetails path={ASSOCIATE_DETAILS_ROUTE} />
              <RegisterPayment path={ASSOCIATE_PAYMENT_ROUTE} />
              <AssociatePaymentConfirmation path={ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE} />
            </AssociateMembership>
            <EmeritusMembership path={EMERITUS_ROUTE}>
              <RegisterTerms
                path={EMERITUS_TERMS_ROUTE}
                code={MembershipCodeEnum.COEM}
                navigateRoute={EMERITUS_DETAILS_ROUTE}
              />
              <EmeritusDetails path={EMERITUS_DETAILS_ROUTE} />
              <RegisterPayment path={EMERITUS_PAYMENT_ROUTE} />
              <EmeritusPaymentConfirmation path={EMERITUS_PAYMENT_CONFIRMATION_ROUTE} />
            </EmeritusMembership>
          </RoutesWrapper>
        </Router>
      </div>
      <Footer />
    </div>
  )
}

const RoutesWrapper: React.FC<{ path: string }> = ({ children }) => {
  useStepReset()
  useScrollToTop()
  return children as ReactElement
}

interface NotFoundProps extends RouteComponentProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  const { t } = useTranslation()
  return (
    <div>
      <H1>{t('page not found')}</H1>
    </div>
  )
}

export default App
