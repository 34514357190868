import { useApolloClient } from '@apollo/react-hooks'
import { BodyRegular } from '@clubspark-react/clubspark-react-tools'
import { Grid, Typography } from '@material-ui/core'
import { navigate, RouteComponentProps } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser, setToken } from 'src/api-service/api-service'
import { clearLocalStorage, setCurrentStep, setTermId } from 'src/utils/localStorage/local-storage'
import MainMembership from '../components/main-membership/main-membership'
import SubMembership from '../components/sub-membership/sub-membership'
import styles from './home.module.scss'
import {
  ASSOCIATE_MEMBERSHIP,
  EMERITUS_MEMBERSHIP,
  CONFERENCE_MEMBERSHIP,
  CORPORATE_MEMBERSHIP,
  USTA_MEMBERSHIP
} from 'src/utils/constants/memberships'
import { REGISTER_ROUTE } from 'src/utils/constants/routes'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'

interface HomeProps extends RouteComponentProps {}

const Home: React.FC<HomeProps> = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const client = useApolloClient()

  useEffect(() => {
    setCurrentStep('0')
    clearLocalStorage()
  }, [])

  const handleClick = async () => {
    setLoading(true)
    setCurrentStep('0')
    setTermId('')
    const user = await getUser(client, MembershipCodeEnum.CPM)
    await setToken()
    if (user) {
      navigate(REGISTER_ROUTE)
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      alignContent="center"
      className={styles.homeRoot}
    >
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        direction="column"
        alignItems="center"
      >
        <Typography className={styles.title}>{t('ITA membership')}</Typography>
        <BodyRegular spacing={{ margins: { md: 'bottom' } }}>
          With any questions about ITA membership, please email membership@itatennis.com
        </BodyRegular>
      </Grid>
      <MainMembership onClick={handleClick} loading={loading} />
      <div className={styles.subMembershipContainer}>
        <SubMembership membership={CORPORATE_MEMBERSHIP} />
        <SubMembership membership={CONFERENCE_MEMBERSHIP} />
        <SubMembership membership={USTA_MEMBERSHIP} />
        <SubMembership membership={ASSOCIATE_MEMBERSHIP} />
        <SubMembership membership={EMERITUS_MEMBERSHIP} />
      </div>
    </Grid>
  )
}
export default Home
