import gql from 'graphql-tag'

export const SUMMER_CIRCUIT_TRANSACTION = gql`
  mutation IntegrateSummerCircuitTransaction($input: IndividualMembershipTransaction!) {
    integrateSummerCircuitTransaction(input: $input) {
      userDetails {
        partnerId
      }
      capturePayment {
        status
        message
      }
      emailUsers {
        status
        message
      }
    }
  }
`

export const COLLEGE_MEMBERSHIP_TRANSACTION = gql`
  mutation IntegrateCollegeMembershipTransaction($input: CollegeMembershipTransaction!) {
    integrateCollegeMembershipTransaction(input: $input) {
      userDetails {
        partnerId
      }
      capturePayment {
        status
        message
      }
      emailUsers {
        status
        message
      }
      createInvoicePayment {
        status
        message
      }
      makeUserVenueAdministrator {
        status
        message
      }
    }
  }
`

export const CORPORATE_MEMBERSHIP_TRANSACTION = gql`
  mutation IntegrateCorporateMembershipTransaction($input: OrganisationMembershipTransaction!) {
    integrateCorporateMembershipTransaction(input: $input) {
      userDetails {
        partnerId
      }
      capturePayment {
        status
        message
      }
      emailUsers {
        status
        message
      }
    }
  }
`

export const CONFERENCE_MEMBERSHIP_TRANSACTION = gql`
  mutation IntegrateConferenceMembershipTransaction($input: OrganisationMembershipTransaction!) {
    integrateConferenceMembershipTransaction(input: $input) {
      userDetails {
        partnerId
      }
      capturePayment {
        status
        message
      }
      emailUsers {
        status
        message
      }
    }
  }
`
export const USTA_MEMBERSHIP_TRANSACTION = gql`
  mutation IntegrateUSTASectionTransaction($input: OrganisationMembershipTransaction!) {
    integrateUSTASectionTransaction(input: $input) {
      userDetails {
        partnerId
      }
      capturePayment {
        status
        message
      }
      emailUsers {
        status
        message
      }
    }
  }
`

export const ASSOCIATE_MEMBERSHIP_TRANSACTION = gql`
  mutation IntegrateAssociateProfessionalTransaction($input: OrganisationMembershipTransaction!) {
    integrateAssociateProfessionalTransaction(input: $input) {
      userDetails {
        partnerId
      }
      capturePayment {
        status
        message
      }
      emailUsers {
        status
        message
      }
    }
  }
`

export const COACH_MEMBERSHIP_TRANSACTION = gql`
  mutation IntegrateCoachMembershipTransaction($input: CoachEmeritusTransaction) {
    integrateCoachEmeritusTransaction(input: $input) {
      userDetails {
        partnerId
      }
      capturePayment {
        status
        message
      }
      emailUsers {
        status
        message
      }
    }
  }
`
