import cx from 'classnames'
import React from 'react'
import styles from './form-error-message.module.scss'

interface Props {
  message?: string
  classname?: string
}

const FormErrorMessage: React.FC<Props> = ({ message, classname, children }) => {
  if (message) {
    return <div className={cx(styles.errorMessage, classname)}>{message}</div>
  }

  return <div className={cx(styles.errorMessage, classname)}>{children}</div>
}

export default FormErrorMessage
