import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { Box, Hidden } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser } from 'src/api-service/api-service'
import { GET_EXTERNAL_ID } from 'src/App/apollo/local-state'
import { ApplicableSteps, InitialStep, useMembershipTerms } from 'src/App/auth/register/register'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useExistingMembership } from 'src/hooks/use-existing-membership'
import { usePrice } from 'src/hooks/use-price'
import { useToken } from 'src/hooks/use-token'
import {
  ASSOCIATE_DETAILS_ROUTE,
  ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE,
  ASSOCIATE_PAYMENT_ROUTE
} from 'src/utils/constants/routes'
import { ASSOCIATE_PROFFESIONAL } from 'src/utils/constants/strings'
import { RouteCheck } from 'src/utils/helper/helper'
import {
  getCurrentStep,
  getCurrentUser,
  getTermId,
  setCurrentStep
} from 'src/utils/localStorage/local-storage'
import AssociatePaymentConfirmation from '../associate-confirmation/associate-confirmation'
import AssociateDetails from '../associate-details/associate-details'
import MembershipSummary from '../membership-summary/membership-summary'
import PageContainer from '../page-container/page-container'
import PageWidth from '../page-width/page-width'
import RegisterPayment from '../register-payment/register-payment'
import RegisterTerms from '../register-terms/register-terms'
import MuiStepper from '../stepper/stepper'

interface AssociateMembershipProps extends RouteComponentProps {}

const AssociateMembership: React.FC<AssociateMembershipProps> = () => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [loadingPage, setLoadingPage] = React.useState<boolean>(false)

  const currentStep = getCurrentStep()
  const user = getCurrentUser()
  const { token } = useToken()

  const { loadingExistingMembership } = useExistingMembership(MembershipCodeEnum.ASCPRO, !token)
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.ASCPRO)
  const { amount } = usePrice({
    skip: !getTermId(),
    variables: { input: { membershipCode: MembershipCodeEnum.ASCPRO, termId: getTermId() } }
  })
  const { data: userId } = useQuery(GET_EXTERNAL_ID, { client })

  useEffect(() => {
    setLoadingPage(true)
    if (!user) getUser(client, MembershipCodeEnum.ASCPRO)
    if (!currentStep || currentStep == null) setCurrentStep('0')
    setLoadingPage(false)
  }, [userId, currentStep, client, user])

  const initialSteps: InitialStep[] = [
    {
      key: '1',
      value: t('terms'),
      visible: hasMultipleActiveTerms,
      Component: (
        <RegisterTerms code={MembershipCodeEnum.ASCPRO} navigateRoute={ASSOCIATE_DETAILS_ROUTE} />
      )
    },
    { key: '2', value: t('details'), visible: true, Component: <AssociateDetails /> },
    {
      key: '3',
      value: t('payment'),
      visible: true,
      Component: (
        <RegisterPayment path={ASSOCIATE_PAYMENT_ROUTE} membership={ASSOCIATE_PROFFESIONAL} />
      )
    },
    {
      key: '4',
      value: t('confirmation'),
      visible: true,
      Component: <AssociatePaymentConfirmation path={ASSOCIATE_PAYMENT_CONFIRMATION_ROUTE} />
    }
  ]

  const applicableSteps = initialSteps.filter(s => s.visible)
  const stepNames = applicableSteps.map(s => s.value)

  return (
    <>
      <Hidden smUp>
        <RouteCheck>
          <MembershipSummary cost={amount} />
        </RouteCheck>
      </Hidden>
      <Box clone p={{ xs: '0 20px 0 20px', sm: '0 20px 0 20px', md: '0 135px 0 135px' }}>
        <PageContainer>
          <PageWidth>
            <MuiStepper
              steps={stepNames}
              children={
                <ApplicableSteps steps={applicableSteps} currentStep={currentStep ?? '0'} />
              }
              activeStep={Number(currentStep)}
              loading={loadingExistingMembership}
              loadingPage={loadingPage || loadingExistingMembership}
            />
          </PageWidth>
        </PageContainer>
      </Box>
    </>
  )
}

export default AssociateMembership
