import { useApolloClient } from '@apollo/react-hooks'
import { Spinner } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { navigate, RouteComponentProps } from '@reach/router'
import { Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMembershipTerms } from 'src/App/auth/register/register'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'
import { useOwnMembers } from 'src/hooks/use-own-members'
import { SchoolInfo } from 'src/types/types'
import { PAYMENT_ROUTE } from 'src/utils/constants/routes'
import { setCurrentStep, setOrgData } from 'src/utils/localStorage/local-storage'
import * as yup from 'yup'
import RegisterSchoolForm from '../register-school-form/register-school-form'
import AddressValidationModal, { AddressUsed } from '../address-validation-modal/address-validation-modal'

interface RegisterDetailsProps extends RouteComponentProps {}

const RegisterSchoolInfo: React.FC<RegisterDetailsProps> = () => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [loading, setLoading] = useState<boolean>(false)
  const [addressValidationDialog, setAddressValidationDialog] = useState(false)
  const { hasMultipleActiveTerms } = useMembershipTerms(MembershipCodeEnum.CPM)

  const { loading: loadingOwnMembers } = useOwnMembers({ code: MembershipCodeEnum.CPM })

  let initialValues: SchoolInfo = {
    school: '',
    mailingAddress: '',
    city: '',
    state: '',
    zipCode: '',
    primaryRole: '',
    teams: '' as any
  }

  const validationSchema = yup.object().shape({
    school: yup.string().required(t('is required', { type: 'school' })),
    mailingAddress: yup.string().required(t('is required', { type: 'mailing address' })),
    zipCode: yup.string().required(t('is required', { type: 'zip code' })),
    city: yup.string().required(t('is required', { type: 'city' })),
    state: yup.string().required(t('is required', { type: 'state' })),
    primaryRole: yup.string().required(t('is required', { type: 'primary role' })),
    teams: yup.string().required(t('is required', { type: 'teams' }))
  })

  const onSubmit = async (values: SchoolInfo, actions: FormikHelpers<SchoolInfo>, selectedAddress: AddressUsed, validatedAddress: any) => {
    setLoading(true)
    actions.setSubmitting(true)

    const orgData = {
      ...values,
      ...(selectedAddress === AddressUsed.VALIDATED ? validatedAddress : {})
    }
    setOrgData(orgData)
    client.writeData({ data: { selectedTeam: values.teams } })
    setLoading(false)
    actions.setSubmitting(false)
    if (!loading) {
      navigate(PAYMENT_ROUTE)
      setCurrentStep(hasMultipleActiveTerms ? '2' : '1')
    }
  }

  if (loadingOwnMembers) {
    return (
      <Grid container justify="center">
        <Spinner />
      </Grid>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false)
        setAddressValidationDialog(true)
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {formikProps =>
        <>
          <RegisterSchoolForm {...formikProps} />
          <AddressValidationModal
            {...formikProps}
            onContinue={onSubmit}
            values={formikProps.values}
            open={addressValidationDialog}
            onClose={() => setAddressValidationDialog(false)}
          />
        </>
      }
    </Formik>
  )
}
export default RegisterSchoolInfo
