import { useEffect, useState } from 'react'
import { getToken } from 'src/api-service/api-service'
import { storeToken } from 'src/utils/localStorage/local-storage'

export function useToken() {
  const [token, setToken] = useState('')
  const [loadingToken, setLoadingToken] = useState(true)

  useEffect(() => {
    ;(async () => {
      if (token) return
      const fetechedToken = await getToken()
      setToken(fetechedToken)
      storeToken(fetechedToken)
      setLoadingToken(false)
    })()
  }, [token])

  return { token, loadingToken }
}
