import React, { useEffect, useState } from 'react'
import { H3, H5, Spinner } from '@clubspark-react/clubspark-react-tools'
import { Grid, Hidden } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TeamRolesEnum, User, VenueRoleName } from 'src/types/types'
import TeamStaffDetails from 'src/App/components/team-staff-details/team-staff-details'
import HR from 'src/App/components/hr/hr'
import styles from './staff-roles.module.scss'

import { getVenueAdmins } from 'src/api-service/api-service'
import { useTeamIds } from 'src/hooks/use-team-ids'
import { MembershipCodeEnum } from 'src/graphql-types/globalTypes'

type ManWomanId = { mensId?: string | null; womensId?: string | null }

type StaffState = Record<TeamRolesEnum, any>

const groupByRole = (users: User[]) => {
  const filterRoles = (roleName: VenueRoleName) =>
    users
      .filter(user => (user.VenueRoles || []).some(role => role.Name === roleName))
      .map(user => [user.FirstName, user.LastName].join(' '))

  return {
    [TeamRolesEnum.HEAD_COACH]: filterRoles('Head Coach'),
    [TeamRolesEnum.ASSISTANT_COACH]: filterRoles('Assistant Coach'),
    [TeamRolesEnum.SPORTS_INFORMATION_DIRECTOR]: filterRoles('Sports Information Director'),
    [TeamRolesEnum.DIRECTOR_OF_OPERATIONS]: filterRoles('Director of Operations'),
    [TeamRolesEnum.BUSINESS_ADMIN]: filterRoles('Business Admin')
  }
}

export const useStaffDetails = () => {
  const [mensRoles, setMensRoles] = useState<StaffState>()
  const [womensRoles, setWomensRoles] = useState<StaffState>()
  const [loadingMensRoles, setLoadingMensRoles] = useState(false)
  const [loadingWomensRoles, setLoadingWomensRoles] = useState(false)
  const { mensId, womensId } = useTeamIds(MembershipCodeEnum.CPM)

  useEffect(() => {
    const fetchAdmins = async () => {
      if (mensId) {
        setLoadingMensRoles(true)

        await getVenueAdmins(mensId)
          .then(users => {
            const userByRole = groupByRole(users)

            setMensRoles(userByRole)

            return userByRole
          })
          .catch(console.error)
          .finally(() => {
            setLoadingMensRoles(false)
          })
      }
      if (womensId) {
        setLoadingWomensRoles(true)

        await getVenueAdmins(womensId)
          .then(users => {
            const userByRole = groupByRole(users)

            setWomensRoles(userByRole)

            return userByRole
          })
          .catch(console.error)
          .finally(() => {
            setLoadingWomensRoles(false)
          })
      }
    }

    fetchAdmins()
  }, [mensId, womensId])

  return { loadingMensRoles, loadingWomensRoles, mensRoles, womensRoles }
}

const CenterSpinner = () => (
  <Grid container justify="center" alignItems="center">
    <Spinner />
  </Grid>
)

const StaffRoles: React.FC<ManWomanId> = ({ mensId, womensId }) => {
  const { t } = useTranslation()
  const { loadingMensRoles, loadingWomensRoles, mensRoles, womensRoles } = useStaffDetails()

  return (
    <Grid container className={styles.staffDetails}>
      <Grid item container className={styles.title}>
        <H3>{t('check team staff details')}</H3>
      </Grid>
      <Grid item container>
        {mensId && (
          <Grid item xs={12} md={womensId ? 6 : 12} className={styles.staffDetailItem}>
            <H5 spacing={{ margins: { md: 'bottom' } }}>{t('mens team staff details')}</H5>
            {loadingMensRoles ? <CenterSpinner /> : <TeamStaffDetails roles={mensRoles} />}
          </Grid>
        )}
        {mensId && womensId && (
          <Hidden mdUp>
            <HR className={styles.mdSeparator} />
          </Hidden>
        )}
        {womensId && (
          <Grid item xs={12} md={mensId ? 6 : 12} className={styles.staffDetailItem}>
            <H5 spacing={{ margins: { md: 'bottom' } }}>{t('womens team staff details')}</H5>
            {loadingWomensRoles ? <CenterSpinner /> : <TeamStaffDetails roles={womensRoles} />}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default StaffRoles
