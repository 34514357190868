import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import ITALogoDark from 'src/assets/ITALogoDark@3x.png'
import styles from './footer.module.scss'

type SocialProps = {
  children: ReactNode
  href: string
}

const SocialMediaIcon: React.FC<SocialProps> = ({ children, href }: SocialProps) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href} className={styles.social}>
      {children}
    </a>
  )
}

const Footer = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.alignmentContainer}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src={ITALogoDark} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.links}>
          {/* <BodyRegular spacing={{ margins: { md: 'bottom' } }} classnames={styles.paragraph}> */}
          <a href="https://itatennis.com/" className={styles.paragraph}>
            itatennis.com
          </a>
          {/* </BodyRegular> */}
          <a href="https://www.wearecollegetennis.com" className={styles.paragraph}>
            wearecollegetennis.com
          </a>
        </div>
        <div className={styles.socialsContainer}>
          <SocialMediaIcon href="https://www.facebook.com/ITACollegeTennis">
            <FaFacebookF />
          </SocialMediaIcon>
          <SocialMediaIcon href="https://www.instagram.com/ita_tennis/">
            <FaInstagram />
          </SocialMediaIcon>
          <SocialMediaIcon href="https://www.linkedin.com/company/ita_tennis/">
            <FaLinkedin />
          </SocialMediaIcon>
          <SocialMediaIcon href="https://twitter.com/ITA_Tennis">
            <FaTwitter />
          </SocialMediaIcon>
          <SocialMediaIcon href="https://www.youtube.com/user/WeAreITA/">
            <FaYoutube />
          </SocialMediaIcon>
        </div>
        <div className={styles.copyrightContainer}>
          {/* <BodyRegular classnames={styles.copyright} spacing={{ margins: { xs: 'bottom' } }}>
          Copyright 2020 by Intercollegiate Tennis Association (ITA).
        </BodyRegular> */}
          {/* <BodyRegular classnames={styles.copyright} spacing={{ margins: { lg: 'bottom' } }}>
          All Rights Reserved
        </BodyRegular> */}
          <p className={styles.copyright}>{t('copyright footer')}</p>
          <p className={styles.copyright}>{t('rights reserved')}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
