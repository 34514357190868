import { Button, H3, BodySmallBold } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './sub-membership.module.scss'
import { Benefits } from '../main-membership/main-membership'
import { MembershipInfo } from 'src/utils/constants/memberships'
import { setCurrentStep, setTermId } from 'src/utils/localStorage/local-storage'
import { getUser } from 'src/api-service/api-service'
import { useApolloClient } from '@apollo/react-hooks'
import { navigate } from '@reach/router'
import { setRegistrationStep } from 'src/App/apollo/local-state'
import { getRoute } from 'src/utils/helper/helper'

type SubMembershipInterface = {
  membership: MembershipInfo
}

const SubMembership: React.FC<SubMembershipInterface> = ({ membership }) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const membershipCode = membership.code

  const hasExpandBenefits = membership.benefits.length > 4

  async function handleClick() {
    setLoading(true)
    setCurrentStep('0')
    setTermId('')
    const user = await getUser(client, membershipCode)
    if (user) {
      const route = getRoute(membershipCode)
      navigate(route)
      setRegistrationStep('0', client)
    }
  }

  return (
    <Grid container direction="column" className={styles.container}>
      <Grid className={styles.headingWrapper}>
        <H3>{membership.name}</H3>
      </Grid>
      <div className={styles.subTextWrapper}>
        <BodySmallBold>{membership.subText}</BodySmallBold>
      </div>
      <div className={styles.benefitsWrapper}>
        <Benefits list={membership.benefits} expanded={expanded} />
      </div>
      {hasExpandBenefits && (
        <Button
          level="link"
          linkStyle
          spacing={{ margins: { ...(hasExpandBenefits ? { xs: 'top' } : {}) } }}
          classname={styles.linkButton}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'Hide full benefits and terms' : 'Read full benefits and terms'}
        </Button>
      )}
      {!hasExpandBenefits && <div className={styles.spaceFiller}></div>}
      <Grid container justify="center" className={styles.buttonContainer}>
        <Button
          key={membership.name}
          size="lg"
          fluid
          spacing={{ margins: { md: 'top' } }}
          loading={loading}
          onClick={handleClick}
        >
          {t('join now')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default SubMembership
